<template>
  <div class="out-select">
    <el-select
      class="select"
      size="medium"
      clearable
      filterable
      multiple
      value-key="id"
      placeholder="检验项目"
      v-model="domain.children">
      <el-option
        v-for="(item) in name_list"
        :value="item"
        :label="item.original_laboratory_name + '(' + item.original_specimen + ')'"
        :key="item.id">
      </el-option>
    </el-select>
    <el-button :disabled="!domain.standard_laboratory_name" @click="reflesh" type="text" icon="el-icon-refresh" :loading="loading">重新查找</el-button>
  </div>

</template>
<script>
import {get_original_item} from './service'
export default {
  data() {
    return {
      name_list:[],
      loading: false
    }
  },
  props: {
    domain: {
      type: Object,
      default: () => {}
    },
    or_name_list: {
      type: Array,
      default: () => []
    },
  },
  watch: {
    'domain.standard_laboratory_name'() {
      this.remote_name(1)
      this.domain.children = []
      this.name_list = []
    },
    'domain.standard_specimen'() {
      this.remote_name(1)
      this.domain.children = []
      this.name_list = []
    }
  },
  created() {
    this.remote_name()
  },
  methods: {
    format_list () {
      let ids = this.name_list?.map(item => item.name) || []
      for (let item of (this.domain.children || [])) {
        if (!ids.includes(this.domain.children) && !this.name) {
          this.name_list.push(item)
        }
      }
    },
    async remote_name(val) {
      if(!this.domain.standard_laboratory_name || !this.domain.standard_specimen)return
      this.loading = true
      try {
        let params ={
          standard_laboratory_name: this.domain.standard_laboratory_name,
          standard_specimen: this.domain.standard_specimen,
        }
        const {data} = await get_original_item(params)
        data.labs = data.labs.map(item=>{
          return {
            ...item,
            id: item.original_laboratory_name + item.original_specimen
          }
        })
        if(val)this.domain.children = data.labs || []
        this.name_list = data.labs || []
      } finally {
        this.loading = false
      }
    },
    reflesh() {
      this.remote_name(1)
    }
  }
}
</script>
<style scoped>
.out-select {
  width: 85%;
  display: flex;
  align-items: center;
  color: var(--color-primary);
  & >span {
    cursor: pointer;
  }
}
.select {
  flex:1;
  margin-right: 10px;
}
</style>
