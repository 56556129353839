
export default {
  namespaced: true,
  state: {
    breadcrumb: [], // 面包屑
  },
  mutations: {
    setBreadcrumb (state, payload) {
      state.breadcrumb = payload
    },
  },
  actions: {

  }
}
