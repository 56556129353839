<template>
  <div class="header_wrapper">
    <div class="back_box" @click="back" v-if="$route.meta.full_screen">
      <i class="el-icon-arrow-left icon" />
      返回
    </div>
    <div class="logo_box" @click="home()" v-else>
      <!-- 脑脊液屏蔽 -->
      <div class="logo" v-if="authInfo.role !== 11">
        <img :src="'./assets/theme-img/logo.png'" class="icon" width="180" height="auto"/>
      </div>
    </div>

    <div class="header_right">
      <div v-if="license_text" class="license">
        <img src="../assets/img/notice.png" class="icon" width="16" height="auto"/>
        <span v-html="license_text"></span>
      </div>
      <div class="user_info">
        <div class="img"></div>
        <div>
          <div class="username">{{ authInfo.nickname }}</div>
          <div class="role">{{ role_label[authInfo.role] }}</div>
        </div>
        <div class="system_set">
          <i class="icon el-icon-caret-bottom" />
          <div class="popup-box">
            <div class="popup_content">
              <div class="item" @click="quit">退出</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import {getSession} from '../utils/session'
import {arr_label} from '../utils/format'
import {role_arr} from '../common-data/config'

export default {
  data () {
    return {
      authInfo: getSession(),
      license_dialog_visible: false
    }
  },
  computed: {
    ...mapState('commonData', {
      license: state => state.license,
    }),
    role_label () {
      return arr_label(role_arr)
    },
    license_text () {
      let text = ''
      let license = this.license
      switch(license.state) {
      case 0:
        text=''
        break
      case 1:
        if (license.limit < 0 || license.limit > 30) {
          text=''
        } else {
          text=`许可证有效期还剩 <label>${license.limit}</label> 天，请尽快联系服务商更换许可证`
        }
        break
      case 2:
        if (license.limit < 0 || license.limit > 30) {
          text=''
        } else {
          text=`试用版：许可证有效期还剩 <label>${license.limit}</label> 天，请尽快联系服务商更换许可证`
        }
        break
      case 3:
        text='已过期，请尽快联系服务商更换许可证'
        break
      default:
        text=''
      }
      return text
    },
  },
  components: {
  },
  created () {
  },
  methods: {
    back () {
      const {path} = this.$route
      if (path === '/detail' && getSession('from_url') !== '/favorite-list') {
        this.$router.replace('/excellent')
      } else {
        this.$router.back()
      }
    },
    async quit () {
      this.$confirm('确认退出？', '提示', {center: true})
        .then(()=> {
          // 脑脊液屏蔽
          if(getSession().role === 11) {
            this.$router.push({ path: '/appy-login' })
          } else {
            this.$router.push({ path: '/login' })
          }
        })
        .catch(()=> {})
    },
    home () {
      let verifyArr = this.authInfo.verifyArr
      this.$router.push(verifyArr[0])
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header_wrapper{
  width: 100%;
  height: 64px;
  background:#fff;
  color: #fff;
  display: flex;
  align-items: center;
  background: var(--common-header-background);
  justify-content: space-between;
}
.logo_box {
  height: 100%;
  display: flex;
  padding-left: 30px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.back_box {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: var(--common-header-background-2);
  padding: 0 18px;
  & .icon {
    font-size: 18px;
    margin-top: -2px;
  }
}
.header_right {
  display: flex;
  align-items: center;
  height: 100%;
}
.license {
  opacity: 0.9;
  font-size: 16px;
  & .icon {
    margin-right: 4px;
    margin-top: -2px;
  }
}
>>>.license label {
  color: var(--color-danger);
}
.user_info {
  height: 100%;
  padding: 0 24px;
  display: flex;
  align-items: center;
  background: var(--common-header-background-2);
  margin-left: 28px;
  & .img {
    width: 43px;
    height: 43px;
    border-radius: 100%;
    margin-right: 16px;
    background: url('../assets/img/head_icon.png') center center no-repeat;
    background-size: cover;
  }
  & .username{
    font-size: 15px;
    font-weight: 500;
  }
  & .role{
    font-size: 13px;
    opacity: 0.8;
  }
}
.system_set {
  position: relative;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  & .icon {
    margin-left: 10px;
    font-size: 16px;
    color: #fff;
  }
  &:hover {
    & .popup-box {
      display: block;
    }
  }
  & .popup-box {
    display: none;
    width: 120px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    transform: translate(19px, 100%);
    z-index: 2;
  }
  & .popup_content {
    padding: 8px 0px;
    border: 1px solid rgba(236,239,248,1);
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
    background: #fff;
    border-radius: 4px;
    margin-top: 12px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: -8px;
      right: 20px;
      width: 14px;
      height: 14px;
      background: #fff;
      transform-origin: center center;
      transform: rotate(45deg);
      border: 1px solid rgba(236,239,248,1);
      border-right: none;
      border-bottom: none;
    }
    & .item {
      padding: 10px 18px;
      font-size: 14px;
      color:#6C757D;
      &:hover {
        background: #f5f7fa;
      }
    }
  }
}
</style>
