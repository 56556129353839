<template>
   <el-dialog
      width="60%"
      title=""
      :visible.sync="visible"
       :before-close="before_close"
      append-to-body>
      <div class="title" slot="title">
        依据原文
      </div>
        <div class="body" v-loading="loading">
          <template v-if="theory.length>0">
            <div v-for="(i,index) of theory" :key="index + 'theory'" style="margin-bottom: 10px;">
              <!-- <h2 class="commom-text">{{`${i.source.name ?  i.source.name + ' |' : ''} ${i.source.publish_version&&+i.source.publish_version>0 ?  '第' + i.source.publish_version + '版' + ' | ' : ''}${i.source.publisher ?  i.source.publisher : ''}`}}</h2> -->
              <Rich class="html"
                v-model="i.context"
                :style_type="2"
                style="padding-top: 10px"
              />
            </div>
          </template>
      <div class="no-data" v-if="(!theory || theory.length===0)&&!loading">
        <img src="../../../assets/img/detail/learning.png" width="300" height="144"/>
          正在学习中～
      </div>
      </div>
    </el-dialog>
</template>
<script>
import Rich from '../../rich/index.vue'
export default {
  data() {
    return {
      loading: false,
      data: {}
    }
  },
  props: {
    knowledge: {
      type: Array,
      default: () =>[]
    },
    visible: {
      type: Boolean,
      default: false
    },
  },
  components: {
    Rich
  },
  computed:{
    theory() {
      let arr = []
      if(this.knowledge &&this.knowledge.length>0) {
        arr = this.knowledge.filter(i=>{
          i.context = (i.knowledge_display || i.knowledge_content).trim()
          let len_index = i.context.length-1
          let text = []
          let el = 1
          while(el<5) {
            if(i.context[len_index]!==' '){
              text.unshift(i.context[len_index])
              el++
            }
            len_index--
          }
          return !(['医生建议','医生提供'].includes(text.join('')) && i.context.length < 16)
        })
      }
      return arr
    }
  },
  created() {

  },
  methods: {
    close () {
      this.$emit('update:visible', false)
    },
    before_close () {
      this.close()
    }
  }
}
</script>
<style scoped>
.title {
  font-size: 24px;
  font-weight: bold;
  color: var(--color-text-primary);
}
.h2 {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 12px;
}
.html {
  font-size: 15px;
  line-height: 1.6;
}
 .no-data {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 28px;
  padding-top: 50px;
  font-size: 24px;
  & >img {
    margin-bottom: 12px;
  }
}
.body {
  font-size: 15px;
  color: var(--color-text-secondary);

  position: relative;
}

>>>.el-dialog__header {
  border-bottom: 1px solid rgba(112, 112, 112, 0.22);
}
>>>.el-dialog__body {
  padding:0 20px 20px;
}
</style>
