<template>
<!-- 量表 -->
  <div class="type_content">
    <ul class="flow-content left">
      <li @click="select_report('')" style="cursor: pointer;">
        <div class="line-left" v-if="list&&list.length>0"/>
        <div class="icon icon-left"></div>
        <div :class="['message','message-left',{'select-message': !current_report_id}]">
          <div class="add">新建</div>
        </div>
      </li>
      <li v-for="(i,index) in list" :key="i.report_id" @click="select_report(i.report_id)" style="cursor: pointer;" :ref="i.report_id">
        <div class="line-left" v-if="index !== list.length-1"/>
        <div class="icon icon-left"></div>
        <div :class="['message','message-left',{'select-message': `${i.report_id}` === `${current_report_id}`}]">
          <div class="time time-left">{{format_date(i.report_time,'yyyy-MM-dd')}}</div>
        </div>
      </li>
      <div style="padding-bottom: 50px;"></div>
    </ul>
    <div class="center"></div>
    <div class="right is-hav-pad" >
      <div class="result">
        <h2 class="commom-text" style="flex:1;">
          <div class="line"/>
          患者一般情况
        </h2>
      </div>
      <el-form ref="ruleForm" :model="data" label-position="top" class="form">
        <el-form-item label="姓名" prop="name"  :rules="{required: true,validator: checkname , trigger: 'change'}">
          <el-input :maxlength="20" clearable :disabled="is_show || (!!original_data.name)" v-model="data.name" placeholder="请填写姓名"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender"  :rules="{required: true, message: '请选择性别', trigger: 'change'}">
          <el-select clearable :disabled="is_show" v-model="data.gender" placeholder="请选择性别" style="width: 100%;">
              <el-option
                v-for="item in ['男','女']"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
            <!-- <SingleSelect clearable :disabled="is_show" v-model="data.gender" placeholder="请选择性别" style="width: 100%;">
              <template slot="single">
              <el-radio label="男" border>男</el-radio>
               <el-radio label="女" border>女</el-radio>
              </template>
            </SingleSelect> -->
        </el-form-item>
        <el-form-item label="年龄" prop="age"  :rules="{required: true, validator:checkage, trigger: 'change'}">
          <MyInput  clearable
            v-model="data.age"
            :disabled="is_show"
            :decimalDigits="0"
            unit="岁"
            placeholder="请填写年龄"
            />
        </el-form-item>
        <el-form-item label="体重" prop="weight" :rules="{ validator:checkweight, trigger: 'change'}">
          <MyInput  clearable
            v-model="data.weight"
            :disabled="is_show"
            :decimalDigits="2"
            unit="kg"
            :placeholder="is_show ? '' : '请填写体重'"
            />
        </el-form-item>
        <el-form-item label="手术方式" prop="operation" >
          <el-select clearable :disabled="is_show" v-model="data.operation" :placeholder="is_show ? '' : '请选择手术方式'" style="width: 100%;">
              <el-option
                v-for="item in ['单侧切除','双侧切除']"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="淋巴结转移个数" prop="lymph_metastasis">
          <MyInput  clearable
            v-model="data.lymph_metastasis"
            :disabled="is_show"
            :decimalDigits="0"
            unit="个"
            :placeholder="is_show ? '' : '请填写淋巴结转移个数'"
            />
        </el-form-item>
        <el-form-item label="有无包膜侵犯？" prop="capsular_invasion" >
          <el-select clearable :disabled="is_show" v-model="data.capsular_invasion" :placeholder="is_show ? '' : '请选择有无包膜侵犯'" style="width: 100%;">
              <el-option
                v-for="item in ['有','无']"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
        </el-form-item>
        <h2 class="commom-text" style="width: 100%;margin-top: -15px;">
          <div class="line"/>
          其他随访问题
        </h2>
        <el-form-item label="用药名称" prop="medicine_name" :rules="{required: true,message: '请选择用药名称' , trigger: 'change'}">
          <el-select clearable :disabled="is_show"  v-model="data.medicine_name" :placeholder="is_show ? '' : '请选择用药名称'" style="width: 100%;">
            <el-option
              v-for="item in ['优甲乐/50ug','雷替斯/50ug']"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用药时间" prop="medicine_time" :rules="{required: true,validator:check_time,trigger: 'change'}">
          <el-date-picker
            :disabled="is_show"
            value-format="timestamp"
            :picker-options="pickerOptions"
            style="width: 100%;"
            v-model="data.medicine_time"
            type="date"
            :placeholder="is_show ? '' : '选择用药时间'">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="每天用药剂量" prop="medicine_metering" :rules="{required: true,message: '请选择每天用药剂量' , trigger: 'change'}">
          <el-select @change="medicine_metering_change" clearable :disabled="is_show" v-model="data.medicine_metering" placeholder="请选择每天用药剂量" style="width: 100%;">
            <el-option
              v-for="item in dose_list"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="data.medicine_metering === '其他'" label="其他用药剂量" prop="other_medicine_metering" :rules="{required: true,message: '请填写其他用药剂量' , trigger: 'change'}">
          <el-input clearable :disabled="is_show" v-model="data.other_medicine_metering" :placeholder="is_show ? '' : '请填写其他用药剂量'"></el-input>
        </el-form-item>
        <el-form-item label="有无心慌、胸闷、手抖？" prop="palpitation" >
          <el-select clearable :disabled="is_show" v-model="data.palpitation" :placeholder="is_show ? '' : '请选择有无心慌、胸闷、手抖'" style="width: 100%;">
              <el-option
                v-for="item in ['有','无']"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
        </el-form-item>
         <el-form-item label="有无乏力？" prop="fatigue" >
          <el-select clearable :disabled="is_show" v-model="data.fatigue" :placeholder="is_show ? '' : '请选择有无乏力'" style="width: 100%;">
              <el-option
                v-for="item in ['有','无']"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="有无失眠？" prop="insomnia" >
          <el-select clearable :disabled="is_show" v-model="data.insomnia" :placeholder="is_show ? '' : '请选择有无失眠'" style="width: 100%;">
              <el-option
                v-for="item in ['有','无']"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="有无嗜睡？" prop="lethargy" >
          <el-select clearable :disabled="is_show" v-model="data.lethargy" :placeholder="is_show ? '' : '请选择有无嗜睡'" style="width: 100%;">
              <el-option
                v-for="item in ['有','无']"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="有无明显体重变化？" prop="weight_change" >
          <el-select clearable :disabled="is_show" v-model="data.weight_change" :placeholder="is_show ? '' : '请选择有无明显体重变化'" style="width: 100%;">
              <el-option
                v-for="item in ['有','无']"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="促甲状腺激素(TSH)水平" prop="tsh">
          <MyInput  clearable
            v-model="data.tsh"
            :disabled="is_show"
            :decimalDigits="2"
            unit="mU/L"
            :placeholder="is_show ? '' : '请填写促甲状腺激素(TSH)水平'"
            />
        </el-form-item>
        <el-form-item label="游离T3(FT3)水平" prop="ft3">
          <MyInput  clearable
            v-model="data.ft3"
            :disabled="is_show"
            :decimalDigits="2"
            unit="pmol/L"
            :placeholder="is_show ? '' : '请填写游离T3(FT3)水平'"
            />
        </el-form-item>
        <el-form-item label="游离T4(FT4)水平" prop="ft4">
          <MyInput  clearable
            v-model="data.ft4"
            :disabled="is_show"
            :decimalDigits="2"
            unit="pmol/L"
            :placeholder="is_show ? '' : '请填写游离T4(FT4)水平'"
            />
        </el-form-item>
        <el-form-item label="是否有焦虑状态?" prop="anxiety" >
          <el-select clearable :disabled="is_show" v-model="data.anxiety" :placeholder="is_show ? '' : '请选择是否有焦虑状态'" style="width: 100%;">
              <el-option
                v-for="item in ['无','轻','中','重']"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="有无服用其他药物？" prop="other_medicines" >
          <el-select @change="other_medicines_change" clearable :disabled="is_show" v-model="data.other_medicines" :placeholder="is_show ? '' : '请选择有无服用其他药物'" style="width: 100%;">
              <el-option
                v-for="item in ['有','无']"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
        </el-form-item>
       <el-form-item v-if="data.other_medicines === '有'" label="其他药物信息" prop="other_medicines_information" :rules="{required: true,message: '请填写其他用药剂量' , trigger: 'change'}">
          <TextareaInput :resize="is_show ? 'none' : 'vertical'" :rows="2"  type="textarea" clearable :disabled="is_show" v-model="data.other_medicines_information" placeholder="请填写其他药物信息，如：品牌/名称/规格/剂量等"></TextareaInput>
        </el-form-item>
        <el-form-item label="有无头痛？" prop="headache" >
          <el-select  clearable :disabled="is_show" v-model="data.headache" :placeholder="is_show ? '' : '请选择有无头痛'" style="width: 100%;">
              <el-option
                v-for="item in ['有','无']"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="有无皮肤潮红？" prop="flushing" >
          <el-select clearable :disabled="is_show" v-model="data.flushing" :placeholder="is_show ? '' : '请选择有无皮肤潮红'" style="width: 100%;">
              <el-option
                v-for="item in ['有','无']"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="有无腹泻、呕吐？" prop="diarrhea" >
          <el-select clearable :disabled="is_show" v-model="data.diarrhea" :placeholder="is_show ? '' : '请选择有无腹泻、呕吐'" style="width: 100%;">
              <el-option
                v-for="item in ['有','无']"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="有无月经紊乱？" prop="paramenia" v-if="data.gender === '女'">
          <el-select clearable :disabled="is_show" v-model="data.paramenia" :placeholder="is_show ? '' : '请选择有无月经紊乱'" style="width: 100%;">
              <el-option
                v-for="item in ['有','无']"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="有无服用钙片？" prop="calcium" >
          <el-select clearable :disabled="is_show" v-model="data.calcium" :placeholder="is_show ? '' : '请选择有无服用钙片'" style="width: 100%;">
              <el-option
                v-for="item in ['有','无']"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
        </el-form-item>
      </el-form>
      <template v-if="data.history&&data.history.length>0">
        <div class="result">
          <h2 class="commom-text" style="flex:1;">
            <div class="line"/>
            修改记录
          </h2>
          <span v-if="data.history.length>3" class="score-icon" @click="show = !show"><i :class="[{'el-icon-caret-top':show},{'el-icon-caret-bottom':!show}]" style="padding-right: 3px;"/>点击查看更多记录</span>
        </div>
        <ul class="flow-content history">
          <li v-for="(i,index) in data.history" :key="i.date" style="cursor: pointer;" v-show="show || index<3">
            <div class="line-left" v-if="(show || index<2)&&index<data.history.length-1"/>
            <div class="icon icon-left"></div>
            <div class="message message-left">
              <div class="time">{{format_date(i.date,'yyyy-MM-dd HH:mm:ss')}}</div>
              {{i.role_type === 2 ? '患者' : ' 医生'}}<span v-if="i.role_type === 1">（{{i.user_name || i.user_id}}）</span>{{i.type}}了用药随访记录
            </div>
          </li>
        </ul>
      </template>
      <div class="save">
        <el-button type="primary" round size="small" @click="is_show = false" :disabled="!(is_show && current_report_id)">编辑</el-button>
        <div class="save-right">
          <el-button  type="primary" round size="small" @click="to_sbmit" :disabled="is_show">保存</el-button>
          <span v-if="hander_has_data() && can_save_data">请完善必填项</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {format_date} from '../../../utils/format'
import MyInput from './MyInput'
import {get_form_detail,get_form_list,sbmit_form_data,get_form_init} from './service'
import {form_obj,dose_list,default_units} from './config'
import { mapActions } from 'vuex'
import TextareaInput from './TextareaInput'
// import SingleSelect from './SingleSelect'

export default {
  data() {
    var checkname = (rule, value, callback) => {
      // let reg = /\p{Unified_Ideograph}/u
      if (!value) {
        callback(new Error('请填写姓名'));
      } else {
        callback();
      }
    };
    var checkage = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请填写年龄'));
      } else if (+value<0 || +value>200) {
        callback(new Error('请填写0-200的年龄'));
      } else {
        callback();
      }
    };
    let checkweight = (rule, value, callback) => {
      if (value!==null &&value!=='' && +value === 0) {
        callback(new Error('请填写大于0的体重'));
      } else {
        callback();
      }
    };
    let check_time = (rule, value, callback) => {
      let arr = this.list.filter(i=>{ return (format_date(i.report_time) === format_date(this.data.medicine_time))&&(+i.report_id !== +this.current_report_id)})
      if (!value) {
        callback(new Error('请选择用药时间'));
      } else if (arr.length>0) {
        callback(new Error('当前用药时间已存在随访记录，请勿重复添加！'));
      } else {
        callback();
      }
    };
    return {
      visible:true,
      is_show: true,
      checkname,
      checkage,
      checkweight,
      check_time,
      show: false,
      can_save_data: false,
      original_data: {},
      dose_list,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  props: {
    data:{
      type: Object,
      default: () =>{}
    },
    list: {
      type: Array,
      default: () =>[]
    },
    current_report_id: {
      type: [String,Number],
      default:''
    },
    patient_id:{
      type: String,
      default:''
    },
    report_id:{
      type: String,
      default:''
    },
  },
  components: {
    MyInput,
    TextareaInput,
    // SingleSelect
  },
  mounted() {
    if(this.current_report_id) {
      let id = this.current_report_id + ''
      setTimeout(()=>{
      // 滑到指定位置
        let dom = this.$refs[id][0]
        dom.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      },800)
    }

  },
  watch: {
    current_report_id() {
      this.$refs['ruleForm'].resetFields()
      this.search_form_detail()
      this.visible = true
      this.is_show = !!this.current_report_id
    },
    is_show(val) {
      this.$emit('is_edit',!val)
    },
  },
  created() {
    this.search_form_list()
    if(!this.current_report_id)  this.is_show = false
  },
  methods: {
    ...mapActions('labDetails', [
      'get_my_application'
    ]),
    format_date,
    select_report(id) {
      this.$emit('select_common_report',id)
    },
    //保存提示语
    hander_has_data() {
      let keys = ['name','gender','age','medicine_name','medicine_time','medicine_metering']
      let {other_medicines_information,other_medicines,medicine_metering,other_medicine_metering}=this.data
      for(let i of keys) {
        if(!this.data[i]) return true
      }
      //特殊
      if(other_medicines && !other_medicines_information) return true
      if(medicine_metering==='其他' && !other_medicine_metering) return true
      return false
    },
    medicine_metering_change() {
      this.data.other_medicine_metering = ''
    },
    other_medicines_change() {
      if(this.data.other_medicines_information ) this.data.other_medicines_information = ''
    },
    to_sbmit() {
      this.can_save_data = false
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.can_save_data = false
          this.$confirm('确定要保存结果?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.to_sbmit_data()
          }).catch(() => {

          });
        } else {
          this.can_save_data = true
        }
      })
    },
    async to_sbmit_data() {
      try {
        this.$emit('update_loading',true)
        let params = JSON.parse(JSON.stringify(this.data))
        for(let i in params) {
          if(i.indexOf('_unit')>-1) {
            let str = i.match(/(\S*)_unit/)[1]
            if(!params[str] && params[str]!==0) params[i] = ''
          }
        }
        const {data} = await sbmit_form_data({...params,patient_id: this.patient_id})
        this.$emit('select_common_report',data.report_id)
        this.search_form_list()
        //报告相同直接申请不走watch
        if(`${data.report_id}` === `${this.current_report_id}`)this.search_form_detail()
        this.get_my_application({patientId:this.patient_id,reportId: this.report_id})
        this.is_show = true
      }finally{
        this.$emit('update_loading',false)
      }
    },
    async get_init_data() {
      this.$emit('update_loading',true)
      let {patient_id} = this
      try {
        let {data} = await get_form_init(patient_id)
        let obj = {...form_obj,...data,api_type: this.data.api_type}
        this.$emit('update_data',obj)
        this.original_data = obj
      } finally{
        this.$emit('update_loading',false)
      }
    },
    async search_form_detail() {
      if(!this.current_report_id) {
        this.get_init_data()
        return
      }
      this.$emit('update_loading',true)
      let {current_report_id} = this
      try {
        let params = {
          report_id:current_report_id
        }
        let {data} = await get_form_detail(params)
        let obj = {...form_obj,...data,api_type: this.data.api_type,...default_units}
        this.original_data = obj
        this.$emit('update_data',obj)
      } finally{
        this.$emit('update_loading',false)
      }
    },
    async search_form_list() {
      this.$emit('update_loading',true)
      try {
        let {data} = await get_form_list(this.patient_id)
        this.$emit('update_list',data)
        if(!this.current_report_id&&data&&data.length>0) {
          this.$emit('select_common_report',data[0].report_id)
        } else {
          this.search_form_detail()
        }
      } finally{
        this.$emit('update_loading',false)
      }
    },

  }
}
</script>
<style scoped>
.flow-content{
  margin-top: 22px;
  & >li {
    display: flex;
    position: relative;
    & .line-left {
      position: absolute;
      border-left: 2px dotted rgba(0, 136, 255, 0.69);
      top: 0px;
      left: 6px;
      height: 100%;
    }
    & .icon {
      width: 16px;
      height: 16px;
      background: #fff;
      border: 4px solid rgba(0, 136, 255, 1);
      border-radius: 50%;
      font-size: 14px;
      font-weight: 600;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      position: relative;
      z-index: 1;
      &.icon-left {
        width: 14px;
        height: 14px;
      }
    }
    & .message {
      flex:1;
      font-size: 14px;;
      font-weight: 500;
      line-height: 16px;
      padding-bottom: 18px;
      color: #262626;
      margin-top: -2px;
      &.message-left{
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #303133;
        &.select-message {
          color: var(--color-primary);
        }
      }
      & .time {
        font-size: 12px;;
        font-weight: 400;
        line-height: 19px;
      }
      & .add {
        font-size: 14px;;
        font-weight: 400;
        line-height: 19px;
      }
    }
  }
  &.history {
    padding-top: 20px;
    padding-bottom:0px;
    & >li {
    & .line-left {
        position: absolute;
        border-left:1px solid #DCDFE6;
        top: 0px;
        left: 4px;
        height: 100%;
      }
    & .icon {
        width: 8px;
        height: 8px;
        background: #fff;
        border: 2px solid rgba(0, 136, 255, 1);
      }
    & .message {
      margin-top: -5px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: var(--color-text-secondary);
      & .time {
        font-size: 14px;
      }


     }

    }
  }
}
.result{
  display: flex;
  align-items: center;
}
.commom-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #303133;
  display: flex;
  align-items: center;
  padding-top: 28px;
  & .line {
    width: 4px;
    height: 16px;
    background: var(--color-primary);
    border-radius: 3px;
    margin-right: 8px;
  }
}

.rule {
  color: #939395;
  cursor: pointer;
  padding-top: 10px;
  & >span {
    color: var(--color-primary);
  }
}
.my-input{
  margin-right: -1px;
  position: relative;
  z-index: 1;
}
.type_content {
  flex:1;
  & .left {
    width: 260px;
    overflow: auto;
    position: absolute;
    left: 0px;
    bottom: 0;
    padding-left: 28px;
    top: 0;
  }
  & .center {
    width: 1px;
    border-right: 1px solid rgba(112, 112, 112, 0.14);
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 260px;
    top: 0;
  }
  & .right {
    padding-bottom: 100px;
    margin-left: 260px;

    &.is-hav-pad{
      padding-left: 25px;
    }
  }
}
>>>.select-unit .el-input__inner{
  border-left: none;
  border-radius:0 4px 4px 0;
}
>>>.select-unit.el-select .el-input.is-focus .el-input__inner {
  border-color: #dcdfe6;
}
 >>>.select-unit.el-select .el-input__inner:focus {
  border-color: #dcdfe6;
}
>>>.el-form-item {
  margin-bottom: 10px;
}
.form {
  display: flex;
  flex-wrap: wrap;
  width:100%;
  margin-right: -20px;
  & >div{
    width: 50%;
    &:nth-child(odd){
      padding-right: 10px;
    }
    &:nth-child(even){
      padding-left: 10px;
    }

  }
}
.score-icon {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-primary);
  cursor: pointer;
  padding-top: 28px;
}

.save {
  position: absolute;
  bottom: 0;
  height: 60px;
  background: #fff;
  z-index: 10;
  right: 0;
  left: 261px;
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding: 0 28px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
  & .save-right {
    & >span {
      padding-left: 10px;
      color: #f56c6c;
    }
  }
}
>>>.el-input.is-disabled .el-input__inner {
  color: var(--color-text-secondary);
}
>>>.el-textarea.is-disabled .el-textarea__inner {
  color: var(--color-text-secondary);
}
>>>.el-form--label-top .el-form-item__label {

    padding: 0;
}
</style>

