<template>
<el-dialog
  width="80%"
  title=""
  :visible.sync="visible"
  v-loading="loading"
  :before-close="before_close"
  append-to-body>
  <div class="head" slot="title">
    <div :class="['application-item',{'select': application_id === i.application_id}]" v-for="(i,index) in current_list" :key="i.application_id" @click="select_i(i)">
      <img v-if="api_type&&api_type>2" :src="`./common-img/new-detail/app-${api_type}.png`" class="icon">
      {{i.application_name}}
      <i class="el-icon-close" v-if="application_id === i.application_id" @click="item_close(index)"/>
    </div>
  </div>
  <div class="title">
    <img v-if="api_type&&api_type>2" :src="`./common-img/new-detail/app-${api_type}.png`" class="icon">
    <h1 class="text">{{data.name}} <span  v-if="[5,6].includes(data.api_type)" class="inner-test">内测</span></h1>
  </div>
  <ul class="list">
    <li class="systems">
      <div class="systems-name">
        <div class="yuan"/>
        所属系统
      </div>
      <el-popover
        placement="right-start"
        trigger="hover">
        <div v-for="(i,index) in hander_img(data.systems)" :key="i.value" :class="['img-i',{'img-i-1': data.systems&&(index === data.systems.length-1)}]">
          <img :src="i.url" width="24px" height="auto"/>
          <div class="img-label">{{i.label}}</div>
        </div>
        <template slot="reference">
          <div class="img-content">
            <div class="img">
              <div v-for="(i,index) in hander_img(data.systems)" :key="i.value">
                <div class="yuan" v-if="index<3">
                  <img :src="i.url" width="24px" height="auto"/>
                </div>
                <div class="yuan" v-if="index === 3">
                  +{{data.systems.length-3}}
                </div>
              </div>
            </div>
            <div class="label li-text">{{data.systems&&data.systems.length>0&&data.systems[0]}}<span v-if="data.systems&&data.systems.length>1">等...</span></div>
          </div>
        </template>
       </el-popover>
      </li>
      <div class="li-line"/>
      <li >
        <div class="systems-name">
        <div class="yuan yuan1"/>
        所属类型
      </div>
        <div class="img-content">
          <img src="../../../assets/img/data.png" width="25px" height="auto"/>
          <div class="li-text">{{data.category === 1 ? '实验室解读' : '大数据分析'}}</div>
        </div>
      </li>
    </ul>
    <!-- 附加信息 -->
    <div class="thery-content">
      <div v-for="(i,index) in data.additional_infos" :key="index">
        <div class="commom-text commom-text-top commom-text-bottom">
          <div class="line"/>
          <div class="list-item">{{i.title}}</div>
        </div>
        <Rich class="html"
        v-model="i.content"
        :style_type="2"
      />
      </div>
      <!-- 一.大数据 显示-->
      <template v-if="data.model_infos&&data.model_infos.length>0">
        <h2 class="commom-text commom-text-top commom-text-bottom">
          <div class="line"/>
          模型详细介绍
        </h2>
        <div v-for="i in data.model_infos" :key="i.diagnosis_id" class="model-infos" @click="to_explain(i)">
          <span>{{i.diagnosis_name}}</span><i class="el-icon-info"/>
        </div>
      </template>
    </div>
    <Detail :visible.sync="explain_visible" :data="explain_item"/>
  </el-dialog>
</template>
<script>
import {get_application_detail} from './service'
import Rich from '../../../components/rich/index.vue'
import {systems} from './config'
import { getImgURL } from "../../../utils/imgUrl"
import {baseURL} from '../../../utils/api'
import Detail from './Detail'
import { user_action } from "../../../common-data/service";


export default {
  data() {
    return {
      data: {},
      loading: false,
      explain_visible: false,
      timer: null,
      open_source: GLOBAL_CONFIG._open_source,
      explain_item: {},
      application_id: '',
      current_list: []
    }
  },
  props: {
    current_arr:{
      type: Array,
      default: ()=>[]
    },
    visible: {
      type: Boolean,
      default: false
    },
    api_type: {
      type: Number,
      default: null
    },
    current_applications_id:{
      type: [String,Number],
      default: null
    }
  },
  components: {
    Rich,
    Detail
  },
  created() {
    this.current_list = JSON.parse(JSON.stringify(this.current_arr))
    this.application_id = this.current_applications_id
    this.search_detail()
  },
  methods: {
    close () {
      this.$emit('update:visible', false)
    },
    before_close () {
      this.close()
    },
    getImgURL,
    item_close(index) {
      if(this.current_list.length ===1){
        this.close()
      } else{
        this.current_list.splice(index,1)
        this.application_id = this.current_list[0].application_id
        this.search_detail()
      }

    },
    select_i(i) {
      this.application_id = i.application_id
      this.search_detail()
    },
    hander_img(list) {
      if(!list) return
      let arr = []
      for(let i of systems) {
        let url = `./common-img/system_list/system-${i.value}.png`
        if(list.includes(i.label))arr.push({...i,url})
      }
      return arr
    },
    to_explain(item) {
      const {shap,roc,recommends=[]} = item
      if(shap) item.hander_shap = baseURL + `/api/v2/images/shap/${shap}`
      if(roc) item.hander_roc = baseURL + `/api/v2/images/roc/${roc}`
      let arr1 = []
      let arr2 = []
      for(let i of recommends) {
        if(i.type===2) {
          arr2.push(i)
        } else {
          arr1.push(i)
        }
      }
      item.arr1 = arr1
      item.arr1.sort((a,b)=>{
        return a.type-b.type
      })
      item.arr2 = arr2
      this.explain_item = item
      this.explain_visible = true
    },
    async search_detail() {
      try {
        this.loading = true
        const {data} = await get_application_detail(this.application_id)
        data.author_info = data.author_info + (data.author_info &&data.author_desc ? '，' : '') + data.author_desc

        this.data = data
        // 埋点
        user_action({
          action_id:  44,
          extra_data: {
            app_id: data.id,
            app_name: data.name,
            report_id: this.$route.query.report_id,
          }
        })
      } finally {
        this.loading = false
      }


    }
  }
}
</script>
<style scoped>
.title {
  font-size: 18px;
  font-weight: 500;
  color: var(--color-text-primary);
  display: flex;
  align-items: center;
  padding-top: 20px;
  & >img {
    width: 70px;
    height: 70px;
    margin-right: 8px;
  }
  & .text {
    position: relative;
    & .inner-test {
      background:#ffb400;
      border-radius: 2px;
      min-height: 30px;
      padding: 2px;
      text-align:center;
      position: absolute;
      top: -20px;
      right: -60px;
      width: 60px;
      color: #fff;
      font-size: 14px;
      z-index: 20;
   }
  }

}
.list {
  display: flex;
  align-items: center;
  background: rgba(0,0,0,0.02);
  margin: 0 -20px;
  padding: 16px 0px 21px;
  & .li-line{
    width: 1px;
    height: 57px;
    background: rgba(0,0,0,0.06);
  }
  & > li {
    padding:0 38px;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    font-weight: bold;
    color: #303133;

    &.systems {
      font-size: 14px;
      color: #303133;
      font-weight: 400;
      & .img {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 16px;
        & .yuan {
          font-size: 13px;
          color: #060A21;
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #FFFFFF;
          border: 2px solid #EBEEF5;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
          border-radius: 50%;
          margin-left: -16px;
        }
      }
      & .label {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        cursor: pointer;
      }
    }

    & .li-text {
      font-weight: 500;
      color: var(--color-text-regular);
      padding-top: 3px;
      font-size: 16px;
    }
    & .systems-name {
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: var(--color-text-regular);
      & .yuan {
        width: 6px;
        height: 6px;
        background: rgba(254,177,16,0.39);
        border-radius: 50%;
        margin-right: 8px;
        &.yuan1{
          background: rgba(7, 193, 96, 1);
        }
      }
    }
    & .img-content {
      padding-left: 10px;
    }

  }
}
.img-i {
  display: flex;;
  align-items: center;
  padding-bottom: 12px;
  &.img-i-1 {
      padding-bottom: 0;
  }
  & .img-label {
    flex: 1;
    padding-left:6px;
    font-size: 14px;
    color: #595D66;
  }
}

.commom-text {
  font-size: 18px;
  font-weight: 500;
  color: var(--color-text-regular);
  display: flex;
  align-items: center;
  &.commom-text-top {
    padding-top: 24px;
  }
  &.commom-text-bottom{
     padding-bottom: 8px;
  }
  &.only {
    align-items: flex-start;
  }
  &.small-title {
    font-size: 16px;
  }
  & .line {
    width: 4px;
    height: 17px;
    background: var(--color-primary);
    border-radius: 2px;
    margin-right: 8px;
  }
  & .lab-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}

.model-infos{
  cursor: pointer;
  margin: 10px 0;
  & >span {
    color:rgba(37, 128, 236, 1);
  }
  & >i {
    padding-left: 5px;
  }
}
.html {
  overflow: auto;
  color: var(--color-text-secondary);
}
.head {
  display: flex;
  flex-wrap: wrap;
  & .application-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 14px;
    & >img {
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
    &.select {
      background: #fff;
    }
    & >i {
      font-size: 20px;
      color: var(--color-text-regular);
      padding-left: 16px;
      padding-top: 2px;
      cursor: pointer;
    }
  }
}

>>>.el-dialog__header{
  padding:0;
  background: rgba(0,0,0,0.02);
}
>>>.el-dialog__body {
  padding-top: 0px;
}

</style>
