<template>
  <div class="chart_wrapper">
    <!-- 图例 -->
    <div class="header_box">
      <div class="legend_box">
        <div
          v-for="(item, index) in legend_data"
          :key="index"
          :class="['item', {unselected: !legend_selected[item], disabled: legend_null[item], highlight: series_name === item}]"
          @click="legend_toggle(item)">
          <div
            class="dot"
            :style="{background: legend_color[item]}"></div>{{item}}
        </div>
      </div>
    </div>
    <!-- 图形 -->
    <div class="chart_box">
      <!-- 背景 -->
      <div class="bg_box">
        <img v-if="style_type === 0" src="../../../assets/img/detail/b_bg_0.png" class="bg" />
        <img v-else-if="style_type === 1" src="../../../assets/img/detail/b_bg_1.png" class="bg" />
        <img v-else src="../../../assets/img/detail/b_bg_2.png" class="bg" />
      </div>
      <!-- 画图 -->
      <div class="chart" ref="myChart"></div>
      <!-- 提示 -->
      <div
        v-for="(item, index) in dot_data"
        :key="index">
        <div
          v-for="(item2, index2) in item"
          :key="index2"
          :class="['lab_box', {show: series_name === item2.name}]"
          @click="series_click(item2.name)">
          <!-- 提示框 -->
          <div
            class="lab_tooltip"
            :style="{left: item2._x + 'px', top: item2._y - 20 + 'px'}"
            @click.stop>
            <!-- <div class="date">{{format_date(item2.list[0].result_time)}}</div>
            <div class="title">{{item2.name}}</div> -->
            <div
              v-for="(item3, index3) in item2.list"
              :key="index3"
              class="row">
              <div class="value">{{item3.original_result}}  {{item3.original_unit || ''}}</div>
              <div class="time" v-if="item2.list.length > 1">{{format_date(item3.result_time, 'HH:mm')}}</div>
            </div>
          </div>
          <!-- 叙述性结果 -->
          <div
            v-if="item2.result_type !== 2"
            :class="['lab_result', {abnormal: item2.abnormal}]"
            :style="{left: item2._x + 'px', top: item2._y + 'px'}">
            {{item2.original_result}}
          </div>
          <!-- 点 -->
          <div
            :class="['lab_dot', {abnormal: item2.abnormal}]"
            :style="{left: item2._x + 'px', top: item2._y + 'px', borderColor: legend_color[item2.name]}">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { format_date } from '../../../utils/format'

// 颜色配置
const color_arr = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']

export default {
  props: {
    data: {
      default: () => [],
      type: Array
    },
    style_type: {
      default: 0,
      type: Number
    },
    up_limit: {
      default: 0,
      type: Number
    },
    down_limit: {
      default: 0,
      type: Number
    },
  },
  data() {
    return {
      myChart: null,
      timer: null,
      x_data: [],
      xAxis: {},
      yAxis: {},
      series: [],
      dot_data: {}, // 点的数据
      series_name: null, // 选中的线
      color_arr,
      legend_data: [], // 图例
      legend_selected: {}, // 图例选择状态
      legend_null: {}, // 图例为空
      legend_color: {}, // 图例颜色
      grid: {}, // 间隔
      max_page_size: 5, // 一屏显示多少个
      y_min: 0, // y轴最小
      y_max: 0, // y轴最大
    }
  },
  computed: {
  },
  watch:{
    data() {
      this.myChart.clear()
      this.init_legend()
      this.init_echart()
    },
  },
  components: {
  },
  created(){
  },
  mounted () {
    // 基于准备好的dom，初始化echarts实例
    this.myChart = echarts.init(this.$refs.myChart)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('resize', this.resize, false)
      this.myChart.clear()
      echarts.dispose(this.myChart)
      this.myChart = null
    })
    this.init_legend()
    this.init_echart()
    window.addEventListener('resize', this.resize, false)

    // 事件绑定
    this.myChart.on('click', (params) => {
      this.series_click(params.seriesName)
    })
    this.myChart.on('datazoom', () => {
      this.render_data()
    })
  },
  methods:{
    format_date,
    series_click (name) {
      this.myChart.dispatchAction({
        type: 'downplay',
      })
      if (this.series_name === name) {
        this.series_name = null
        return
      }
      this.myChart.dispatchAction({
        type: 'highlight',
        seriesName: name,
      })
      this.series_name = name
    },
    // 图例切换
    legend_toggle (item) {
      if (this.legend_null[item]) return
      this.series_click(item)
      // 当前检验项是否全隐藏
      let dot_list = this.dot_data[item]
      if (!dot_list || dot_list.length === 0) {
        this.myChart.dispatchAction({
          type: 'dataZoom',
          // 可选，dataZoom 组件的 index，多个 dataZoom 组件时有用，默认为 0
          dataZoomIndex: 0,
          // 开始位置的百分比，0 - 100
          start: 0,
          // 结束位置的百分比，0 - 100
          end: 100,
        })
        this.myChart.dispatchAction({
          type: 'dataZoom',
          // 可选，dataZoom 组件的 index，多个 dataZoom 组件时有用，默认为 0
          dataZoomIndex: 1,
          // 开始位置的百分比，0 - 100
          start: 0,
          // 结束位置的百分比，0 - 100
          end: 100,
        })
      }
    },
    resize () {
      // 防抖，有自定义图形组件时使用
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.myChart.resize()
        this.echarts()
      }, 50)
    },
    // 初始化图例
    init_legend () {
      this.legend_data = []
      this.legend_selected = {}
      this.legend_null = {}
      for (let [index, item] of Object.entries(this.data)) {
        let name = item.name
        this.legend_data.push(name)
        this.legend_selected[name] = true
        if (!item.histories || item.histories.length === 0) {
          this.legend_null[name] = true
          this.legend_selected[name] = false
        }
        this.legend_color[name] = this.color_arr[index % this.color_arr.length]
      }
    },
    // 初始数据
    init_data () {
      // 过滤数据
      let filter_data = this.data.filter(item => {
        return this.legend_selected[item.name]
      })
      // 新数据
      let new_data = []
      // 所有的时间
      let all_date = []
      for (let item of filter_data) {
        let obj = {}
        for (let item2 of (item.histories || [])) {
          let time_str = format_date(item2.result_time)
          if (!obj[time_str]) obj[time_str] = []
          obj[time_str].push(item2)
          // 时间
          all_date.push(item2.result_time)
        }
        new_data.push(obj)
      }
      // 排序
      all_date.sort()
      all_date = all_date.map(item => format_date(item))
      // x轴
      this.x_data = [...new Set(all_date)]
      this.xAxis = {
        type: 'category',
        axisLabel: {
          color: '#6C757D',
          fontSize: 14,
        },
        axisLine: {
          lineStyle: {
            color: '#ccc'
          },
          onZero: false
        },
        axisTick: {
          lineStyle: {
            color: '#ccc'
          },
          alignWithLabel: true
        },
        data: this.x_data,
      }
      // 线
      this.series = []
      // 所有的值
      let all_value = []
      for (let [index, item] of Object.entries(filter_data)) {
        // 当前值
        const current_data = new_data[index]
        const name = item.name
        let obj = {
          type: 'line',
          name,
          data: [],
          connectNulls: true,
          symbol: 'none',
          z: 3,
          triggerLineEvent: true,
          legendHoverLink: false,
          lineStyle: {
            color: this.legend_color[name]
          },
          emphasis: {
            disabled: true,
            lineStyle: {
              width: 5,
            },
          },
        }
        for (let time_str of this.x_data) {
          let item_list= current_data[time_str]
          if (item_list) {
            let value = 0
            let current_item = item_list && item_list[0]
            for (let item of item_list) {
              if (Math.abs(item.calculate_result) >= Math.abs(value)) {
                value = +item.calculate_result
                current_item = item
              }
            }
            obj.data.push({
              value,
              extra_data: {
                list: item_list,
                name,
                original_specimen: item.original_specimen,
                original_laboratory_name: item.original_laboratory_name,
                ...current_item,
              },
            })
            // 值
            all_value.push(value)
          } else {
            obj.data.push(null)
          }
        }
        this.series.push(obj)
      }
      // 上下界
      this.series.push(
        {
          type: 'line',
          markLine: {
            symbol: 'none',
            silent: true,
            label: {
              position: 'start',
            },
            data: [
              {
                yAxis: this.up_limit,
                label: {
                  color: '#f05252',
                  formatter: '上界',
                },
                lineStyle: {
                  color: '#f05252',
                },
              },
              {
                yAxis: this.down_limit,
                label: {
                  color: '#FEB110',
                  formatter: '下界',
                },
                lineStyle: {
                  color: '#FEB110',
                },
              }
            ]
          },
          z: 1,
        }
      )
      // y轴
      let y_min = Math.min(...all_value)
      y_min = y_min > this.down_limit ? this.down_limit : y_min
      this.y_min = Math.floor(y_min - Math.abs(y_min) * 0.2)
      let y_max = Math.max(...all_value)
      y_max = y_max < this.up_limit ? this.up_limit : y_max
      this.y_max = Math.ceil(y_max + Math.abs(y_max) * 0.5)

      this.yAxis = {
        type: 'value',
        position: 'right',
        axisLabel: {
          show: false,
        },
        min: this.y_min,
        max: this.y_max,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#ccc'
          },
        },
        splitLine: {
          show: false
        },
      }
    },
    init_echart () {
      this.init_data()
      this.$nextTick(() => {
        this.echarts()
      })
    },
    // 渲染数据
    render_data () {
      // 像素转化
      this.dot_data = {}
      // this.series
      this.series.forEach(({data=null, name}) => {
        let arr = []
        if (data) {
          for (let item of data) {
            if (item && item.extra_data) {
              let pix_arr = this.myChart.convertToPixel({seriesIndex: 0}, [format_date(item.extra_data.result_time), item.extra_data.calculate_result])
              let _x = pix_arr[0]
              let _y = pix_arr[1]
              // 是否显示
              let _show = this.myChart.containPixel({seriesIndex: 0}, [_x, _y])

              if (_show) {
                arr.push({
                  ...item.extra_data,
                  _x,
                  _y,
                })
              }
            }
          }
        }
        if (name) this.dot_data[name] = arr
      })
    },
    echarts () {
      this.myChart.clear()
      // x轴dataZoom
      let len = this.x_data.length
      let zoom_show = len > this.max_page_size ? true : false
      let diff = 100
      if (len > 0) {
        diff = 100 / len
      }
      let current_index = len - 1
      let start_1 = current_index * diff
      let start_2 = 100 - this.max_page_size * diff
      let start = Math.min(start_1, start_2)
      let end = start + this.max_page_size * diff

      // y轴dataZoom
      // 上下界最好位置
      let best_y_min = Math.floor(this.down_limit - Math.abs(this.down_limit) * 1.5)
      let best_y_max = Math.ceil(this.up_limit + Math.abs(this.up_limit) * 1.5)
      best_y_min = best_y_min < this.y_min ? this.y_min : best_y_min
      best_y_max = best_y_max > this.y_max ? this.y_max : best_y_max
      let y_diff = 100 / (this.y_max - this.y_min)
      let y_start = (best_y_min - this.y_min) * y_diff
      let y_end = (best_y_max - this.y_min) * y_diff

      this.grid = {
        top: 20,
        left: 40,
        right: 50,
        bottom: 70
      }
      let option = {
        grid: this.grid,
        dataZoom: [
          {
            show: zoom_show,
            start,
            end,
            showDetail: false,
            showDataShadow: false,
            brushSelect: false,
            filterMode: 'none',
            fillerColor: GLOBAL_STYLE.echarts_datazoom_color,
          },
          {
            show: true,
            orient: 'vertical',
            start: y_start,
            end: y_end,
            showDetail: false,
            brushSelect: false,
            filterMode: 'none',
            fillerColor: GLOBAL_STYLE.echarts_datazoom_color,
          }
        ],
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        series: this.series,
        animation: false,
      }
      this.myChart.setOption(option)
      // 渲染数据
      this.render_data()
      // 是否高亮
      if (this.series_name !== null) {
        this.series_click(this.series_name)
      }
    },

  }
}
</script>

<style scoped>
.chart_wrapper {
  height: 500px;
  user-select: none;
  display: flex;
  flex-direction: column;
}
.header_box {
  color: var( --color-text-regular);
  display: flex;
}
/* 图例 */
.legend_box {
  display: flex;
  flex-wrap: wrap;
  flex:1;
  & .item {
    margin-right: 20px;
    display: flex;
    align-items: center;
    color: var( --color-text-regular);
    cursor: pointer;
    &.unselected {
      color: #ccc;
      & .dot {
        background: #ccc !important;
      }
    }
    &.disabled {
      cursor: not-allowed;
    }
    &.highlight {
      font-weight: bold;
      & .dot {
        width: 10px;
        height: 10px;
      }
    }
  }
  & .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--color-danger);
    margin-right: 8px;
  }
}
/* 图形 */
.chart_box {
  flex:1;
  position: relative;
  display: flex;
  flex-direction: column;
}
.chart {
  width: 100%;
 flex:1;
}
.bg_box {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  height: 250px;
  padding: 0 50px 0 50px;
}
.bg {
  height: 100%;
  width: 100%;
}

.lab_box {
  &.show {
    & .lab_dot {
      width: 12px;
      height: 12px;
    }
    & .lab_tooltip {
      display: block;
    }
    & .lab_result {
      display: none;
    }
  }
  &:hover {
    & .lab_dot {
      width: 12px;
      height: 12px;
    }
    & .lab_tooltip {
      display: block;
    }
    & .lab_result {
      display: none;
    }
  }
}
.lab_tooltip {
  position: absolute;
  display: none;
  transform: translate(-50%, -100%);
  z-index: 3;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.04);
  padding: 10px;
  border-radius: 4px;
  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);

  }
  & .date {
    color: #999;
    font-size: 12px;
  }
  & .title {
    font-size: 14px;
    color: var( --color-text-regular);
    margin-bottom: 2px;
    font-weight: 500;
  }
  & .row {
    display: flex;
    justify-content: space-between;
  }
  & .value {
    font-size: 12px;
    color: var( --color-text-regular);
  }
  & .time {
    font-size: 12px;
    color: #909399;
    margin-left: 20px;
  }
}
.lab_dot {
  position: absolute;
  z-index: 3;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid var(--color-danger);
  background: #fff;
  cursor: pointer;
}
.lab_result {
  position: absolute;
  transform: translate(-50%, -30px);
  color: #00C092;
  &.abnormal {
    color: var(--color-danger);
  }
}
</style>
