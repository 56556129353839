import api from '../utils/api'
import store from '../store'

// 菜单权限
export async function get_models () {
  return api.get('/api/v2/system/ai/models/available')
}

// 页面访问日志
export async function page_record (payload) {
  return api.post('/api/v2/logs/save', payload, {defaultAdapterOptions: {
    noWarn: true
  }})
}

// license状态
export async function get_license () {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         state: 2,
  //         limit: 100,
  //         code: 'dadwdawdadada5'
  //       }
  //     })
  //   }, 500)
  // })
  return api.get('/api/v2/license')
}

// license激活
export async function edit_license (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         state: 3,
  //         limit: 0,
  //         code: '99999999999'
  //       }
  //     })
  //   }, 500)
  // })
  return api.post('/api/v2/license/activation', payload)
}
// 获取机器标识符
export async function get_identifier () {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         Identifier: 888
  //       }
  //     })
  //   }, 500)
  // })
  return api.get('/api/v2/license/identifier')
}
// 获取版本列表
export async function get_version () {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         Identifier: 888
  //       }
  //     })
  //   }, 500)
  // })
  return api.get('/api/v2/system/version/item')
}
// 验证
export async function verify_license (payload) {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         Identifier: 888
  //       }
  //     })
  //   }, 500)
  // })
  return api.post('/api/v2/license/verify',payload)
}

// 用户行为统计
export async function user_action (payload) {
  const source = store.state.labDetails2.plugin_data?.source
  payload.extra_data = {
    source,
    ...payload.extra_data
  }
  payload.extra_data = JSON.stringify(payload.extra_data)
  return api.post('/api/v2/embed/actions', payload, {defaultAdapterOptions: {
    noWarn: true
  }})
}

export async function get_patient_info (payload) {
  return api.get(`/api/v2/reading2/patients/${payload.patient_id}/reports`, {params: payload.other})
}

