<template>
  <div class="view-wrapper-box">
    <!-- 正常访问 -->
    <div class="view-wrapper" v-show="!other_app_url">
      <common-header
        v-if="open_source !== 'iframe' && $route.meta.source !== 'patch'"
        class="view-header"
        @toggle_show="toggle_show"
      />
      <div class="view-content">
        <common-menu class="view-left" v-show="menu_show" v-if="!$route.meta.full_screen && open_source !== 'iframe'"/>
        <div class="view-right" id="view_page">
          <div v-show="!$route.meta.no_header" class="view-right-header" v-if="!$route.meta.full_screen && open_source !== 'iframe'">
            <div class="route-name">{{$route?.meta?.show_name || $route.name || (breadcrumb.length>0 && breadcrumb[breadcrumb.length-1].name)}}</div>
            <div class="breadcrumb">
              <img :class="['fold',{'unfold': menu_show}]" src="../assets/img/show_menu.png" @click="toggle_show()">
              <Breadcrumb />
            </div>
          </div>
          <div class="view-right-content">
            <keep-alive :include="include" :max="max">
              <router-view />
            </keep-alive>
          </div>
        </div>
      </div>
    </div>
    <!-- 其它应用 -->
    <div class="other_app" v-if="other_app_url" v-loading="iframe_loading">
      <iframe style="display:block;" scrolling="no" height="100%" width="100%" frameborder="no" ref="iframe" :src="other_app_url_2" />
    </div>
    <!-- 菜单栏 -->
    <Nav
      v-if="open_source !== 'iframe' && $route.meta.show_nav"
      @change="to_url"
      :other_app_url="other_app_url"
      />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { create_guid } from "../utils/format";
import { pushState } from "../utils/popstate"
import CommonHeader from '../components/common-header'
import CommonMenu from '../components/common-menu'
import Breadcrumb from '../components/Breadcrumb'
import Nav from "../components/Nav";

export default {
  name: 'common_layout',
  data () {
    return {
      open_source: GLOBAL_CONFIG._open_source,
      // 其它应用
      timer: null,
      iframe_loading: false,
      listen_name: null,
      random_name: create_guid(),
    }
  },
  computed: {
    ...mapState('commonData', {
      menu_show: state => state.menu_show,
      other_app_url: state => state.other_app_url,
      breadcrumb: state => state.breadcrumb,
    }),
    ...mapState('keepAlive', {
      max: state => state.max,
      include: state => state.include,
    }),
    other_app_url_2 () {
      let url = location.href
      let host = `/${this.random_name}.html#`
      if (url.includes('#')) {
        host = `${url.split('#')[0]}${this.random_name}.html#`
      }
      // 本地调试
      // let host = '/#'
      if (this.other_app_url) {
        if (this.other_app_url.includes('?')) {
          return host + this.other_app_url + '&_open_source=iframe'
        }
        return host + this.other_app_url + '?_open_source=iframe'
      }
      return host + this.other_app_url

    }
  },
  components: {
    CommonHeader,
    CommonMenu,
    Breadcrumb,
    Nav
  },
  watch: {
    other_app_url (val, old_val) {
      if (!val) {
        window.removeEventListener(this.listen_name, this.popstate, false)
      }
      else if (!old_val && val) {
        // 历史回退
        this.listen_name = create_guid()
        pushState(this.listen_name)
        window.addEventListener(this.listen_name, this.popstate, false)
      }
    }
  },
  created () {
    // iframe中控制关闭
    window.iframe_close = () => {
      this.update_other_app_url(null)
      this.$router.back()
    }
  },
  updated(){
    if (!this.other_app_url) {
      let dom = document.getElementById('view_page')
      if(dom)dom.scrollTop = 0
    }
  },
  destroyed () {
  },
  methods: {
    ...mapMutations('commonData', [
      'set_menu_show',
      'update_other_app_url'
    ]),
    toggle_show () {
      this.set_menu_show(!this.menu_show)
    },
    popstate () {
      if (this.other_app_url) {
        this.update_other_app_url(null)
      }
    },
    // 其它应用
    to_url (path) {
      this.random_name = create_guid()
      this.update_other_app_url(path)
      // 模拟等待
      this.iframe_loading = true
      if(this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.iframe_loading = false
      }, 300)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.view-wrapper-box {
  height: 100%;
  display: flex;
}
.view-wrapper {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #FAFBFE;
  overflow: hidden;
}
.view-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.view-left {
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.view-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.view-right-header {
  padding: 20px 20px 10px;
  display: flex;
  justify-content: space-between;
}
.route-name  {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  color: #303133;
}
.fold {
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin-left: 181px;
  margin-right: 10px;
  transform: rotate(-180deg);
  &.unfold {
    transform: rotate(0deg);
  }
}
.breadcrumb {
  display: flex;
  align-items: center;
}
.view-right-content {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
/* 其它应用 */
.other_app {
  flex:1;
  height: 100%;
  position: relative;
  z-index: 5000;
}
</style>
