
//应用系统
export const systems = [
  {"label":"五官科疾病",value:1 },
  {"label":"产科疾病", value: 2},
  {"label":"其他",value: 3},
  {"label":"内分泌代谢性疾病", value: 4},
  {"label":"呼吸系统疾病",value: 5},
  {"label":"心血管疾病",value: 6},
  {"label":"感染性疾病",value: 7},
  {"label":"泌尿系统疾病",value: 8},
  {"label":"消化系统疾病",value: 9},
  {"label":"生殖系统疾病",value: 10},
  {"label":"皮肤疾病",value: 11},
  {"label":"神经系统疾病",value: 12},
  {"label":"精神疾病",value: 13},
  {"label":"肌肉骨骼疾病",value: 14},
  {"label":"肿瘤性疾病",value: 15},
  {"label":"血液系统疾病",value: 16},
  {"label":"风湿免疫病",value: 17}
]
