<template>
  <el-drawer
    :visible.sync="visible"
    class="my-drawer"
    size="700px"
    top="40px"
    :destroy-on-close="true"
    :append-to-body="true"
    :before-close="before_close">
    <div class="title" slot="title">
      <h1 class="text">{{data.diagnosis_name}}</h1>
    </div>
    <div class="dialog_content">
      <template v-if="performances?.length">
        <h2 class="commom-text commom-text-top commom-text-bottom">
          <div class="line"/>
          性能
          <img width="16px" height="auto" @click="to_explain('性能')" style="margin-left: 4px;cursor: pointer;" src="../../../assets/img/detail/explain.png"/>
        </h2>
        <Table  :data="performances"/>
      </template>
        <template v-if="data.roc || data.roc_url">
        <h2 class="commom-text commom-text-top commom-text-bottom" >
          <div class="line"/>
          ROC曲线
          <img @click="to_explain('ROC曲线')" width="16px" height="auto" style="margin-left: 4px;cursor: pointer;" src="../../../assets/img/detail/explain.png"/>
        </h2>
        <img v-if=" data.shap_url" style="max-width: 80%;display: block;margin:0 auto;" height="auto"  :src="baseURL + data.roc_url">
        <img v-else-if="data.roc" style="max-width: 80%;display: block;margin:0 auto;" height="auto"  :src="data.hander_roc">
      </template>
      <template v-if="data.shap || data.shap_url">
        <h2 class="commom-text commom-text-top commom-text-bottom" >
          <div class="line"/>
          SHAP图
          <img width="16px" height="auto" @click="to_explain('SHAP图')" style="margin-left: 4px;cursor: pointer;" src="../../../assets/img/detail/explain.png"/>
        </h2>
        <img v-if="data.shap" style="max-width: 80%; display: block;margin:0 auto;" height="auto" :src="data.hander_shap">
        <img v-else-if="data.roc_url" style="max-width: 80%; display: block;margin:0 auto;" height="auto" :src="baseURL + data.shap_url">
      </template>
    </div>
    <Explain :visible.sync="explain_visible" :name="explain_name"/>
  </el-drawer>
</template>
<script>
import Table from './Table'
import Explain from './Explain'
import {baseURL} from '../../../utils/api'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    data: {
      default: ()=>{},
      type: Object
    }
  },
  data () {
    return {
      explain_visible: false,
      explain_name: '',
      baseURL
    }
  },
  computed: {
    performances() {
      let performances = []
      if(this.data?.performances?.length) {
        performances = this.data.performances
      } else if(this.data?.performance_items?.length) {
        performances = this.data.performance_items
      }
      return performances
    }
  },
  components: {
    Table,
    Explain

  },
  watch: {
  },
  created() {

  },
  methods: {
    close () {
      this.$emit('update:visible', false)
    },
    before_close () {
      this.close()
    },
    to_explain(name) {
      this.explain_name = name
      this.explain_visible = true
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row-apply';
      } else {
        return ''
      }
    }
  }
}
</script>
<style>
.jup{
  color: #6C757D;
  &:hover{
    color:var(--color-primary);
  }
}
.evidence .el-dialog__header {
  border-bottom: 1px solid #DEE2E6;
}
.decision_dislog {
  & .el-dialog__header {
    position: relative;
    z-index: 2;
  }
}
</style>
<style scoped>
.title {
  position: relative;
  & .text{
    position: relative;
    z-index: 1;
    font-size: 30px;
    font-weight: bold;
    color: #303133;
    padding:40px 28px 0px;
    padding-bottom:32px;
    & >span {
      font-size: 20px;
      font-weight: 400;
      color: #98A6AD;
      line-height: 28px;
      margin-left: 12px;
    }
  }
  & .img {
    position: absolute;
  }
}
.text1 {
  position: relative;
  padding-right: 25px;
  & >span {
    font-size: 12px;
    position: absolute;
    top: -5px;
  }
}

.dialog_content {
  padding: 0 28px 32px;
 font-size: 16px;
  line-height: 24px;
  color: #303133;
  overflow: auto;
}
.h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #303133;
  padding: 28px 0 8px;
  &.h2-1 {
    padding-top: 0px;
  }

}
.wenxian {
  color: var(--color-text-secondary);
  line-height: 24px;
}
.add-bold {
  font-weight: bold;
}
.commom-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #303133;
  display: flex;
  align-items: center;
  &.commom-text-top {
    padding-top: 32px;
  }
  &.commom-text-bottom{
     padding-bottom: 12px;
  }
  &.only {
    align-items: flex-start;
  }
  &.small-title {
    font-size: 16px;
  }
  & .info {
    font-size: 16px;
    line-height: 24px;
    color: var(--color-primary);
    flex:1;
  }
  & .line {
    width: 4px;
    height: 16px;
    background: var(--color-primary);
    border-radius: 3px;
    margin-right: 8px;
  }
  & .lab-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}
>>>.el-drawer.rtl {
  overflow: auto;
}

>>>.el-dialog__body {
  padding: 0px 20px;
}
>>>.el-drawer__body {
  padding: 0px 20px;
  position: relative;
  z-index: 10;
}
>>>.el-drawer__close-btn {
  position: absolute;
  right: 20px;
  top:40px;
  z-index: 100;

  & .el-dialog__close {
    transform: scale(1.4);
    font-size: 22px;
    color: #707070;
    font-weight: bold;
  }
}
>>> .el-drawer__header {
  margin-bottom: -12px;
}
>>>.weight .warning-row-apply {
  background: var(--color-bg-primary);
}

</style>

