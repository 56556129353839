<template>
 <div class="content" :id="id">
   <template v-if="show_desc">
      <div v-for="(i,index) in list" :key="index" >
        <div v-if="show || (!show&&index<2)" :class="['plan-item',{'hide-line':hide_line},  {'other-type': another}]">
          <img width="26" height="26" :src="getImgURL(i.app_icon)"/>
          <span class="round-dot"></span>
          <ul style="flex:1">
            <li v-if="!hide_line" @click="look_item_detail(i)" class="plan-item-title"><span :class="{'is-color': show_desc}">{{i.application_name}}</span><template v-if="show_desc"> 提示：</template></li>
            <li class="desc" v-if="show_desc">
              <div v-if="another">
                <div :class="{'desc-out':idx !== i.hint_contents.length-1}" style="padding: 10px; 0" v-for="(q, idx) in i.hint_contents" :key="idx">
                  <div class="desc-content">
                    <div class="test">高</div>
                    <span v-html="q"></span>
                    <span class="show_no" @click.stop="open_evd(i, q)" >
                      阅读更多
                    </span>
                  </div>
                </div>
              </div>
              <div class="desc-content" v-else>
                <div class="test">高</div><span v-html="hander_html(i)"></span>
                <span class="show_no" @click.stop="open_evd(i)" >
                  阅读更多
                </span>
              </div>
            </li>
          </ul>
        </div>
     </div>
   </template>
    <template v-else>
      <div v-for="(i,index) in list" :key="index" class="plan-item" style="cursor: pointer;" @click="look_item_detail(i)">
        <img width="26" height="26" :src="getImgURL(i.app_icon)"/>
        <ul style="flex:1">
          <li class="plan-item-title"><span :class="{'is-color': show_desc}">{{i.application_name}}</span><template v-if="show_desc">提示：</template></li>
        </ul>
       <i  class="el-icon-arrow-right arrow"/>
     </div>
    </template>
    <TriggerModelDetail
    :visible.sync="detail_visible"
    :item="item"
    v-if="detail_visible"/>
    <ApplicationDialog  :visible.sync="visible" v-if="visible" :current_arr="current_arr" :current_applications_id="current_applications_id"/>
  </div>
</template>
<script>
import TriggerModelDetail from './TriggerModelDetail'
import ApplicationDialog from './application-detail'
import { getImgURL } from "../../utils/imgUrl"
import { mapMutations,mapState, mapGetters } from "vuex"
import { create_guid } from '../../utils/format'
import { user_action } from "../../common-data/service";

export default {
  props: {
    list: {
      type: Array,
      default: ()=>[]
    },
    show_desc: {
      type: Boolean,
      default: false
    },
    hide_line:{
      type: Boolean,
      default: false
    },
    another:{
      type: Boolean,
      default: false
    },
    show:{
      type: Boolean,
      default: true
    },
  },
  data () {
    return {
      detail_visible: false,
      getImgURL,
      item:{},
      id: this.create_guid(),
      timer: null,
      timer1: null,
      visible: false,
      current_arr: [],
      current_applications_id: '',
      api_type: null
    }
  },
  computed: {
    ...mapState("labDetails", {
      loading: (state) => state.loading,
    }),
    ...mapGetters("labDetails2", [
      "report_id",
    ]),
  },
  components: {
    TriggerModelDetail,
    ApplicationDialog
  },
  watch: {
    list() {
      if(this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.set_height()
      },50)
    },
    show() {
      if(this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.set_height()
      },50)
    }
  },
  created() {
    if(this.list.length) {
      this.$nextTick(this.set_height)
    }
  },
  methods: {
    create_guid,
    ...mapMutations("commonData", [

    ]),
    hander_html(i) {
      return (i.title ? '<span style="padding-right: 10px;color: #000;font-weight: 500;">' +i.title + '</span>' : '') +  (i.fx || '') + (i.hint_content || '')
    },
    open_evd(i, q) {
      this.detail_visible = true
      if(q) {
        i.hint_content = q
      }
      this.item = JSON.parse(JSON.stringify(i))
      // 埋点
      user_action({
        action_id:  41,
        extra_data: {
          report_id: this.report_id,
          app_id: i.application_id,
          app_name: i.application_name,
          msg: i.hint_content
        }
      })
    },
    set_height(more) {
      let out_dom = document.getElementById(this.id)
      let desc = [...out_dom.getElementsByClassName("desc-content")];
      let show_no = [...out_dom.getElementsByClassName("show_no")];
      let test = [...out_dom.getElementsByClassName("test")];
      //获取当前的高度
      let single_height = test[0].clientHeight
      if(more) {
        desc.forEach((item, index) => {
          show_no[index].className = "show_no"
          item.className = "desc-content";
        })
      }
      if(this.timer1) clearTimeout(this.timer1)
      this.timer1 = setTimeout(() => {
        desc.forEach((item,index) => {
          if(item.clientHeight> single_height*2) {
            item.className = "desc-content hold_box";
            show_no[index].className = "show_no show_more"
            item.style.height = single_height*2 + 'px'
          } else {
            if(this.list[index]&&this.list[index].title) show_no[index].className = "show_no show_more show_more_last"
          }
        });
      }, 50);
    },
    look_item_detail(i) {
      this.visible = true
      this.current_arr = [i]
      this.current_applications_id = i.application_id
    },
  }
}
</script>
<style scoped>
.content {
  & .plan-item {
    display: flex;
    padding: 13px 0 6px 0;
    padding-left: 9px;
    border-bottom: 1px solid #ebeef5;
    &.hide-line{
       border-bottom: none;
    }

    &.other-type {
      align-items: flex-start;

      & img {
        position: relative;
        top: -2px;
      }
    }

    & >ul {
      font-size: 14px;
      color: var(--color-text-regular);
      & .plan-item-title {
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        & >span {
          &.is-color {
            color: var(--color-primary);
          }

        }
      }
      & .desc {

        & .desc-content {
          flex:1;
          position: relative;
          line-height: 1.5;
          font-size: 14px;
        }

      }
    }
  }
}
.show_no{
  color:var(--color-primary);
  display: none;
  position: absolute;
  right: 0;
  bottom: 0;
  &.show_more{
    color:var(--color-primary);
    background: #fff;
    padding-left: 8px;
    display: block;
    cursor: pointer;
  }
}
.icon {
  position: absolute;
  color: rgba(255, 255, 255, 1);
  right: -5px;
  top: 135px;
  font-size: 26px;
  z-index: 10;
}

.open_icon_box {
  cursor: pointer;
}
.hold_box{
  height: 44px;
  overflow: hidden;
  position: relative;
  &::before {
    content: '...';
    position: absolute;
    width:77px;
    height: 20px;
    bottom: 1px;
    padding-left:2px;
    right: 0px;
    background: #fff;
  }
}
.arrow {
  font-size: 22px;
  color:rgba(144, 147, 153, 1);
  cursor: pointer;
}

.desc-out {
  border-bottom: 1px solid #ebeef5;
}

.round-dot {
 display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: var(--color-warning);
  position: relative;
  top: 8px;
  margin-right: 6px;
  margin-left: 17px;
}
.test{
  position: absolute;
  color: transparent;
}

</style>
