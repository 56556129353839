<template>
<!-- 血气 -->
  <div class="type_content" v-if="is_has_data">
     <!-- 血气列表  -->
    <ul class="flow-content left">
      <li v-for="(i,index) in list" :key="i.bga_report_id" @click="select_report(i)" style="cursor: pointer;">
        <div class="line-left" v-if="index !== list.length-1"/>
        <div class="icon icon-left"></div>
        <div :class="['message','message-left',{'select-message': i.bga_report_id === current_report_id}]">
          <div class="time time-left">{{format_date(i.report_time,'yyyy-MM-dd HH:mm')}}</div>
          {{i.natural_conclusion.replace(/\n/g,'；')}}
        </div>
      </li>
    </ul>
    <div class="right is-hav-pad" style="padding-bottom: 60px;">
      <div class="commom-text commom-text-top">报告时间：<span class="info">{{format_date(data.report_time,'yyyy-MM-dd HH:mm')}}</span></div>
        <div class="commom-text commom-text-top">
          病例类型：
          <el-radio-group size="small" v-model="last_select_radio" @change="select_flesh">
          <el-radio-button :label="1">急性</el-radio-button>
          <el-radio-button :label="2">慢性</el-radio-button>
        </el-radio-group>
      </div>
      <!--api_type === 3 ：血气分析 类型 的结论-->
      <template v-if="data.laboratories&&data.laboratories.length>0">
        <template  v-if="last_select_radio === 1&&data.acute">
          <div v-if="data.acute.group_result&&data.acute.group_result.length>0" class="conclusion-content">
            <div class="conclusion-title">综合判断：</div>
            <ul>
              <li class="conclusion" v-for="(i,index) in data.acute.group_result" :key="index">
                {{i.join('；')}}
              </li>
            </ul>
          </div>
          <div class="result-info"><i class="el-icon-warning-outline"/>本血气分析结果，仅在患者体温正常情况下有效，不适用于发热患者。氧合状态分析只适用于未吸氧的动脉血样本。</div>
          <div v-if="data.acute.tips&&data.acute.tips.length>0" class="conclusion-content">
            <div class="conclusion-title">风险提示：</div>
            <span>{{data.acute.tips.join('；')}}</span>
          </div>
          <div v-if="data.acute.hints&&data.acute.hints.length>0" class="conclusion-content">
            <div class="conclusion-title">更多提示：</div>
            <CommonTrigger :hide_line="true" style="flex:1;margin-top: -22px; margin-left: -10px;" :show_desc="true" :list="data.acute.hints"/>
          </div>
        </template>
        <template v-if="last_select_radio === 2&&data.chronic">
          <div v-if=" data.chronic.group_result&& data.chronic.group_result.length>0" class="conclusion-content">
            <div class="conclusion-title">综合判断：</div>
            <ul>
              <li class="conclusion" v-for="(i,index) in data.chronic.group_result" :key="index">
                {{i.join('；')}}
              </li>
            </ul>
          </div>
          <div class="result-info"><i class="el-icon-warning-outline"/>本血气分析结果，仅在患者体温正常情况下有效，不适用于发热患者。氧合状态分析只适用于未吸氧的动脉血样本。</div>
          <div v-if="data.chronic.tips&&data.chronic.tips.length>0" class="conclusion-content">
            <div class="conclusion-title">风险提示：</div>
            <span>{{data.chronic.tips.join('；')}}</span>
          </div>
          <div v-if="data.chronic.hints&&data.chronic.hints.length>0" class="conclusion-content">
            <div class="conclusion-title">更多提示：</div>
            <CommonTrigger :hide_line="true" style="flex:1;margin-top: -22px;margin-left: -10px;" :show_desc="true" :list="data.chronic.hints"/>
          </div>
        </template>
      </template>
       <template v-if="data.laboratories&&data.laboratories.length>0">
        <h2 class="commom-text commom-text-top commom-text-bottom">
          <div class="line"/>
          <span style="flex: 1">诊断依据：</span>
        </h2>
        <span v-if="data.patient_age" style="padding-right: 20px;">年龄：{{data.patient_age}}</span><span v-if="data.patient_gender" style="">性别：{{data.patient_gender}}</span>
        <LabTable :data="data.laboratories" :api_type="data.api_type"/>
      </template>
      <!--血气分析 api_type===3 血气分析值显示 -->
      <div class="commom-text commom-text-top commom-text-bottom" style="margin-bottom: -20px;">血气数值分析：</div>
      <template v-if="last_select_radio === 1">
        <div v-for="(i,index) in data.acute_arr" :key="i.value + 'i'">
          <div class="commom-text commom-text-top">{{index + 1}}. {{i.name}}</div>
          <span v-html="format_html(i.value)"/>
        </div>
      </template>
      <template v-else>

        <div v-for="(i,index) in data.chronic_arr" :key="i.value">
          <div class="commom-text commom-text-top">{{index + 1}}. {{i.name}}</div>
          <span v-html="format_html(i.value)"/>
        </div>
      </template>
       <template v-if="data.need_focus&&data.need_focus.length>0">
       <slot name="focus"></slot>
        <h2 class="commom-text commom-text-top commom-text-bottom">
          <div class="line"/>
          <span style="flex: 1">还需关注的项目</span>
        </h2>
        <LabTable :data="data.need_focus" :api_type="data.api_type"/>
      </template>
    </div>
  </div>
</template>
<script>
import CommonTrigger from '../CommonTrigger.vue'
import LabTable from './LabTable'
import {format_date} from '../../../utils/format'
import { mapState,mapActions } from 'vuex'
import {get_blood_spirits,to_save_flesh} from './service'
export default {
  data() {
    return {
      last_select_radio: 1,
      is_first: true,
      timer: null
    }
  },
  props: {
    data:{
      type: Object,
      default: () =>{}
    },
    list: {
      type: Array,
      default: () =>[]
    },
    current_report_id: {
      type: String,
      default:''
    },
    is_has_data: {
      type: Boolean,
      default: false
    },
    patient_id:{
      type: String,
      default:''
    },
    report_id: {
      type: String,
      default:''
    }
  },
  components: {
    CommonTrigger,
    LabTable,
  },
  computed:{
    ...mapState('labDetails', {
      flesh_list: state => state.flesh_list,
    })
  },
  watch: {
    flesh_list(val){
      this.$emit('update_list',val)
      if(val.length>0&&!this.current_report_id) {
        this.$emit('select_common_report',val[0].bga_report_id)
      } else {
        this.search_blood_spirits()
      }
      if(this.is_first) {
        this.$emit('update_loading',false)

        this.is_first = false
      }
    },
    current_report_id(){
      this.search_blood_spirits()
    }
  },
  created() {
    this.$emit('update_loading',true)
    this.search_flesh_list({patient_id:this.patient_id})
  },
  methods: {
    ...mapActions('labDetails', [
      'search_flesh_list',
      'search_trigger_list',
    ]),
    format_date,
    // html文字处理
    format_html (text) {
      return text.replace(/</g, '&lt;').replace(/\n/g,'<br/>')
    },
    // 防抖
    select_flesh(val) {
      if(this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(()=>{
        this.save_flesh(val)
      },600)
    },
    // 获取当前血气报告-急性或者慢性
    hander_last(val) {
      let obj_arr = val.filter(i=>{
        return i.bga_report_id === this.current_report_id
      })
      if(obj_arr.length>0) this.last_select_radio = obj_arr[0].type
    },
    // 选择血气报告
    select_report(i) {
      this.$emit('select_common_report',i.bga_report_id)
    },
    // 血气详情
    async search_blood_spirits (id) {
      if(!this.current_report_id) return
      this.$emit('update_loading',true)
      try {
        const {data} = await get_blood_spirits ({name: this.name,report_id: this.current_report_id})
        if(id) {
          this.search_flesh_list({patient_id:this.patient_id})
        } else {
          this.hander_last(this.flesh_list)
        }
        const {acute,chronic,report_time,need_focus} = data
        let list = []
        if(report_time)list.push({key: 'laboratories',name: '结果'})
        if(need_focus&&need_focus.length>0) list.push({key: 'focus',name: '还需关注的项目'})
        // if(additional_infos && additional_infos.length>0 &&data.api_type === 2) {
        //   let index = 0
        //   for(let item of additional_infos) {
        //     list.push({key:'additional_infos',name: item.title,index: index})
        //     index ++
        //   }
        // }
        // 菜单
        this.$emit('update_menus',list)
        data.acute_arr = []
        if(acute) {
          const {ph_balance,electrolyte,lactic_acid,hypoxia} = acute
          data.acute_arr.push({name: '酸碱平衡判断：',value: ph_balance || '暂未发现酸碱失衡'})
          if(electrolyte) data.acute_arr.push({name: '电解质判断：',value: electrolyte})
          if(lactic_acid) data.acute_arr.push({name: '乳酸判断：',value: lactic_acid})
          if(hypoxia) data.acute_arr.push({name: '缺氧判断：',value:hypoxia})
        }
        data.chronic_arr = []
        if(chronic) {
          const {ph_balance,electrolyte,lactic_acid,hypoxia} = chronic
          data.chronic_arr.push({name: '酸碱平衡判断：',value: ph_balance || '暂未发现酸碱失衡'})
          if(electrolyte) data.chronic_arr.push({name: '电解质判断：',value: electrolyte})
          if(lactic_acid) data.chronic_arr.push({name: '乳酸判断：',value: lactic_acid})
          if(hypoxia) data.chronic_arr.push({name: '缺氧判断：',value:hypoxia})
        }
        this.$emit('update_data',{api_type: this.data.api_type,...data})
      } finally {
        this.$emit('update_loading',false)
      }
    },
    async save_flesh (val) {
      this.loading = true
      try {
        await to_save_flesh({type:val, patientId:this.data.patient_id,bga_report_id: this.current_report_id})
        this.search_trigger_list({report_id: this.report_id})
        this.search_blood_spirits(this.report_id)
      } finally {
        this.loading = false
      }
    },

  }
}
</script>
<style scoped>
.commom-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #303133;
  display: flex;
  align-items: center;
  &.commom-text-top {
    padding-top: 32px;
  }
  &.commom-text-bottom{
     padding-bottom: 12px;
  }
  &.small-title {
    font-size: 16px;
  }
  & .info {
    font-size: 16px;
    line-height: 24px;
    color: var(--color-primary);
    flex:1;
     &.risk {
      color: red;
    }
  }
  & .line {
    width: 4px;
    height: 16px;
    background: var(--color-primary);
    border-radius: 3px;
    margin-right: 8px;
  }
  & .lab-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}
.flow-content{
  & >li {
    display: flex;
    position: relative;
    & .line {
      position: absolute;
      border-left: 2px dotted rgba(0, 136, 255, 0.69);
      top: 0px;
      left: 7px;
      height: 100%;
    }
    & .line-left {
      position: absolute;
      border-left: 2px dotted rgba(0, 136, 255, 0.69);
      top: 0px;
      left: 6px;
      height: 100%;
    }
    & .icon {
      width: 16px;
      height: 16px;
      background: #fff;
      border: 4px solid rgba(0, 136, 255, 1);
      border-radius: 50%;
      font-size: 14px;
      font-weight: 600;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      position: relative;
      z-index: 1;
      &.icon-left {
        width: 14px;
        height: 14px;
      }
    }
    & .message {
      flex:1;
      font-size: 14px;;
      font-weight: 500;
      line-height: 16px;
      padding-bottom: 18px;
      color: #262626;
      margin-top: -2px;
      &.message-left{
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #303133;
        &.select-message {
          color: var(--color-primary);
        }
      }
      & .time {
        font-size: 12px;;
        font-weight: 400;
        line-height: 19px;
        color: #565656;
        & .time-left {
          font-size: 12px;
          line-height: 17px;
          color: var(--color-text-secondary);
          padding-top: 7px;
        }
      }
    }
  }
}
.type_content {
  flex:1;
  & .left {
    width: 260px;
    overflow: auto;
    position: absolute;
    left: 0px;
    bottom: 0;
    padding-left: 28px;
    top: 0;
    border-right: 1px solid rgba(112, 112, 112, 0.14);
    padding-top: 28px;
  }
  & .right {
    padding-bottom: 100px;
    margin-left: 260px;

    &.is-hav-pad{
      padding-left: 28px;
    }
  }
}
.conclusion-content {
  display: flex;
  padding: 32px 0 12px;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-primary);
  & .conclusion-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #303133;
  }
}
.result-info {
  color: #939395;
  padding-left:64px;
  display: flex;
  line-height: 18px;
  & >i{
    padding-right: 10px;
    padding-top: 2px;
  }
}
</style>
