// 页面数据
export const global_page_data = {}
// 页面层级
const global_page_deepth = {}

export default {
  namespaced: true,
  state: {
    max: 5, // 最大缓存页面
    include: [], // 缓存哪些页面
  },
  mutations: {
    update_keep_alive (state, payload) {
      const to = payload.to || {}
      const from = payload.from || {}
      const routes = payload.routes || []
      const to_name = to.meta?.name
      const from_name = from.meta?.name
      const to_deepth = to.meta?.deepth

      // 如果要to(进入)的页面是需要keepAlive缓存的
      if (to.meta?.keep_alive && !state.include.includes(to_name)) {
        state.include.push(to_name)
        global_page_deepth[to_name] = to_deepth
      }

      // 去掉高层级的缓存
      if (to_deepth) {
        state.include = state.include.filter(name => (to_deepth > global_page_deepth[name] || name === to_name))
      }

      // 更新父view
      if (state.include.length) {
        const children_view = (childrens) => {
          let flag = false
          for (const item of childrens) {
            const name = item.meta?.name
            const children = item.children || []
            if (children.length) {
              if (children_view(children)) {
                !state.include.includes(name) && state.include.push(name)
                flag = true
              } else {
                const index = state.include.indexOf(name)
                index !== -1 && state.include.splice(index, 1)
              }
            }
            if (name && state.include.includes(name)) {
              flag = true
            }
          }
          return flag
        }
        children_view(routes)
      }

      // 更新页面数据
      // 保存滚动条信息
      if (state.include.includes(from_name)) {
        // 同一个页面不用保存
        if (to_name === from_name) {
          delete global_page_data[from_name]
          return
        }
        const classname = from.meta.scroll_seletor || '.keep_alive'
        global_page_data[from_name] = {
          classname: classname,
          scrolls: [],
        }
        const keep_alives = [...document.querySelectorAll(classname)]
        for (const item of keep_alives) {
          global_page_data[from_name].scrolls.push([item.scrollLeft, item.scrollTop])
        }
      }

      // 清除无用滚动条信息
      for (const name in global_page_data) {
        if (!state.include.includes(name)) {
          delete global_page_data[name]
        }
      }
    },
    // 删除页面缓存
    delete_page (state, payload) {
      const index = state.include.indexOf(payload)
      index !== -1 && state.include.splice(index, 1)
      delete global_page_data[payload]
    },
  },
  actions: {
  }
}

// 恢复页面
export function restore_page (page_name) {
  const data = global_page_data[page_name]
  if (!data) return
  const classname = data.classname
  const scrolls = data.scrolls

  const keep_alives = [...document.querySelectorAll(classname)]
  let index = 0
  for (const item of keep_alives) {
    item.scrollLeft = scrolls[index] && scrolls[index][0]
    item.scrollTop = scrolls[index] && scrolls[index][1]
    index++
  }
}
