<template>
  <div :class="['out',{'out1': $route.meta.source === 'patch'}]">
    <div class="bg" @click="close()"></div>
    <i class="el-icon-caret-right icon"/>
    <div class="live" v-loading="loading">
      <i class="el-icon-close t-close" @click="close()"/>
      <div class="title" v-if="list.length>0">
        <img class="title-bg" width="88" height="79" src="../../assets/img/detail/trigger-bg.png">
        <div class="title-left">
          本次分析触发了<span class="num">{{list.length}}</span>个智慧检验分析应用
          <div class="info">点击应用可查看具体介绍和研究合作团队信息</div>
        </div>
      </div>
      <div class="t-body">
        <div class="no-data" v-if="list.length===0&&!loading">
          <img src="../../assets/img/detail/img_no_data.png" width="120" height="120"/>
          <div class="info">暂无触发应用</div>
        </div>
        <CommonTrigger v-else :list="list"/>
    </div>
    <div class="footer" @click="look_all">查看所有应用</div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex"
import CommonTrigger from './CommonTrigger'
import { mapMutations } from "vuex"
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: ()=>[]
    },
    report_id:{
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      detail_visible: false,
    }
  },
  computed: {

  },
  components: {
    CommonTrigger,
  },
  watch: {

  },
  created() {
    this.search_trigger_list({report_id:this.report_id})
  },
  methods: {
    ...mapActions("labDetails", [
      'search_trigger_list',
    ]),
    ...mapMutations("commonData", [
      'update_other_app_url',
    ]),
    look_all() {
      this.update_other_app_url('/appstore')
    },
    close() {
      this.$emit('update:visible', false)
    }
  }
}
</script>
<style scoped>

@-webkit-keyframes live-in {
  0% {
    transform: translate(100%, 0)
  }

  100% {
    transform: translate(0, 0)
  }
}

@-webkit-keyframes live-out {
  0% {
    transform: translate(0, 0)
  }

  100% {
    transform: translate(0, 100%)
  }
}

.out.live {
  animation: live-in .3s 1ms
}
.out.live{
  animation: live-out .3s
}
.out {
  position: absolute;
  top: 0;
  left: 0;
  right: 60px;
  bottom: 0;
  z-index: 20;
  transition: all .3s ease-in-out;

  & .bg {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0px;
    bottom: 0;
    /* background: rgba(0,0,0, 0.5); */
  }
  & .live {
    position: absolute;
    right: 10px;
    top: 110px;
    height: 372px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 372px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: auto;
    & .t-close {
      position: absolute;
      font-size: 24px;
      cursor: pointer;
      right: 15px;
      top: 15px;
      font-weight: bold;
      z-index: 5;
      color: #707070;
    }
  }
}
.title {
  background: rgba(254, 249, 237, 0.39);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 14px;
  font-size: 16px;
  padding: 0px 14px;
  font-weight: 600;
  color: var(--color-text-secondary);
  line-height: 22px;
  display: flex;
  align-items: center;
  position: relative;
  height: 79px;
  & .title-bg {
    position: absolute;
    right: 0px;
  }
  & .title-left {
    flex:1;
    z-index: 1;
    position: relative;
    font-size: 15px;
    font-weight: 600;
    line-height: 21px;
    color: #242938;
    & .num {
      color: rgba(240, 86, 86, 1);
    }
    & .info {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      color: #565656;
      padding-top: 2px;
    }
  }
}
.t-body {
  flex:1;
  padding:0 15px 0px;
  overflow: auto;

}

.footer {
  padding:15px 0;
  text-align: center;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  line-height: 22px;
  color: var(--color-primary);
  cursor: pointer;
}
.icon {
  position: absolute;
  color: rgba(255, 255, 255, 1);
  right: -5px;
  top: 240px;
  font-size: 26px;
  z-index: 10;
}

.open_icon_box {
  cursor: pointer;
}
.no-data {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 80px;
  font-size: 14px;
  color: var(--color-text-secondary);
  & >img {
    margin-bottom: 12px;
  }
  & .info {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-text-secondary);
    padding-bottom: 4px;
  }
}


</style>
