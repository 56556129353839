<template>
  <div>
    <div class="pagination_comp" v-if="total_page > 1">
      <button type="button" :disabled="prev_disabled" class="btn prev" @click="change(-1)">
        <i class="el-icon el-icon-arrow-left"></i>
      </button>
      <div class="pages">{{currentPage}} / {{total_page}}</div>
      <button type="button" :disabled="next_disabled" class="btn next" @click="change(1)">
        <i class="el-icon el-icon-arrow-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    currentPage:{
      type: Number,
      default: 1
    },
    pageSize:{
      type: Number,
      default: 1
    },
    total:{
      type: Number,
      default: 1
    },
  },
  data () {
    return {

    }
  },
  computed:{
    total_page () {
      return Math.ceil(this.total / this.pageSize)
    },
    prev_disabled () {
      return this.currentPage === 1
    },
    next_disabled () {
      return this.currentPage === this.total_page
    }
  },
  watch:{
  },
  mounted () {
  },
  methods: {
    change (num) {
      let _currentPage = this.currentPage + num
      this.$emit('current-change', _currentPage)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .pagination_comp {
    display: flex;
    justify-content: center;
    align-items: center;
    & .btn {
      width: 40px;
      height: 40px;
      background: transparent;
      color: #666;
      cursor: pointer;
      &:hover {
        color: var(--color-primary);
      }
      &:disabled {
        color: #C0C4CC;
        cursor: not-allowed;
      }
    }
    & .pages {
      color: #6C757D;
      font-size: 14px;
      margin: 0px 8px;
    }
  }
</style>
