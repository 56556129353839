import {get_application_detail} from './service'

export default {
  namespaced: true,
  state: {
    data: {},//详情
    list: [],//左边列表
    menus:[],//头部菜单
    loading: false,
    select_key: null,//头部菜单显示部分
    current_report_id: '',//当前id
    no_data_obj: null,//没有值应用数据
    current_item: null,//应用参数
    name: '',//诊断名称
    application_id: '',//应用id
    change_init_params: null,//初始参数变化
  },
  getters: {
    is_has_data(state) {
      const {current_report_id,loading} = state
      let t_state = current_report_id && !loading
      return t_state
    },
  },
  mutations: {
    clear_data(state) {
      state.loading = false
      state.list = []
      state.data={}
      state.menus = []
      state.no_data_obj= null
      state.current_item = null
      state.change_init_params = null
    },
    show_loading (state) {
      state.loading =  true
    },
    hide_loading (state) {
      state.loading = false
    },
    update_data(state,payload) {
      //更新详情
      let data = payload.data || payload
      state.data = {api_type: state.data.api_type,...data}
    },
    t_update_list(state,payload) {
      //左侧列表值
      state.list = payload.data
    },
    update_menus(state,payload) {
      //更新头部菜单
      state.menus = payload.data ? payload.data : payload
    },
    init_data(state,payload) {
      //初始化传入参数
      state.name = payload.name
      state.data = payload.data
    },
    //更新报告id
    update_current_id(state,payload) {
      state.current_report_id = payload
    },
    update_application_id(state,payload) {
      //更新应用信息
      state.application_id = payload
    },
    update_current_item(state,payload) {
      //更新应用信息
      state.current_item = payload
    },
    update_select_key(state,payload) {
      //更新头部菜单显示
      state.select_key = payload
    },
    update_no_data(state,payload) {
      state.no_data_obj = payload.data
    },
    change_item_detail(state,payload) {
      //初始化传入对象用于变化申请左侧列表
      state.change_init_params = payload
    }
  },
  actions: {
    async search_no_data_detail({rootState, commit }, payload) {
      //无数据申请
      const {data} = rootState.DiagnosisDialog
      //判断无数据申请条件
      if(![1,2].includes(data.api_type)&&payload) {
        try {
          commit({
            type: 'show_loading',
          })
          const {data} = await get_application_detail(payload)
          commit({
            type: 'update_no_data',
            data
          })
        } finally {
          commit({
            type: 'hide_loading',
          })
        }
      }
    },
    async update_list({dispatch,commit,state}, payload)  {
      //更新列表
      commit({
        type: 't_update_list',
        data: payload
      })
      //申请应用内容
      if(state.application_id)dispatch('search_no_data_detail',state.application_id)
    }
  }
}
