import api from '../../utils/api'
import { getSession } from "../../utils/session";
export async function get_application(payload) {
  if(getSession().role === 11) {
    console.log(payload)
    return new Promise((resolve)=>{
      setTimeout(()=>{
        resolve({
          data:{
            applications: [{
              "api_type": 15,
              "application_desc": "脑脊液辅助监测分析",
              "application_icon": "default-15.png",
              "application_id": 333,
              "name": "脑脊液辅助监测分析"
            }],
            my_applications: [{
              "api_type": 15,
              "application_desc": "脑脊液辅助监测分析",
              "application_icon": "default-15.png",
              "application_id": 333,
              "name": "脑脊液辅助监测分析"
            }]
          }
        })
      },500)
    })
  } else {
    return api.get(`/api/v2/application/doctor`, {params: payload})
  }

}
//馨提示
export async function get_trigger_list(payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         list: [
  //           {
  //             application_name: '名字一',
  //             standard_id: 127,
  //             muted: false,
  //             hint_content: '是个好伤感好伤感是个山光水色是个好伤感好伤感是个山光水色是个好伤感好伤感是个山光水色的更好的更好的更好的'
  //           },
  //           {
  //             application_name: '名字二',
  //             standard_id: 2,
  //             muted: false,
  //             hint_content: '是个好伤感好伤感是个山光水色是个好伤感好伤感是个山光水色是个好伤感好伤感是个山光水色'
  //           }
  //         ]
  //       }
  //     })
  //   },500)
  // })
  return api.get(`/api/v2/ai/reports/${payload}/applications`)
}
