<template>
  <div>
    <el-table
      :data="data"
      border
      class="tab1"
      style="width: 100%">
      <el-table-column
        prop="code"
        v-if="api_type === 3"
        align="center"
        label="代码">
      </el-table-column>
      <el-table-column
        prop="original_laboratory_name"
        align="center"
        min-width="130"
        label="检验名称">
        <template slot-scope="scope">
          <span :class="{'can-click': scope.row.knowledge_id&&scope.row.knowledge_id.length>0}" @click="look_theroy(scope.row)"><span v-if="scope.row.knowledge_id&&scope.row.knowledge_id.length>0">*</span>{{scope.row.original_laboratory_name || scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="original_specimen"
        align="center"
        label="标本">
        <template slot-scope="scope">
            {{scope.row.original_specimen || scope.row.specimen}}
        </template>
      </el-table-column>
      <el-table-column
        prop="original_result"
        label="结果"
        align="center">
        <template slot-scope="scope">
          {{ scope.row.original_result || scope.row.result}}
          <img
            class="img"
            v-if="scope.row.abnormal && scope.row.abnormal_indicator === '升高（H）'"
            src="../../../assets/img/up-arrow.png"
            alt=""
            width="15"
            height="auto"
          />
          <img
            class="img"
            v-else-if="scope.row.abnormal && scope.row.abnormal_indicator === '降低（L）'"
            src="../../../assets/img/down-arrow.png"
            alt=""
            width="15"
            height="auto"
          />
          <img
           class="img"
            v-else-if="scope.row.abnormal_type && scope.row.abnormal_type === 1"
            src="../../../assets/img/up-arrow.png"
            alt=""
            width="15"
            height="auto"
          />
          <img
            class="img"
            v-else-if="scope.row.abnormal_type && scope.row.abnormal_type === -1"
            src="../../../assets/img/down-arrow.png"
            alt=""
            width="15"
            height="auto"
          />

        </template>
      </el-table-column>
      <el-table-column
        prop="original_units"
        align="center"
        label="单位">
         <template slot-scope="scope">
          {{scope.row.original_units || scope.row.original_unit || scope.row.unit || '-'}}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        min-width="130"
        prop="original_reference_interval"
        label="参考值">
        <template slot-scope="scope">
          {{ scope.row.original_reference_interval || (scope.row.range_lower ? `${scope.row.range_lower}-${scope.row.range_upper}` : '') || scope.row.original_reference || scope.row.reference || '-'}}
        </template>
      </el-table-column>
    </el-table>
        <KnolegeTheroy :knowledge="knowledge" :visible.sync="visible" v-if="visible"/>
  </div>
</template>
<script>
import KnolegeTheroy from './KnolegeTheroy.vue'
export default {
  props: {
    data: {
      default: () => [],
      type: Array
    },
    api_type: {
      default: null,
      type: Number
    },
  },
  data() {
    return {
      knowledge: [],
      visible: false
    }
  },
  components:{
    KnolegeTheroy
  },
  created() {
  },
  methods: {
    look_theroy (item) {
      if(!(item.knowledge_id&&item.knowledge_id.length>0)) return
      this.knowledge = item.knowledge
      this.visible = true
    }
  }
}
</script>
<style scoped>
.can-click {
  color: rgba(240, 82, 82, 1);
  cursor: pointer;
}
.img {
  position: relative;
  transform: translateY(-2px);
}
>>>.tab1 {
  &.el-table th {
    padding: 7px 0;
    background: var(--color-bg-primary);
    font-weight: bold;
    line-height: 20px;
    color: var(--color-text-secondary);
  }
  &.el-table td {
    padding: 7px 0;
  }
  &.el-table .el-table__header{
    width: 100% !important;
  }
  &..el-table .el-table__body{
    width: 100% !important;
  }
}
</style>
