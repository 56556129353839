<template>
   <el-dialog
      width="680px"
      title=""
      :visible.sync="visible"
       :before-close="before_close"
      append-to-body>
      <div class="title" slot="title">
        提示
      </div>
      <div class="body">
        您的检验项目尚不满足计算条件，请您完善
        <el-popover
          placement="bottom"
          title=""
          width="280"
          trigger="hover"
          content="蛋白定量（胸腔液）/ 总蛋白（血）">
          <span slot="reference">胸液/血清蛋白比值</span>
        </el-popover>、
        <el-popover
          placement="bottom"
          title=""
          width="310"
          trigger="hover"
          content="乳酸脱氢酶（胸腔液）/ 乳酸脱氢酶（血）">
          <span slot="reference">胸液/血清乳酸脱氢酶(jLDH)比值</span>
        </el-popover>、
        <el-popover
          placement="bottom"
          title=""
          width="310"
          trigger="hover"
          content="乳酸脱氢酶（胸腔液）/  乳酸脱氢酶（血）的参考范围上限">
          <span slot="reference">胸液LDH水平/血清LDH正常值上限比值</span>
        </el-popover>
        的检验项目填写
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close()">取 消</el-button>
        <el-button type="primary" @click="close()">确 定</el-button>
      </span>
    </el-dialog>
</template>
<script>

export default {
  data() {
    return {

    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  components: {

  },
  created() {

  },
  methods: {
    close () {
      this.$emit('update:visible', false)
    },
    before_close () {
      this.close()
    },
  }
}
</script>
<style scoped>
>>>.el-dialog__header {
  border-bottom: 1px solid rgba(112, 112, 112, 0.22);
}
>>>.el-dialog__body {
  padding:0 20px 20px;
}
.title {
  font-size: 20px;
  line-height: 20px;
  color: #333;
}
.body {
  line-height: 26px;
  color: #303133;
  padding: 20px 0;
  & span {
    color: var(--color-primary);
    cursor: pointer;
  }
}
</style>
