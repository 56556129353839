<template>
   <el-dialog
    v-loading="loading"
    width="90%"
    title="多指标历史趋势"
    :visible.sync="visible"
    :before-close="before_close"
    :close-on-click-modal="false"
    append-to-body>
    <!-- 暂无数据 -->
    <div class="no-data" v-if="(!list || list.length === 0) && !loading">
      <img src="../../../assets/img/detail/img_no_data.png" width="220" height="220"/>
      暂无数据～
    </div>
    <!--  历史趋势 -->
    <LaboLines
      :data="list"
      :up_limit="up_limit"
      :down_limit="down_limit"
      v-else/>
  </el-dialog>
</template>
<script>
import { get_trend } from './service'
import LaboLines from './LaboLines'

export default {
  props: {
    plan_id: {
      type: [String, Number],
      default: ''
    },
    patient_id:{
      type: [String, Number],
      default: ''
    },
    laboratories: {
      type: Array,
      default: ()=>[]
    },
    visible: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loading: false,
      list: [],
      up_limit: 0,
      down_limit: 0,
    }
  },

  components: {
    LaboLines,
  },
  created() {
    this.get_data()
  },
  methods: {
    close () {
      this.$emit('update:visible', false)
    },
    before_close () {
      this.close()
    },
    async get_data () {
      this.loading = true
      const {patient_id, laboratories, plan_id} = this
      let params = {
        patient_id,
        laboratories,
        plan_id,
      }
      try {
        const {data} = await get_trend(params)
        this.up_limit = data.up_limit
        this.down_limit = data.down_limit
        // 数据处理
        this.list = data.data && data.data.map((arr) => {
          let histories = []
          let original_laboratory_name = arr[0] && arr[0].standard_laboratory_name
          let original_specimen = arr[0] && arr[0].standard_specimen
          for (let item of arr) {
            item.histories = item.histories || []
            item.histories = item.histories.map(item2 => {
              item2.reference_interval = item.reference_interval
              item2.result_type = item.result_type
              item2.unit = item.unit
              return item2
            })
            histories = [...histories, ...item.histories]
          }
          histories.sort((a, b) => {
            return +a.result_time - (+b.result_time)
          })
          return {
            original_laboratory_name,
            original_specimen,
            name: `${original_laboratory_name}(${original_specimen})`, // 必须唯一
            histories,
          }
        })
      } finally {
        this.loading = false
      }
    },
  }
}
</script>
<style scoped>
>>>.el-dialog {
  margin: 8vh auto 0 !important;
}
.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
