<template>
  <el-drawer
    :visible.sync="visible"
    size="400px"
    style="right: 60px"
    :append-to-body="true"
    :modal="false"
    :withHeader="false"
    :before-close="before_close"
  >
    <div class="app">
      <div class="app_head">
        <p>全部应用</p>
        <span @click="edit_fn">{{ !this.is_edit ? "编辑" : "保存" }}</span>
      </div>
      <div class="all_app" v-if="apps && apps.length">
        <div class="app_items" v-for="item in apps" :key="item.id" @click.stop="jump(item)">
          <div class="img_box">
            <img :src="`./common-img/app_board_${item.api_type}.png`" class="icon" width="100%" height="auto">
            <div class="do_btn" v-if="item.type && is_edit" @click.stop="plus(item)">
              +
            </div>
          </div>
          <p style="width: 100%;text-align: center;">{{ short_name_config[item.api_type] }}</p>
        </div>
      </div>
      <div class="split_line"></div>
      <div class="app_head" style="margin-top: 30px">
        <p>我的应用</p>
      </div>
      <div class="all_app" v-if="my_apps && my_apps.length">
        <draggable
          class="drag_out"
          w="auto" h="auto" dragCancel=".not_drag"
          ref="drag_item"
          :draggable="is_edit"
          @activated="onActivated(item, index)"
          @dragging="onDrag"
          @dragstop="onStop"
          :handles="['ml']"
          v-for="(item, index) in my_apps" :key="item.id" @click.stop="jump(item)"
        >
          <div :class="['app_items',{drag_item: is_edit}]">
            <div class="img_box">
              <img :src="`./common-img/app_board_${item.api_type}.png`" class="icon" width="100%" height="auto">
              <div class="do_btn" v-if="is_edit" @click.stop="minus(item)">-</div>
            </div>
            <p style="width: 100%;text-align: center;">{{ short_name_config[item.api_type] }}</p>
          </div>
        </draggable>
        <div class="drag_out" ref="drag_item" style="width: 0;height: 0"></div>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import { store_app } from "./service";
import { short_name_config } from '../../../pages/lab-detail/config'
import draggable from '../../vue-draggable-resizable'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      apps: [],
      my_apps: [],
      is_edit: false,
      short_name_config: {...short_name_config},
      current_item: {},
      current_index: -1,
      current_rect: {}
    };
  },
  computed: {
    ...mapState("labDetails", {
      applications: (state) => state.applications,
    }),
  },
  components: {
    draggable,
  },
  watch: {},
  created() {
    this.apps = JSON.parse(JSON.stringify(this.applications.applications));
    this.my_apps = JSON.parse(
      JSON.stringify(this.applications.my_applications)
    );
    this.init();
  },
  methods: {
    ...mapMutations("labDetails", ["updateAllowSelect", "updateAppVisible"]),
    ...mapMutations("commonData", [
      'update_other_app_url',
    ]),
    init() {},
    edit_fn() {
      if (!this.is_edit) {
        this.is_edit = true;
        this.compare_arr();
      } else {
        this.edit_app();
      }
    },
    async edit_app() {
      this.loading = true;
      let app = {
        my_applications: [],
      };
      if (this.my_apps.length) {
        this.my_apps.forEach((item, index) => {
          app.my_applications.push({
            application_id: item.application_id,
            sort: index,
          });
        });
      }
      try {
        await store_app(app);
      } finally {
        this.loading = false;
        this.is_edit = false;
        this.close()
        this.$emit('reload')
      }
    },
    compare_arr() {
      this.my_apps.forEach((item) => {
        item.type = false;
      });
      const my_app_ids = this.my_apps.map((item) => {
        return item.application_id;
      });
      const not_my_app = this.apps.filter((item) => {
        return my_app_ids.indexOf(item.application_id) < 0;
      });
      not_my_app.forEach((item) => {
        item.type = true;
      });
      this.$forceUpdate()
    },
    plus(item) {
      if (
        this.my_apps
          .map((app) => {
            return app.application_id;
          })
          .indexOf(item.application_id) < 0
      ) {
        this.my_apps.push(item);
      }
      this.compare_arr();
    },
    minus(item) {
      this.my_apps.splice(this.my_apps.indexOf(item), 1);
      this.compare_arr();
    },
    close() {
      this.updateAppVisible(false);
    },
    before_close() {
      this.close();
    },
    jump() {
      // if(!this.is_edit) {
      //   this.update_other_app_url(`/appstore-detail?id=${item.application_id}`)
      // }
    },
    onActivated(item, index) {
      this.current_item = item
      this.current_index = index
      this.current_rect = document.getElementsByClassName('drag_out')[index].getBoundingClientRect()
    },
    onDrag() {
    },
    onStop() {
      const active_box = document.getElementsByClassName('drag_out active')[0]
      const select_data = active_box.getBoundingClientRect()
      const box = [...document.getElementsByClassName('drag_out')]
      let distace_arr = []
      box.forEach((item, index) => {
        const box_data = index === this.current_index ? this.current_rect: item.getBoundingClientRect()
        distace_arr.push(Math.pow((select_data.x - box_data.x), 2) + Math.pow((select_data.y - box_data.y), 2))
      })
      const drag_idx = distace_arr.indexOf(Math.min(...distace_arr))

      const arr = JSON.parse(JSON.stringify(this.my_apps))
      arr.splice(this.current_index, 1)
      arr.splice(drag_idx, 0 , this.current_item)
      this.my_apps = []
      this.$nextTick(()=>{
        this.my_apps = JSON.parse(JSON.stringify(arr))
      })
    },

  },
};
</script>
<style scoped>
.app {
  padding: 30px 18px;
  color: #303133;
}

.app_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;

  & span {
    color: var(--color-primary);
    cursor: pointer;
  }
}

.all_app,
.placeh {
  padding: 20px 10px;
  display: flex;
  flex-wrap: wrap;
}

.placeh{
  text-align:center;
  border:2px dotted #ccc;
  color:#999;
  font-size: 14px;
}

.drag_out {
  display: flex;
  flex-wrap: wrap;
  width: 50px;
}

.app_items {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
  margin: 0 25px 20px 0;
  user-select: none;

  & p {
    margin-top: 6px;
  }
}

.img_box {
  width: 40px;
  height: 40px;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.do_btn {
  position: absolute;
  width: 12px;
  height: 12px;
  font-size: 12px;
  text-align: center;
  line-height: 10px;
  background: var(--color-danger);
  color: #fff;
  right: -14px;
  top: -6px;
  cursor: pointer;
}

.split_line {
  height: 0;
  border-top: solid 1px #ebeef5;
}

.drag_item {
  cursor: move;
}

.app_item_s2 {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border: dotted 2px #ccc;
}

</style>
