import Vue from 'vue'
import Vuex from 'vuex'
import commonData from './common-data/module'
import excellent from './pages/excellent/module'
import aiPlatform from './pages/ai-platform/module'
import labDetails from './pages/lab-detail/module'
import reportDetail from './pages/unscramble/module'
import labDetails2 from './pages/lab-detail-2/module'
import crisis from './pages/crisis-reminder/module'
import knowledge from './pages/encyclopedia-knowledge/module'
import comprehensivePrompt from  './pages/comprehensive-prompt/module'
import reportList from './pages/report-list/module'
import DiagnosisDialog from './components/detail/diagnosis-dialog-2/module'
import feedback from './components/feedback/module'
import keepAlive from './keepAlive'
import favoriteList from './pages/favorite-list/module'
import auditList from './pages/audit-rules-management/module'
import ShenshanDetail from './pages/shenshan-detail/module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    commonData,
    excellent,
    labDetails,
    labDetails2,
    crisis,
    reportDetail,
    knowledge,
    comprehensivePrompt,
    reportList,
    DiagnosisDialog,
    feedback,
    keepAlive,
    favoriteList,
    aiPlatform,
    auditList,
    ShenshanDetail
  }
})
