import router from "../../router"
import { getSession, setSession } from '../../utils/session'
import { get_application,get_trigger_list } from "./service";

export default {
  namespaced: true,
  state: {
    //我的应用集
    applications: {},
    trigger_list: [],
    applications_copy: {},
    other_applications: [],
    plugin_data: getSession('plugin_data'),
  },
  getters: {
    decrypt_query (state) {
      // vue2.0 getter 的结果像计算属性一样会被缓存起来
      return state.plugin_data || {}
    },
    patient_id(state, getter) {
      return getter.decrypt_query.id
    },
    report_id() {
      return router.app.$route.query.report_id
    },
    report_date(state, getter) {
      return getter.decrypt_query.report_date
    },
  },
  mutations: {
    updateApplication(state, payload) {
      state.applications = payload.data
      state.applications_copy = JSON.parse(JSON.stringify(payload.data))
    },
    hander_application(state, payload) {
      if(!state.applications_copy.my_applications.map(item=>{
        return item.application_id
      }).includes(payload.application_id)) {
        state.applications_copy.my_applications.unshift(payload)
      }
    },
    update_app_copy(state, payload) {
      const ids = state.applications_copy.my_applications.map(it=>{return it.application_id})
      state.applications_copy.my_applications.splice(ids.indexOf(payload.application_id), 1);
    },
    update_applications_copy(state, payload) {
      state.applications_copy.my_applications = payload.concat(state.applications_copy.my_applications)
    },
    update_trigger_list(state, payload) {
      state.trigger_list = payload.data.applications
    },
    save_other_list(state, payload) {
      state.other_applications = payload
    },
    update_plugin_data(state, payload) {
      setSession(payload, 'plugin_data')
      state.plugin_data = payload
    },
  },
  actions: {
    //获取我的应用集
    async get_my_application({ commit }, payload) {
      const { data } = await get_application(payload)
      commit({
        type: 'updateApplication',
        data
      })
    },
    // 应用集提醒
    async search_trigger_list({ commit }, payload) {
      if(!payload.report_id) return
      const { data } = await get_trigger_list(payload.report_id)
      commit({
        type: 'update_trigger_list',
        data,
      })
      return { data }
    },
  }
}
