<template>
  <div id="app">
    <keep-alive :include="include" :max="max">
      <router-view />
    </keep-alive>
    <div v-if="open_source === 'iframe'" class="iframe_close"  @click="close">
      <img src="./assets/img/close.png" />关闭
    </div>
    <VersonDialog
      v-if="visible"
      :visible.sync="visible"
    />
  </div>
</template>

<script>
import VersonDialog from './components/VersonDialog'
import { mapState, mapActions } from 'vuex'
import { setPersonalData, getPersonalData, getSession} from './utils/session'
import { noLoginArr, pluginArr } from './pages/login/config'


export default {
  name: 'App',
  created() {
    this.get_license()
  },
  data() {
    let verson_config = JSON.parse(qa_verson_config.verson_config)
    return {
      visible:false,
      verson_config,
      open_source: GLOBAL_CONFIG._open_source,
    }
  },
  computed: {
    ...mapState('keepAlive', {
      max: state => state.max,
      include: state => state.include,
    }),
  },
  watch: {
    $route() {
      this.update_hint()
    }
  },
  components: {
    VersonDialog
  },
  methods: {
    getPersonalData,
    setPersonalData,
    ...mapActions('commonData', [
      'get_license'
    ]),
    close () {
      top.window.iframe_close()
    },
    // 提示更新
    update_hint() {
      const authToken = getSession()
      let path = this.$route.path
      if(noLoginArr.includes(path) || pluginArr.includes(path) || !authToken) return
      let save_verson = this.getPersonalData('CurrentVerson')
      let current_verson = this.verson_config.update_verson_no
      if((!save_verson || save_verson !== current_verson)) {
        this.setPersonalData('CurrentVerson',current_verson)
        this.visible = true
      }
    }
  }
}
</script>

<style>
#app {
  height: 100%;
}
.iframe_close {
  position: absolute;
  right: 35px;
  top: 20px;
  z-index: 99;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  color: var(--color-primary);
  display: flex;
  align-items: center;
  & img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}
</style>
