import api from '../../../utils/api'

export async function get_wikipedia (payload) {
  let url = `/api/v2/ai/reports/lite/analysis/${payload.report_id}/diagnosis`
  return api.get(url,{params: payload})
}

// 获取诊断详情
export async function get_laboratories (payload) {
  let url = '/api/v2/knowledge/info'
  return api.post(url,payload)
}
// 保存血气按钮慢性和急性操作
export async function to_save_flesh (payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{

  //       }
  //     })
  //   },500)
  // })
  return api.post( `/api/v2/ai/reports/${payload.bga_report_id}/${payload.patientId}/result/${payload.type}`)
}

// 获取血气详情
export async function get_blood_spirits (payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{

  //       }
  //     })
  //   },500)
  // })
  return api.get(`/api/v2/ai/reports/bga/${payload.report_id}`)
}
// 获取grace报告详情
export async function get_grace (payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         report_id: 1,
  //         level: '高危',
  //         score: 125,
  //         date: new Date().getTime(),
  //         cardiac_arrest: true,
  //         st_down: false,
  //         cardiac_enzymes_high: true,
  //         age: 12,
  //         heart_rate:  150,
  //         bloo_pressure: 150,
  //         serum_creatinine: 77,
  //         kill_ip_rank: 1
  //       }
  //     })
  //   },500)
  // })
  return api.get(`/api/v2/model/grace/${payload.report_id}`)
}
//获取渗出液模型报告详情
export async function get_exudate (payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         report_id: 1,
  //         date: new Date().getTime(),
  //         result: '漏出性胸腔积液',
  //         laboratories: [
  //           {
  //             laboratory_es_id:1,
  //             laboratory_id: 1,
  //             original_laboratory_name: '总血蛋白',
  //             original_specimen: '血',
  //             original_unit: 'g/l',
  //             original_result: '2'
  //           },
  //           {
  //             laboratory_es_id:2,
  //             laboratory_id: 2,
  //             original_laboratory_name: '总血蛋白',
  //             original_specimen: '血',
  //             original_unit: 'g/l',
  //             original_result: '2'
  //           },
  //           {
  //             laboratory_es_id:10,
  //             laboratory_id: 10,
  //             original_laboratory_name: '总血蛋白',
  //             original_specimen: '血',
  //             original_unit: 'g/l',
  //             original_result: '2'
  //           },
  //           {
  //             laboratory_es_id:3,
  //             laboratory_id: 3,
  //             original_laboratory_name: '总血蛋白',
  //             original_specimen: '血',
  //             original_unit: 'g/l',
  //             original_result: '2'
  //           },
  //           {
  //             laboratory_es_id:4,
  //             laboratory_id: 4,
  //             original_laboratory_name: '总血蛋白',
  //             original_specimen: '血',
  //             original_unit: 'g/l',
  //             original_result: '',
  //             range_lower: '2',
  //             range_upper: '2'
  //           },

  //         ]
  //       }
  //     })
  //   },500)
  // })
  return api.get(`/api/v2/model/exudate/${payload.report_id}`)
}

// grace报告创建/编辑
export async function submit_grace (payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         date: new Date().getTime(),
  //         result: '漏出性胸腔积液',
  //       }
  //     })
  //   },500)
  // })
  return api.post(`/api/v2/model/grace/${payload.patient_id}`,payload)
}


// 渗出液模型报告创建/编辑
export async function submit_exudate (payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{

  //       }
  //     })
  //   },500)
  // })
  return api.post(`/api/v2/model/exudate/${payload.patient_id}`,payload)
}
// 评估grace
export async function submit_assessment_grace (payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{

  //       }
  //     })
  //   },500)
  // })
  return api.post(`/api/v2/model/grace/measure`,payload)
}


// 评估渗出液
export async function submit_assessment_exudate (payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{

  //       }
  //     })
  //   },500)
  // })
  return api.post(`/api/v2/model/exudate/measure`,payload)
}

// 删除grace报告
export async function del_grace (payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{

  //       }
  //     })
  //   },500)
  // })
  return api.post(`/api/v2/model/grace/${payload.report_id}/delete`)
}


// 删除渗出液模型报告
export async function del_exudate (payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{

  //       }
  //     })
  //   },500)
  // })
  return api.post(`/api/v2/model/exudate/${payload.report_id}/delete`)
}
// 出液模型检验项初始化
export async function get_lab_list (payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         laboratories: [
  //           {
  //             laboratory_es_id:1,
  //             laboratory_id: 1,
  //             original_laboratory_name: '总血蛋白',
  //             original_specimen: '血',
  //             original_unit: 'g/l',
  //             original_result: ''
  //           },
  //           {
  //             laboratory_es_id:2,
  //             laboratory_id: 2,
  //             original_laboratory_name: '总血蛋白',
  //             original_specimen: '血',
  //             original_unit: 'g/l',
  //             original_result: ''
  //           },
  //           {
  //             laboratory_es_id:10,
  //             laboratory_id: 10,
  //             original_laboratory_name: '总血蛋白',
  //             original_specimen: '血',
  //             original_unit: 'g/l',
  //             original_result: ''
  //           },
  //           {
  //             laboratory_es_id:3,
  //             laboratory_id: 3,
  //             original_laboratory_name: '总血蛋白',
  //             original_specimen: '血',
  //             original_unit: 'g/l',
  //             original_result: ''
  //           },
  //           {
  //             laboratory_es_id:4,
  //             laboratory_id: 4,
  //             original_laboratory_name: '总血蛋白',
  //             original_specimen: '血',
  //             original_unit: 'g/l',
  //             original_result: '',
  //             range_lower: '',
  //             range_upper: ''
  //           },

  //         ]
  //       }
  //     })
  //   },500)
  // })

  return api.get(`/api/v2/model/exudate/init/${payload}`)
}

// ckd详情
export async function get_ckd (payload) {
  return api.get(`/api/v2/model/ckd/${payload.report_id}`)
}

//grace初始化
export async function get_init_grace(payload) {
  return api.get(`/api/v2/model/grace/init/${payload}`)
}

//胃黏膜报告列表
export async function get_gastric_list(payload) {
  return api.get(`/api/v2/model/gastric/${payload}/history`)
}

//胃黏膜报告详情
export async function get_gastric(payload) {
  return api.get(`/api/v2/model/gastric/${payload.report_id}`)
}

//量表详情
export async function get_form_detail(payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         age: 22,
  //         gender: '男',
  //         name: '张三',
  //         medicine_name: '优甲乐/50ug',
  //         medicine_time: new Date(),
  //         medicine_metering: '50ug',
  //       }
  //     })
  //   },500)
  // })
  return api.get(`/api/v2/model/medication/thyroid/${payload.report_id}`)
}

//量表列表
export async function get_form_list(payload) {
  return api.get(`/api/v2/model/medication/thyroid/${payload}/history`)
}

// 量表初始化
export async function get_form_init (payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         age: 22,
  //         gender: '男',
  //         name: '张三'
  //       }
  //     })
  //   },500)
  // })
  return api.get(`/api/v2/model/medication/thyroid/init/${payload}`)
}

// 提交量表
export async function sbmit_form_data (payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         age: 22,
  //         gender: '男',
  //         name: '张三',
  //         report_id: '1663_a4282b23_4cd2_4b77_a81d_66489fa9caf1'
  //       }
  //     })
  //   },500)
  // })
  return api.post(`/api/v2/model/medication/thyroid/${payload.patient_id}`,payload)
}

//ckd列表
export async function get_ckd_list(payload) {
  return api.get(`/api/v2/model/ckd/${payload}/history`)
}

// 血气列表
export async function get_flesh_list(payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         list: [
  //           {
  //             report_time: new Date().getTime(),
  //             report_id: '143267_aa76485cf61e9f21a8a4449dc368f212',
  //             natural_conclusion: '结论一',
  //             diagnosis_name:'血气分析'
  //           },
  //           {
  //             report_time: new Date('2021/12/1').getTime(),
  //             report_id: '40336_41bdf3d1ea2d2537c651c9e538bcc28f',
  //             natural_conclusion: '结论二',
  //             diagnosis_name: '血气分析'
  //           }
  //         ]
  //       }
  //     })
  //   },500)
  // })
  return api.get(`/api/v2/ai/reports/bga/list`,{params: payload})
}

// 获取grace报告列表
export async function get_grace_list(payload) {
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         data: [
  //           {
  //             date: new Date().getTime(),
  //             report_id: '143267_aa76485cf61e9f21a8a4449dc368f212',
  //             level: '高危',
  //             score: 124,
  //           },
  //           {
  //             date: new Date('2021/12/1').getTime(),
  //             report_id: '40336_41bdf3d1ea2d2537c651c9e538bcc28f',
  //             level: '中危',
  //             score: 125,
  //           }
  //         ]
  //       }
  //     })
  //   },500)
  // })
  return api.get(`/api/v2/model/grace/${payload}/history`)
}

// 获取渗出液模型报告列表
export async function get_exudate_list(payload) {
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         data: [

  //           {
  //             date: new Date().getTime(),
  //             report_id: '143267_aa76485cf61e9f21a8a4449dc368f212',
  //             result: '结论一'
  //           },
  //           {
  //             date: new Date('2021/12/1').getTime(),
  //             report_id: '40336_41bdf3d1ea2d2537c651c9e538bcc28f',
  //             result: '结论二'
  //           }
  //         ]
  //       }
  //     })
  //   },500)
  // })
  return api.get(`/api/v2/model/exudate/${payload}/history`)
}

export async function get_application_detail (payload) {
  return api.get(`/api/v2/application/${payload}`)
}

export async function get_select_list (payload) {
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         chronic_history:[
  //           {key: 1,value: 'COPD 等慢性呼吸系统疾病'},
  //           {key: 2,value: '慢性肾病'},
  //           {key: 3,value: '糖尿病'},
  //           {key: 4,value: '高血压'},
  //           {key: 5,value: '血脂异常'},
  //           {key: 6,value: '心血管疾病'},
  //           {key: 7,value: '脑血管疾病'},
  //           {key: 8,value: '实体器官移植后的免疫缺陷'},
  //           {key: 9,value: 'AIDS'},
  //           {key: 10,value: '慢性肝病'},
  //           {key: 11,value: '镰状细胞性贫血'},
  //           {key: 12,value: '地中海贫血'},
  //           {key: 13,value: '神经认知障碍'},
  //         ],
  //         history_tumor:[
  //           {key: 1,value: '恶性肿瘤'},
  //         ],
  //         drug_history:[
  //           {key: 1,value: '使用免疫抑制/调节药物'},
  //         ],
  //         personal_history:[
  //           {key: 1,value: '重度吸烟'},
  //           {key: 2,value: '妊娠晚期'},
  //         ],
  //         other_risk:[
  //           {key: 1,value: '养老机构居住人员'},
  //           {key: 2,value: '未接种疫苗'},
  //         ],
  //         inspection: [
  //           {key: 1,value: '淋巴细胞计数或百分比 降低/进行性降低'},
  //           {key: 2,value: 'CRP 异常 / 进行性上升'},
  //           {key: 3,value: '血沉 异常'},
  //           {key: 4,value: '淋巴细胞计数 下降'},
  //         ],
  //         biochemistry: [
  //           {key: 1,value: 'LDH 上升'},
  //           {key: 2,value: '铁蛋白 异常 / 进行性上升'},
  //           {key: 3,value: '乳酸显著 异常'},
  //           {key: 4,value: '肌酸激酶 / 肌酸激酶同工酶  异常'},
  //           {key: 5,value: '肝酶水平 异常'},
  //           {key: 6,value: '肌酐水平 异常'},
  //           {key: 7,value: 'cTnI 异常'},
  //           {key: 8,value: 'NT-proBNP/BNP 异常'},
  //         ],
  //         immune: [
  //           {key: 1,value: 'KL-6上升 /  进行性上升'},
  //           {key: 2,value: 'IFN-λ 3 上升'},
  //           {key: 3,value: 'IL-6 异常'},
  //           {key: 4,value: 'IL-8 异常'},
  //           {key: 5,value: 'IL-10 异常'},
  //         ],
  //         coagulation:[
  //           {key: 1,value: 'D-D 聚体上升 / 明显升高'},
  //         ],
  //         other_warning: [
  //           {key: 1,value: '出现严重肺部表现的患者'},
  //           {key: 2,value: '低氧血症加重或吸氧需求增加'},
  //           {key: 3,value: '呼吸困难症状加重'},
  //         ],
  //         illness_factor: [
  //           {key: 1,value: '出现呼吸衰竭，且需要机械通气'},
  //           {key: 2,value: '出现休克'},
  //           {key: 3,value: '合并其他器官功能衰竭需ICU监护治疗'},
  //         ],
  //         severe_factor: [
  //           {key: 1,value: '呼吸窘迫，RR≥30次/分'},
  //           {key: 2,value: '静息状态下，指氧饱和度≤93%'},
  //           {key: 3,value: '动脉血氧分压(PaO2)/吸氧浓度(FiO2)≤300mmHg'},
  //           {key: 4,value: '临床症状进行性加重'},
  //           {key: 5,value: '肺部影像学显示 24~48 小时内病灶明显进展>50%者'},

  //         ]
  //       }
  //     })
  //   },500)
  // })
  return api.get(`/api/v2/model/covid19/init/${payload}/menus`)
}

//新冠详情
export async function get_covid19_detail(payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         name: '张三',
  //         report_id: payload.report_id,
  //         report_time: new Date(),
  //         results: ['存在重症和危重症的高危因素','出现重症及危重症的预警','已经出现危重症因子'],
  //         gender: '男',
  //         age: 40,
  //         height: 180,
  //         weight: 70,
  //         patient_weight_unit: 'kg',
  //         patient_height_unit: 'cm',
  //         BMI: 21.6,
  //         pul_ct_abnormality: true,
  //         chronic_history: [1,2,3],
  //         history_tumor: [1],
  //         drug_history: [1],
  //         personal_history: [1],
  //         other_risk: [1],
  //         inspection: [1],
  //         biochemistry: [1],
  //         immune: [1],
  //         coagulation: [1],
  //         other_warning: [1,3],
  //         illness_factor: [1],
  //         severe_factor: [1],
  //         history: [
  //           {
  //             "date": new Date().getTime(),
  //             "role_type": 1,
  //             "type": "创建",
  //             "user_id": 3,
  //             "user_name": "检验科医生"
  //           },
  //           {
  //             "date": new Date('2021/12/1').getTime(),
  //             "role_type": 1,
  //             "type": "创建",
  //             "user_id": 3,
  //             "user_name": "检验科医生"
  //           }
  //         ]
  //       }
  //     })
  //   },500)
  // })
  return api.get(`/api/v2/model/covid19/${payload.report_id}`)
}

//新冠列表
export async function get_covid19_list(payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:[
  //         {
  //           report_time: new Date().getTime(),
  //           report_id: '143267_aa76485cf61e9f21a8a4449dc368f212',
  //           results: ['存在重症和危重症的高危因素']
  //         },
  //         {
  //           report_time: new Date('2021/12/1').getTime(),
  //           report_id: '40336_41bdf3d1ea2d2537c651c9e538bcc28f',
  //           results: ['出现重症及危重症的预警']
  //         }
  //       ]
  //     })
  //   },500)
  // })
  return api.get(`/api/v2/model/covid19/${payload}/history`)
}

// 新冠初始化
export async function get_covid19_init (payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         name: '张三',
  //         gender: '男',
  //         age: 40,
  //         height: 180,
  //       }
  //     })
  //   },500)
  // })
  return api.get(`/api/v2/model/covid19/init/${payload}`)
}

// 提交新冠量表
export async function sbmit_covid19_data (payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         report_id: payload.report_id,
  //       }
  //     })
  //   },500)
  // })
  return api.post(`/api/v2/model/covid19/${payload.patient_id}`,payload)
}

//肾上腺列表
export async function get_type11_list(payload) {
  return api.get(`/api/v2/model/adrenal_leather/${payload}/history`)
}

// 肾上腺提交
export async function sbmit_type11_data (payload) {
  return api.post(`/api/v2/model/adrenal_leather/${payload.report_id}`,payload)
}

//肾上腺详情
export async function get_type11_detail(payload) {
  return api.get(`/api/v2/model/adrenal_leather/${payload.report_id}`)
}

//原发性醛固酮
export async function get_type_list_12(payload) {
  return api.get(`/api/v2/model/aldosteronism/${payload}/history`)
}

//原发性醛固酮
export async function get_type_detail_12(payload) {
  return api.get(`/api/v2/model/aldosteronism/${payload.report_id}`)
}

//甲醛固酮功能辅助分析列表
export async function get_type_list_14(payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data: {
  //         data:[
  //           {
  //             report_time: new Date().getTime(),
  //             report_id: '143267_aa76485cf61e9f21a8a4449dc368f212',
  //             analysis_result: {

  //             }
  //           },
  //           {
  //             report_time: new Date('2021/12/1').getTime(),
  //             report_id: '40336_41bdf3d1ea2d2537c651c9e538bcc28f',
  //             analysis_result: {
  //               tips:['tips1','tips2'],
  //               suggestions: ['suggestions1','suggestions2']
  //             }
  //           }
  //         ]
  //       }

  //     })
  //   },500)
  // })
  return api.get(`/api/v2/model/thyroid_analysis/${payload}/history`)
}

//醛固酮分析报告详情
export async function get_type_detail_14(payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         report_id: payload.report_id,
  //         application_id: 334,
  //         patient_seq_no: 999,
  //         patient_name: '患者名称',
  //         standard_age: 30,
  //         standard_gender: '男',
  //         analysis_result: {
  //           tips:['tips1','tips2'],
  //           // suggestions: ['suggestions1','suggestions2']
  //         },
  //         pregnant_status: 9,
  //         laboratories: [
  //           {
  //             "abnormal": true,
  //             "standard_abnormal_indicator": "降低（L）",
  //             "code": "PA",
  //             "encyclopedia": true,
  //             "error": false,
  //             "evidences_type": 3,
  //             "feedback": false,
  //             "laboratory_esid": "19X0845568|13|116",
  //             "laboratory_id": "LTI_CH01406",
  //             "laboratory_name": "前白蛋白",
  //             "original_laboratory_name": "前白蛋白",
  //             "original_reference": "200-430",
  //             "original_result": "97",
  //             "original_result_type": 2,
  //             "original_specimen": "血",
  //             "original_unit": "mg/L",
  //             "ref_type": 1,
  //             "reference_interval": "[200,430]",
  //           },
  //           {
  //             "abnormal": true,
  //             "standard_abnormal_indicator": "降低（L）",
  //             "code": "PA",
  //             "encyclopedia": true,
  //             "error": false,
  //             "evidences_type": 3,
  //             "feedback": false,
  //             "laboratory_esid": "19X0845568|13|116",
  //             "laboratory_id": "LTI_CH01406-2",
  //             "laboratory_name": "前白蛋白",
  //             "original_laboratory_name": "前白蛋白",
  //             "original_reference": "200-430",
  //             "original_result": "97",
  //             "original_result_type": 2,
  //             "original_specimen": "血",
  //             "original_unit": "mg/L",
  //             "ref_type": 1,
  //             "reference_interval": "[200,430]",
  //           },
  //           {
  //             "abnormal": true,
  //             "standard_abnormal_indicator": "降低（L）",
  //             "code": "PA",
  //             "encyclopedia": true,
  //             "error": false,
  //             "evidences_type": 3,
  //             "feedback": false,
  //             "laboratory_esid": "19X0845568|13|116",
  //             "laboratory_id": "LTI_CH01406-2",
  //             "laboratory_name": "前白蛋白",
  //             "original_laboratory_name": "前白蛋白",
  //             "original_reference": "200-430",
  //             "original_result": "97",
  //             "original_result_type": 2,
  //             "original_specimen": "血",
  //             "original_unit": "mg/L",
  //             "ref_type": 1,
  //             "reference_interval": "[200,430]",
  //           },
  //           {
  //             "abnormal": true,
  //             "standard_abnormal_indicator": "降低（L）",
  //             "code": "PA",
  //             "encyclopedia": true,
  //             "error": false,
  //             "evidences_type": 3,
  //             "feedback": false,
  //             "laboratory_esid": "19X0845568|13|116",
  //             "laboratory_id": "LTI_CH01406-2",
  //             "laboratory_name": "前白蛋白",
  //             "original_laboratory_name": "前白蛋白",
  //             "original_reference": "200-430",
  //             "original_result": "97",
  //             "original_result_type": 2,
  //             "original_specimen": "血",
  //             "original_unit": "mg/L",
  //             "ref_type": 1,
  //             "reference_interval": "[200,430]",
  //           }
  //         ]
  //       }
  //     })
  //   },500)
  // })
  return api.get(`/api/v2/model/thyroid_analysis/${ payload.report_id}`)
}

//醛固酮分析报告详情
export async function pregnant_status_14(payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         data:null
  //       }
  //     })
  //   },500)
  // })
  return api.post(`/api/v2/model/thyroid_analysis/${payload.report_id}/pregnant_status`,payload)
}


//脑脊液列表
export async function get_type_list_15(payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:[
  //         {
  //           report_id: 12,
  //           report_time: new Date().getTime(),
  //           analysis_join: '存在“细菌性脑膜炎","结核性脑膜炎”风险'
  //         }
  //       ]
  //     })
  //   },500)
  // })
  return api.get(`/api/v2/model/cerebrospinal_analysis/${payload}/history `)
}

//脑脊液详情
export async function get_type_detail_15(payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         patient_name: '张三',
  //         standard_age: 33,
  //         standard_gender: '男',
  //         analysis_results: [
  //           {
  //             diagnosis_name: '疾病名称1',
  //             probability: 0.67,
  //             risk: false,
  //             shap_url: '糖尿病.png',
  //             weight_url: '糖尿病.png',
  //             laboratories:[
  //               {
  //                 "es_id": "X00000158_794_J00000216_101_1711512060000",
  //                 "laboratory_es_id": "X00000158_794_J00000216_101_1711512060000",
  //                 "laboratory_id": "LTI_CH02162",
  //                 "org_hosp": "",
  //                 "original_laboratory_name": "游离T4",
  //                 "original_reference": "0.70~1.48",
  //                 "original_result": "0.21",
  //                 "original_specimen": "血",
  //                 "original_unit": "ng/dl",
  //                 "standard_abnormal_indicator": "降低（L）"
  //               },
  //               {
  //                 "es_id": "X00000158_793_J00000215_101_1711512060000",
  //                 "laboratory_es_id": "X00000158_793_J00000215_101_1711512060000",
  //                 "laboratory_id": "LTI_CH02161",
  //                 "org_hosp": "",
  //                 "original_laboratory_name": "游离T3",
  //                 "original_reference": "1.71~3.71",
  //                 "original_result": "1.42",
  //                 "original_specimen": "血",
  //                 "original_unit": "ng/L",
  //                 "standard_abnormal_indicator": "降低（L）"
  //               },
  //               {
  //                 "es_id": "X00000158_792_J00000214_101_1711512060000",
  //                 "laboratory_es_id": "X00000158_792_J00000214_101_1711512060000",
  //                 "laboratory_id": "LTI_CH00406",
  //                 "org_hosp": "",
  //                 "original_laboratory_name": "促甲状腺激素",
  //                 "original_reference": "0.34~5.60",
  //                 "original_result": "7.42",
  //                 "original_specimen": "血",
  //                 "original_unit": "mIU/L",
  //                 "standard_abnormal_indicator": "升高（H）"
  //               }
  //             ]
  //           },
  //           {
  //             diagnosis_name: '疾病名称2',
  //             probability: 0.88,
  //             risk: true,
  //             shap_url: '糖尿病.png',
  //             weight_url: '糖尿病.png',
  //             laboratories:[
  //               {
  //                 "es_id": "X00000158_794_J00000216_101_1711512060000",
  //                 "laboratory_es_id": "X00000158_794_J00000216_101_1711512060000",
  //                 "laboratory_id": "LTI_CH02162",
  //                 "org_hosp": "",
  //                 "original_laboratory_name": "游离T4",
  //                 "original_reference": "0.70~1.48",
  //                 "original_result": "0.21",
  //                 "original_specimen": "血",
  //                 "original_unit": "ng/dl",
  //                 "standard_abnormal_indicator": "降低（L）"
  //               },
  //               {
  //                 "es_id": "X00000158_793_J00000215_101_1711512060000",
  //                 "laboratory_es_id": "X00000158_793_J00000215_101_1711512060000",
  //                 "laboratory_id": "LTI_CH02161",
  //                 "org_hosp": "",
  //                 "original_laboratory_name": "游离T3",
  //                 "original_reference": "1.71~3.71",
  //                 "original_result": "1.42",
  //                 "original_specimen": "血",
  //                 "original_unit": "ng/L",
  //                 "standard_abnormal_indicator": "降低（L）"
  //               },
  //               {
  //                 "es_id": "X00000158_792_J00000214_101_1711512060000",
  //                 "laboratory_es_id": "X00000158_792_J00000214_101_1711512060000",
  //                 "laboratory_id": "LTI_CH00406",
  //                 "org_hosp": "",
  //                 "original_laboratory_name": "促甲状腺激素",
  //                 "original_reference": "0.34~5.60",
  //                 "original_result": "7.42",
  //                 "original_specimen": "血",
  //                 "original_unit": "mIU/L",
  //                 "standard_abnormal_indicator": "升高（H）"
  //               }
  //             ]
  //           }
  //         ]
  //       }
  //     })
  //   },500)
  // })
  return api.get(`/api/v2/model/cerebrospinal_analysis/${ payload.report_id}`)
}
