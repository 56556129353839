<template>
<!-- 实验室 -->
  <div class="type-content">
    <!-- 头部元素滑动 -->
    <template v-if="data.laboratories&&data.laboratories.length>0">
    <!--api_type === 1 ：实验室 类型 的结论-->
      <div class="commom-text commom-text-top commom-text-bottom" style="display: none;">实验室诊断根据以下指标项得出</div>
      <div class="conclusion" style="display: none;">
        结论：
        <span v-for="(i,index) in data.laboratories" :key="i.laboratory_id">
          {{`${i.original_specimen}-${i.original_laboratory_name}`}}
          <!-- 异常 -->
          <template v-if="i.abnormal">
                <!-- 异常区间-->
              <span v-if="i.evidences_type === 3" :style="{color: i.abnormal_indicator === '降低（L）' ? '#00c092' : 'rgba(240, 82, 82, 1)'}">
                {{i.abnormal_indicator === '升高（H）' ? '升高' : '降低'}}至
              </span>
              <!-- 定量 -->
              <span v-else-if="i.evidences_type === 2">
                <img
                  class="up_arrow_img"
                  v-if="i.abnormal_indicator === '升高（H）'"
                  src="../../../assets/img/up-arrow.png"
                  width="15"
                  height="auto"
                /><img
                  v-else
                  src="../../../assets/img/down-arrow.png"
                  width="15"
                  height="auto"
                />
              </span>
            <!-- 异常区间和定性和叙述性 -->
            <span v-if="i.evidences_type!==2" span :style="{color: i.abnormal_indicator === '降低（L）' ? '#00c092' : 'rgba(240, 82, 82, 1)'}">{{i.original_result }} {{i.evidences_type === 3 ? i.original_units : ''}}</span>
          </template>
          <!-- 正常 -->
          <span v-else style="color: #00c092">
            {{i.evidences_type === 2 ? '正常' : (i.original_result)}}
          </span>
          <span v-if="index<data.laboratories.length-1">、</span>
        </span>
        <!-- 疾病名字 -->
          {{ data.reliable === 2 ? "为" : "怀疑"}}{{data.name}}
      </div>
    </template>
     <!--结论-- 显示化验列表和结论-->
    <template v-if="data.laboratories&&data.laboratories.length>0">
       <h2 class="commom-text commom-text-top commom-text-bottom">
        <div class="line"/>
          <span style="flex: 1">诊断依据：</span>
          <el-radio-group v-model="model_radio" v-if="data.source_type === 4">
            <el-radio :label="1">表格展示</el-radio>
            <el-radio :label="2">关联展示</el-radio>
          </el-radio-group>
      </h2>
      <span v-if="data.patient_age" style="padding-right: 20px;">年龄：{{data.patient_age}}</span><span v-if="data.patient_gender" style="">性别：{{data.patient_gender}}</span>
      <LabTable v-if="model_radio === 1" :data="data.laboratories" :api_type="data.api_type"/>
      <Graph
        v-else
        :style="{height: data.laboratories.length < 2 ? '400px' : '600px'}"
        class="grap"
        :value="current_key"
        :page_size="10"
        :links="links"
        :nodes="nodes"/>
    </template>
    <!-- 历史趋势 -->
    <el-button style="margin-top: 20px;" type="primary" round @click="history_trend" v-if="data.laboratories&&data.laboratories.length>0&&data.source_type === 4">点击查看历史趋势</el-button>
     <!-- 显示文字 -->
    <div v-if="hander_show_text()" class="t-title" >为进一步明确诊断，请参考以下诊疗指南</div>

    <!-- 显示趋势历史 -->
    <Trend
      :visible.sync="trend_visible"
      :laboratories="laboratories"
      v-if="trend_visible"
      :patient_id="patient_id"
    />
  </div>
</template>
<script>
import LabTable from './LabTable'
import Graph from './Graph.vue'
import Trend from '../trend'
export default {
  data() {
    return {
      trend_visible: false,
      model_radio: 1,
      laboratories: []
    }
  },
  props: {
    data:{
      type: Object,
      default: () =>{}
    },
    links: {
      type: Array,
      default: () =>[]
    },
    nodes: {
      type: Array,
      default: () =>[]
    },
    current_key: {
      type: [Number,String],
      default: null
    },
    patient_id: {
      type: String,
      default:''
    },
  },
  components: {
    LabTable,
    Trend,
    Graph
  },
  created() {
  },
  methods: {
    history_trend() {
      this.trend_visible = true
      this.laboratories = this.data.laboratories.map(i=>{
        return {
          standard_laboratory_name: i.laboratory_name,
          standard_specimen: i.standard_specimen,
        }
      })
    },
    hander_show_text() {
      const {api_type, introduction,condition_assessment,clinical_manifestations, special_inspection} = this.data
      if(api_type===1 && (introduction || condition_assessment || clinical_manifestations || special_inspection)) return true
      return false
    },
  }
}
</script>
<style scoped>
.commom-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #303133;
  display: flex;
  align-items: center;
  &.commom-text-top {
    padding-top: 32px;
  }
  &.commom-text-bottom{
     padding-bottom: 12px;
  }
  &.small-title {
    font-size: 16px;
  }
  & .info {
    font-size: 16px;
    line-height: 24px;
    color: var(--color-primary);
    flex:1;
     &.risk {
      color: red;
    }
  }
  & .line {
    width: 4px;
    height: 16px;
    background: var(--color-primary);
    border-radius: 3px;
    margin-right: 8px;
  }
  & .lab-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}
.grap {
  margin: 0 auto; width: 100%;
}
.t-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #303133;
  padding-top: 30px;
}
.type-content {
  padding-bottom: 30px;
}
</style>
