<template>
<!-- 大数据 -->
  <div class="type-content">
    <!--api_type === 2 ：大数据 类型 的结论-->
    <div  class="commom-text commom-text-top">
    患病风险：<span class="info risk">{{format_decimal(data.risk_probability_normal)}}%</span>
    </div>
      <!--title -  变化趋势 -->
    <template v-if="data.histories_normal&&data.histories_normal.length>1">
      <h2 class="commom-text commom-text-top commom-text-bottom" >
        <div class="line"/>
        风险变化趋势
      </h2>
      <div style="height: 300px;position: relative;" >
        <LaboLine :data="hander_histories(data.histories_normal)" :report_id="report_id"/>
      </div>
    </template>
     <!--结论-- 显示化验列表和结论-->
    <template v-if="data.laboratories&&data.laboratories.length>0">
        <!--title -  依据 -->
      <h2 class="commom-text commom-text-top commom-text-bottom">
        <div class="line"/>
          <span style="flex: 1">诊断依据：</span>
          <el-radio-group v-model="model_radio"  v-if="data.source_type === 4">
            <el-radio :label="1">表格展示</el-radio>
            <el-radio :label="2">关联展示</el-radio>
          </el-radio-group>
      </h2>
      <span v-if="data.patient_age" style="padding-right: 20px;">年龄：{{data.patient_age}}</span><span v-if="data.patient_gender" style="">性别：{{data.patient_gender}}</span>
      <LabTable v-if="model_radio === 1" :data="data.laboratories" :api_type="data.api_type"/>
      <Graph
        v-else
        :style="{height: data.laboratories.length < 2 ? '400px' : '600px'}"
        class="grap"
        :value="current_key"
        :page_size="10"
        :links="links"
        :nodes="nodes"/>
    </template>
    <!-- 历史趋势 -->
    <el-button style="margin-top: 20px;" type="primary" round @click="history_trend" v-if="data.laboratories&&data.laboratories.length>0">点击查看历史趋势</el-button>
    <!-- api_type === 2 ：大数据 类型 的趋势变化 -->
    <slot name="CommonCyclopedia"></slot>

    <!--title - 性能 -->
    <template v-if="data.performances&&data.performances.length>0">
      <slot name="performances"></slot>
      <h2 class="commom-text commom-text-top commom-text-bottom">
        <div class="line"/>
        性能
        <img width="16px" height="auto" @click="to_explain('性能')" style="margin-left: 4px;cursor: pointer;" src="../../../assets/img/detail/explain.png"/>
      </h2>
      <Table  :data="data.performances"/>
    </template>
      <!--title - ROC曲线 -->
    <template v-if="data.roc">
      <slot name="roc"></slot>
      <h2 class="commom-text commom-text-top commom-text-bottom">
        <div class="line"/>
        ROC曲线
        <img @click="to_explain('ROC曲线')" width="16px" height="auto" style="margin-left: 4px;cursor: pointer;" src="../../../assets/img/detail/explain.png"/>
      </h2>
      <img style="max-width: 60%;display: block;margin:0 auto;" height="auto" :src="data.hander_roc">
    </template>
    <!--title - SHAP图 -->
    <template v-if="data.shap">
      <slot name="shap"></slot>
      <h2 class="commom-text commom-text-top commom-text-bottom">
        <div class="line"/>
        SHAP图
        <img width="16px" height="auto" @click="to_explain('SHAP图')" style="margin-left: 4px;cursor: pointer;" src="../../../assets/img/detail/explain.png"/>
      </h2>
      <img style="max-width: 75%;display: block;margin:0 auto;" height="auto" :src="data.hander_shap">
    </template>
     <Explain :visible.sync="explain_visible" :name="explain_name"/>
      <!-- 显示趋势历史 -->
    <Trend
    :visible.sync="trend_visible"
    :laboratories="laboratories"
    v-if="trend_visible"
    :patient_id="patient_id"
  />
  </div>
</template>
<script>
import Table from './Table'
import LabTable from './LabTable'
import Explain from './Explain'
import LaboLine from './LaboLine'
import Trend from '../trend'
import Graph from './Graph.vue'
import {format_decimal} from '../../../utils/format'
export default {
  data() {
    return {
      explain_visible: false,
      explain_name: '',
      model_radio: 1,
      laboratories: [],
      trend_visible: false
    }
  },
  props: {
    data:{
      type: Object,
      default: () =>{}
    },
    links: {
      type: Array,
      default: () =>[]
    },
    nodes: {
      type: Array,
      default: () =>[]
    },
    current_key: {
      type: [Number,String],
      default: null
    },
    patient_id: {
      type: String,
      default:''
    },
    report_id:  {
      type: String,
      default:''
    },
  },
  components: {
    Table,
    LabTable,
    Explain,
    LaboLine,
    Trend,
    Graph
  },
  methods: {
    format_decimal,
    to_explain(name) {
      this.explain_name = name
      this.explain_visible = true
    },
    // 趋势图
    hander_histories(list) {
      if(!list) {
        return {}
      }
      return {
        histories: list,
        result_type: 2,
        unit:'%'
      }
    },
    history_trend() {
      this.trend_visible = true
      this.laboratories = this.data.laboratories.map(i=>{
        return {
          standard_laboratory_name: i.laboratory_name,
          standard_specimen: i.standard_specimen,
        }
      })
    }

  }
}
</script>
<style scoped>
.commom-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #303133;
  display: flex;
  align-items: center;
  &.commom-text-top {
    padding-top: 32px;
  }
  &.commom-text-bottom{
     padding-bottom: 12px;
  }
  &.small-title {
    font-size: 16px;
  }
  & .info {
    font-size: 16px;
    line-height: 24px;
    color: var(--color-primary);
    flex:1;
     &.risk {
      color: red;
    }
  }
  & .line {
    width: 4px;
    height: 16px;
    background: var(--color-primary);
    border-radius: 3px;
    margin-right: 8px;
  }
  & .lab-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}
.grap {
  margin: 0 auto; width: 100%;
}
.type-content {
  padding-bottom: 30px;
}
</style>
