import Vue from "vue"
import Router from "vue-router"
import { getSession, removeSession } from "./utils/session"
import { decrypt } from "./utils/aes"
import { noLoginArr } from "./pages/login/config"
import { logout } from "./pages/login/service"
import { page_record } from "./common-data/service"
import store from "./store"
import { restore_page } from "./keepAlive"

// 解决点击重复路由报错问题
const original_push = Router.prototype.push
Router.prototype.push = function push() {
  return original_push.apply(this, arguments)?.catch((err) => err)
}

/* Global */
import CommonLayout from "./pages/common-layout"

const Demo = () => import("@/pages/demo")
const H5 = () => import("@/pages/h5")

const Login = () => import("./pages/login")

const Home = () => import("./pages/home")
const Excellent = () => import("@/pages/excellent")
const Detail = () => import("@/pages/lab-detail")
const Laboratory = () => import("@/pages/laboratory")
const Data = () => import("@/pages/data")
const DiagnosisMenu = () => import("@/pages/diagnosis-menu")
const Users = () => import("./pages/users")
const Logs = () => import("./pages/logs")
const AboutUs = () => import("./pages/about-us")

//雷达图详情
const RadarDetail = () => import("@/pages/lab-detail-radar")

const AboutUsLogin = () => import("./pages/about-us/LoginAboutUs.vue")
// 管理许可证
const EditLicense = () => import("./pages/editLicense")
// 常见问题
const CommonProblem = () => import("./pages/common-problem")
// 系统更新
const VersionUpdate = () => import("./pages/version-update")
// 应用集详情
const ApplicationSet = () => import("./pages/application-set")

//危机提醒
const CrisisReminder = () => import("./pages/crisis-reminder")
//危机提醒详情
const CrisisDetail = () => import("./pages/crisis-detail")
//看板
const BulletinBoard = () => import("./pages/bulletin-board")

// 应用集
const AppStore = () => import("./pages/appstore")
//专病应用
const SpecializedDiseaseApplication = () => import("./pages/specialized-disease-application")
//专病应用详情
const SpecializedDiseaseDetail = () => import("./pages/specialized-disease-detail")
//专病总览
const SpecializedAbove = () => import("./pages/specialized-above")
//专病科室
const SpecializedDepartment = () => import("./pages/specialized-department")
//专病医生
const SpecializedDoctor = () => import("./pages/specialized-doctor")
//专病患者
const SpecializedPatient = () => import("./pages/specialized-patient")
//专病简介
const SpecializedDesc = () => import("./pages/specialized-desc")

// 数据清洗
const DataCleaning = () => import("./pages/data-cleaning")
//使用统计
const OperationsUseStatistical = () => import("./pages/operations-use-statistical")
// 解读能力
const OperationsInterpretAbility = () => import("./pages/operations-interpret-ability")
// 黑名单管理
const Blacklist = () => import("./pages/blacklist")
const BlacklistRecord = () => import("./pages/blacklist-record")
//ai报告解读
const AiReport = () => import("./pages/ai-report")
//注解报告
const AnalysisReport = () => import("./pages/analysis-report")

// 报告详情第二版
const Detail2 = () => import("@/pages/lab-detail-2")
const Unscramble = () => import("@/pages/unscramble")
const EncyclopediaKnowledge = () => import("@/pages/encyclopedia-knowledge")
const Report = () => import("@/pages/report")
//知识详情
const KnowledgeDetail = () => import("@/pages/knowledge-detail")
//数据中心
const DataCenter = () => import("@/pages/data-center")
//科研管理
const ScientificResearch = () => import("@/pages/scientific-research")
//自动机器学习平台
const AiPlatform = () => import("@/pages/ai-platform")
const AiPlatformDetail = () => import("@/pages/ai-platform-detail")
// 应用集页面
const AppApplication = () => import("@/pages/app-application")

//综合提示语
const ComprehensivePrompt = () => import("@/pages/comprehensive-prompt")
const ComprehensivePromptDetail = () => import("@/pages/comprehensive-prompt-detail")
//自定义文本
const UserDefinedText = () => import("@/pages/user-defined-text")
//报告列表
const ReportList = () => import("@/pages/report-list")
// 应用管理
const AppManage = () => import("@/pages/app-manage")
// 患者端
const CitizenPhone = () => import("./pages/citizen-phone")
// 即将上线
const Online = () => import("./pages/online")
// 河流图
const River = () => import("./pages/river")
//收藏列表
const FavoriteList = () => import("./pages/favorite-list")
//审核规则管理
const AuditRulesManagement = () => import("./pages/audit-rules-management")
//审核规则详情
const AuditRulesDetail = () => import("./pages/audit-rules-detail")
// 插件登录
const PluginLogin = () => import("./pages/plugin-login")
// 雷达图小窗口
const ReportDetailRadar = () => import("./pages/report-detail-radar")

// 良心小慧
const CitizenChatPhone = () => import("./pages/citizen-chat-phone")

// 深汕-决策提示列表
const DecisionAssist = () => import("./pages/decision-assist")

//深汕-公共页面
const ShenshanDetail = () => import("./pages/shenshan-detail")
//诊断辅助
const DiagnosticAssist = () => import("./pages/diagnostic-assist")
//决策辅助详情
const DecisionAssistDetail = () => import("./pages/decision-assist-detail")

//科研辅助
const ScientificResearchAssist = () => import("./pages/scientific-research-assist")

//我参与的科研
const MyScientificResearch = () => import("./pages/my-scientific-research")

//推送策略
const PushStrategy = () => import('./pages/push-strategy')

//单点推送
const SingleDemo = () => import('./pages/single-demo')
const SingleLogin = () => import('./pages/single-login')

//运维数据同步
const Datasynchronism = () => import('./pages/data-synchronism/index.vue')
const DatasynchronismDiagnosis = () => import('./pages/data-synchronism/Diagnosis.vue')
const DatasynchronismInspect = () => import('./pages/data-synchronism/Inspect.vue')
const DatasynchronismCheck = () => import('./pages/data-synchronism/Check.vue')

//应用管理员
const ApplyLogin = () => import('./pages/apply-login')

Vue.use(Router)

const routes = [
  {
    path: "/login",
    name: "登录",
    component: Login,
    meta: {
      deepth: 0,
    },
  },
  {
    path: "/plugin-login",
    name: "插件版登录",
    component: PluginLogin,
  },
  {
    path: "/apply-login",
    name: "插件版登录",
    component: ApplyLogin,
  },

  {
    path: "/about-us-login",
    name: "关于慧检登陆",
    component: AboutUsLogin,
  },
  {
    path: "/radar-detail",
    name: "长海-报告详情",
    component: RadarDetail,
    meta: {
      source: "patch", // 插件版
    },
  },
  {
    path: "/h5",
    name: "h5",
    component: H5,
  },
  {
    path: "/report-detail",
    component: Detail2,
    meta: {
      deepth: 2,
    },
    children: [
      {
        path: "",
        name: "解读",
        component: Unscramble,
      },
      {
        path: "/encyclopedia-knowledge",
        name: "知识",
        component: EncyclopediaKnowledge,
      },
      {
        path: "/knowledge-detail",
        name: "知识详情",
        component: KnowledgeDetail,
      },
      {
        path: "/report",
        name: "报告单",
        component: Report,
      },
      {
        path: "/app-application",
        name: "专属应用",
        component: AppApplication,
      },
      {
        path: "/river",
        name: "健康趋势",
        component: River,
      },
      {
        path: "/scientific-research-assist-common",
        name: "科研辅助",
        component: ScientificResearchAssist,
      },
      {
        path: "/my-scientific-research-common",
        name: "我参与的科研",
        component: MyScientificResearch,
      }
    ],
  },
  {
    path: "/",
    component: CommonLayout,
    meta: {
      name: 'common_layout',
    },
    children: [
      {
        path: "",
        name: "首页",
        component: Home,
        meta: {
          no_header: true, // 没有面包屑
          deepth: 1
        },
      },
      {
        path: "/about-us",
        name: "关于系统",
        component: AboutUs,
        meta: {
          deepth: 1
        },
      },
      {
        path: "/excellent",
        name: "AI报告解读",
        component: Excellent,
        meta: {
          deepth: 1
        },
      },
      {
        path: "/detail",
        name: "报告详情",
        component: Detail,
        meta: {
          full_screen: true, // 全屏展示
          show_nav: true, // 显示右边导航
        },
      },
      {
        path: "/data-center",
        name: "数据中心",
        component: DataCenter,
        meta: {
          deepth: 1
        },
      },
      {
        path: "/scientific-research",
        name: "科研项目管理",
        component: ScientificResearch,
        meta: {
          name: 'scientific_research_page', // 缓存必须加name，对应的是页面组件name
          keep_alive: true, // 是否缓存
          deepth: 1, // 页面层级，前往层级大于自己的才会被缓存
        },
      },
      {
        path: "/ai-platform",
        name: "自动机器学习平台",
        component: AiPlatform,
        meta: {
          deepth: 1
        },
      },
      {
        path: "/ai-platform-detail",
        name: "自动机器学习平台详情",
        component: AiPlatformDetail,
      },
      {
        path: "/laboratory",
        name: "详情",
        component: Laboratory,
        meta: { history: true}, // 追加面包屑

      },
      {
        path: "/data",
        name: "懂检验",
        component: Data,
        meta: {
          deepth: 1
        },
      },
      {
        path: "/diagnosis-menu",
        name: "查疾病",
        component: DiagnosisMenu,
        meta: {
          deepth: 1
        },
      },

      {
        path: "/crisis-reminder",
        name: "重要指标管理",
        component: CrisisReminder,
        meta: {
          deepth: 1
        },
      },

      {
        path: "/bulletin-board",
        name: "系统看板",
        component: BulletinBoard,
        meta: {
          no_header: true, // 没有面包屑
          deepth: 1
        },
      },

      {
        path: "/crisis-detail",
        name: "重要指标",
        component: CrisisDetail,
        meta: {
          deepth: 1
        },
      },
      {
        path: "/users",
        name: "账号管理",
        component: Users,
      },
      {
        path: "/logs",
        name: "日志管理",
        component: Logs,
      },
      {
        path: "/editLicense",
        name: "管理许可证",
        component: EditLicense,
        meta: {
          deepth: 1
        },
      },
      {
        path: "/common-problem",
        name: "常见问题",
        component: CommonProblem,
        meta: {
          deepth: 1
        },
      },
      {
        path: "/version-update",
        name: "系统更新",
        component: VersionUpdate,
        meta: {
          deepth: 1
        },
      },
      {
        path: "/appstore",
        name: "系统应用",
        component: AppStore,
        meta: {
          deepth: 1
        },
      },
      {
        path: "/specialized-disease-application",
        name: "专病应用",
        component: SpecializedDiseaseApplication,
        meta: {
          deepth: 1
        },
      },
      {
        path: "/specialized-disease-detail",
        component: SpecializedDiseaseDetail,
        meta: {
          no_header: true, // 没有面包屑
        },
        children: [
          {
            path: '',
            name: '总览',
            component: SpecializedAbove,
            meta: {
              no_header: true, // 没有面包屑
            },
          },
          {
            path: '/specialized-department',
            name: '科室',
            component: SpecializedDepartment,
            meta: {
              no_header: true, // 没有面包屑
            },
          },
          {
            path: '/specialized-doctor',
            name: '医生',
            component: SpecializedDoctor,
            meta: {
              no_header: true, // 没有面包屑
            },
          },
          {
            path: '/specialized-patient',
            name: '患者',
            component: SpecializedPatient,
            meta: {
              no_header: true, // 没有面包屑
              name: 'specialized_patient', // 缓存必须加name，对应的是页面组件name
              keep_alive: true, // 是否缓存
              deepth: 1, // 页面层级，前往层级大于自己的才会被缓存
            },
          },
          {
            path: '/specialized-desc',
            name: '简介',
            component: SpecializedDesc,
            meta: {
              no_header: true, // 没有面包屑
            },
          },
        ]
      },
      {
        path: "/appstore-detail",
        name: "应用集详情",
        component: ApplicationSet,
        meta: {
          history: true,
        },
      },
      {
        path: "/data-cleaning",
        name: "数据清洗",
        component: DataCleaning,
      },
      {
        path: "/operations-use-statistical",
        name: "使用统计",
        component: OperationsUseStatistical,
      },
      {
        path: "/operations-interpret-ability",
        name: "解读能力",
        component: OperationsInterpretAbility,
      },
      {
        path: "/blacklist",
        name: "黑名单",
        component: Blacklist,
      },
      {
        path: "/blacklist-record",
        name: "操作记录",
        component: BlacklistRecord,
      },
      {
        path: "/ai-report",
        name: "AI报告",
        component: AiReport,
      },
      {
        path: "/analysis-report",
        name: "注解报告",
        component: AnalysisReport,
      },
      {
        path: "/comprehensive-prompt",
        name: "智慧提示",
        component: ComprehensivePrompt,
      },
      {
        path: "/comprehensive-prompt-detail",
        component: ComprehensivePromptDetail,
        meta: {
          customBreadcrumb: true, // 自定义
        },
      },
      {
        path: "/report-list",
        name: "报告列表",
        component: ReportList,
      },
      {
        path: "/user-defined-text",
        name: "自定义文本",
        component: UserDefinedText,
      },
      {
        path: "/app-manage",
        name: "应用管理",
        component: AppManage,
      },
      {
        path: "/favorite-list",
        name: "我的收藏",
        component: FavoriteList,
      },
      {
        path: "/audit-rules-management",
        name: "检验审核规则管理",
        component: AuditRulesManagement,
      },
      {
        path: "/audit-rules-detail",
        name: "检验审核规则详情",
        component: AuditRulesDetail,
      },
      {
        path: "/push-strategy",
        name: "推送策略",
        component: PushStrategy,
      },
      {
        path: "",
        component: Datasynchronism,
        children: [
          {
            path: "/data-synchronism",
            name: "数据同步-诊断",
            meta: {
              show_name: '数据同步'//面包屑显示
            },
            component: DatasynchronismDiagnosis,
          },
          {
            path: "/data-synchronism-inspect",
            name: "数据同步-检验",
            meta: {
              show_name: '数据同步'//面包屑显示
            },
            component: DatasynchronismInspect,
          },
          {
            path: "/data-synchronism-check",
            name: "数据同步-检查",
            meta: {
              show_name: '数据同步'//面包屑显示
            },
            component: DatasynchronismCheck,
          },
        ]
      }
    ],
  },
  {
    path: "/demo",
    name: "例子",
    component: Demo,
  },
  {
    path: "/citizen-phone",
    name: "患者端",
    component: CitizenPhone,
  },
  {
    path: "/citizen-chat-phone",
    name: "良心小慧",
    component: CitizenChatPhone,
  },
  {
    path: "/online",
    name: "即将上线",
    component: Online,
  },
  {
    path: "/report-detail-radar",
    name: "雷达图",
    component: ReportDetailRadar,
  },

  {
    path: "",
    component: ShenshanDetail,
    children: [
      {
        path: "/diagnostic-assist",
        name: "诊断辅助",
        component: DiagnosticAssist,
      },
      {
        path: "/decision-assist",
        name: "决策提示",
        component: DecisionAssist,
      },
      {
        path: "/decision-assist-detail",
        name: "查看信息",
        component: DecisionAssistDetail,
        meta: {
          history: true,
        },
      },
    ],
  },
  {
    path: "/scientific-research-assist",
    name: "科研辅助",
    component: ScientificResearchAssist,
  },
  {
    path: "/my-scientific-research",
    name: "我参与的科研",
    component: MyScientificResearch,
  },
  {
    path: "/single-demo",
    name: "模拟单点登录",
    component: SingleDemo,
  },
  {
    path: "/single-login",
    name: "单点登录",
    component: SingleLogin,
  }

]

const router = new Router({
  routes,
})
//脑脊液屏蔽
const login_path = ()=>{
  return getSession('login_path') || '/login'
}
// 地址是否被包含
export const path_includes = (path, verify_list) => {
  if (['/login','/apply-login'].includes(path)) return true
  for (let key of verify_list) {
    const reg = new RegExp(`^${key}$`)
    if (reg.test(path)) {
      return true
    }
  }
  return false
}

// 首次访问系统
let first_visit = true

router.beforeEach(async (to, from, next) => {
  // 页面缓存
  store.commit("keepAlive/update_keep_alive", { to, from, routes })

  const { path, fullPath, query } = to

  let redirect = encodeURIComponent(fullPath)
  const _first_visit = first_visit
  first_visit = false
  // 不需要登录验证的地址
  for (let i = 0, len = noLoginArr.length; i < len; i++) {
    if (noLoginArr[i] === path) {
      return next()
    }
  }

  // 兼容旧对接
  if (_first_visit) {
    let { encrypt: encrypt_data, patient_id, PatId } = query
    // 数据兼容
    patient_id = patient_id || PatId
    if (encrypt_data) {
      const new_query = decrypt(encrypt_data, false)
      return next({ path: "/plugin-login", query: new_query })
    } else if (["/report-detail", "/report"].includes(path) && patient_id) {
      const _query = JSON.parse(JSON.stringify(query))
      // 登录参数
      const del_keys = [
        "id",
        "patient_id",
        "PatId",
        "doctor_id",
        "userId",
        "report_date",
        "tsCheck",
        "doctor_name",
        "doctor_no",
        "doctor_dpname",
        "source",
      ]
      for (let key of del_keys) {
        delete _query[key]
      }
      let { href } = router.resolve({
        path,
        query: _query,
      })
      if (/^#/.test(href)) {
        href = href.substring(1)
      }
      return next({
        path: "/plugin-login",
        query: {
          ...query,
          redirect: encodeURIComponent(href),
        },
      })
    }
  }

  const authToken = getSession()
  const verifyArr = (authToken && authToken.verifyArr) || []

  if (!path_includes(path, verifyArr)&&!['/login','/apply-login'].includes(path)){
    return next({ path: login_path(), query: { redirect } })
  }
  // 直接打开login页面的不清除
  if (['/login','/apply-login'].includes(path) && !_first_visit && authToken) {
    try {
      await logout()
    } finally {
      removeSession()
      // 阻止iframe里面的登录
      if (GLOBAL_CONFIG._open_source === "iframe") {
        top.window.location.reload()
      } else {
        setTimeout(() => {
          location.reload()
        }, 300)
      }
    }
  }
  next()
})

// 记录访问的页面
router.afterEach((to) => {
  // 记录访问的页面
  const authToken = getSession()
  if (authToken && authToken.token && ![login_path(), "/plugin-login"].includes(to.path)) {
    if (to.name) {
      page_record({
        desc: `页面访问 页面：${to.name}`,
      })
    }
  }
  // 恢复缓存的页面，如果有bug，去页面里面activated来执行
  setTimeout(() => {
    restore_page(to.meta?.name)
  })
})

export default router
