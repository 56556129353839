// 不需要登录验证的地址
export const noLoginArr = [
  "/demo",
  "/h5",
  "/citizen-phone",
  "/online",
  "/about-us-login",
  "/radar-detail",
  "/plugin-login",
  "/citizen-chat-phone",
  '/single-demo',
  '/single-login',
]

// 插件版地址
export const pluginArr = [
  "/report-detail",
  "/knowledge-detail",
  "/encyclopedia-knowledge",
  "/report",
  "/app-application",
  "/river",
  "/favorite-list",
  "/report-detail-radar",
  "/decision-assist",
  "/diagnostic-assist",
  "/decision-assist-detail",
  "/scientific-research-assist",
  "/my-scientific-research",
  "/scientific-research-assist-common",
  '/my-scientific-research-common',
  '/data-synchronism',
  '/data-synchronism-inspect',
  '/data-synchronism-check',
]

// 不需要权限验证的地址
export const noVerifyArr = [...noLoginArr, ...pluginArr]

// 有权限验证的地址
export const verifyArr = [...noVerifyArr]
