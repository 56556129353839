<template>
  <el-drawer
    :append-to-body="true"
    :visible.sync="visible"
    class="my-drawer"
    size="80%"
    top="40px"
    v-loading="loading"
    :destroy-on-close="true"
    :before-close="before_close">
    <div class="title" slot="title">
      <h1 class="text">
        {{create_name || data.diagnosis_display_name || name}}
        <template v-if="current_report_id" >
          <span class="applay" @click="applay(i)" v-for="i in data.applications" :key="i.application_id">
            {{i.application_name}}
            <span v-if="[5,6].includes(data.api_type)" class="inner-test">内测</span>
          </span>
        </template>
        <span v-else-if="!current_report_id&&current_item" class="applay" @click="applay(current_item)">
          {{current_item.name}}
          <span v-if="[5,6].includes(data.api_type)" class="inner-test">内测</span>
        </span>
      </h1>
      <ul v-if="data.hints&&data.hints.length>0" class="prompt">
        <i class="el-icon-caret-top icon-top"/>
        <li v-for="(i,index) in data.hints" :key="index" v-html="i">
        </li>
      </ul>
      <div id="wrapper_time" class="wrapper" ref="wrapper">
        <ul class="tab wrapper_content" ref="wrapper_content" >
          <li v-for="(i,index) in list" :key="i.key + index" @click="tab_select(i,index)" :class="[{'select': select_key === index}]" :ref="'tab' + index">
            {{i.name}}
            <div class="line"/>
          </li>
          <div ref="last"/>
        </ul>
      </div>
    </div>
    <div :class="['dialog_content',{'dialog_content1': data.api_type>2}]" @scroll="scroll_to" ref="basical_main">
      <!-- 头部元素滑动 -->
      <div class="scoll-titles"  ref="laboratories" v-if="data.api_type"></div>
     <!-- 实验室 -->
        <ApiType1
          :data="data"
          :patient_id="patient_id"
          :nodes="nodes" :links="links"
          :current_key="current_key"
          style="padding-left: 28px;"
          v-if="data.api_type === 1&&!hander_data()">
        </ApiType1>

        <!-- 大数据 -->
        <ApiType2
          :data="data"
          :report_id="report_id"
          :patient_id="patient_id"
          :nodes="nodes" :links="links"
          style="padding-left: 28px;"
          :current_key="current_key"
          v-else-if="data.api_type === 2&&!hander_data()">
          <!-- 头部元素滑动 -->
          <div slot="risk_probability" class="scoll-titles" ref="risk_probability"></div>
          <div slot="laboratories" class="scoll-titles" ref="laboratories"></div>
          <CommonCyclopedia :data="data" slot="CommonCyclopedia">
            <div slot="introduction" class="scoll-titles" ref="introduction"/>
            <div slot="recommends" class="scoll-titles" ref="recommends"/>
            <div slot="condition_assessment" class="scoll-titles" ref="condition_assessment"/>
            <div slot="clinical_manifestations" class="scoll-titles" ref="clinical_manifestations"/>
            <div slot="guides" class="scoll-titles" ref="guides" title="guides"/>
          </CommonCyclopedia>
          <div slot="performances" class="scoll-titles" ref="performances"/>
          <div slot="roc" class="scoll-titles" ref="roc"/>
          <div slot="shap" class="scoll-titles" ref="shap"/>
        </ApiType2>

      <!-- 血气 -->
      <ApiType3
        :patient_id="patient_id"
        :report_id="report_id"
        :data="data"
        :list="common_list"
        :current_report_id="current_report_id"
        :is_has_data="!hander_data()"
        @select_common_report="select_common_report"
        @update_list="update_list"
        @update_menus="update_menus"
        @update_loading="update_loading"
        @update_data="update_data"
        v-else-if="data.api_type === 3"
       >
        <div slot="focus" ref="focus" class="scoll-titles"></div>
      </ApiType3>

     <!-- ckd -->
      <ApiType4
        :data="data"
        :list="common_list"
        :current_report_id="current_report_id"
        :patient_id="patient_id"
        @select_common_report="select_common_report"
        @update_list="update_list"
        @update_menus="update_menus"
        @update_loading="update_loading"
        @update_data="update_data"
        :is_has_data="!hander_data()"
        v-else-if="data.api_type === 4">
        <CommonCyclopedia :data="data" slot="CommonCyclopedia">
          <div slot="introduction" class="scoll-titles" ref="introduction"/>
          <div slot="recommends" class="scoll-titles" ref="recommends"/>
          <div slot="condition_assessment" class="scoll-titles" ref="condition_assessment"/>
          <div slot="clinical_manifestations" class="scoll-titles" ref="clinical_manifestations"/>
          <div slot="guides" class="scoll-titles" ref="guides" title="guides"/>
        </CommonCyclopedia>
      </ApiType4>

       <!-- grace -->
      <ApiType5
        :data="data"
        :list="common_list"
        :current_item="current_item"
        :current_report_id="current_report_id"
        v-else-if="data.api_type === 5"
        :is_has_data="!hander_data()"
        :patient_id="patient_id"
        :report_id="report_id"
        @reflesh_page="reflesh_page"
        @is_edit="is_edit"
        @select_common_report="select_common_report"
        @update_list="update_list"
        @update_menus="update_menus"
        @update_loading="update_loading"
        @update_data="update_data"
        >
        <CommonCyclopedia :data="data" slot="CommonCyclopedia">
          <div slot="introduction" class="scoll-titles" ref="introduction"/>
          <div slot="recommends" class="scoll-titles" ref="recommends"/>
          <div slot="condition_assessment" class="scoll-titles" ref="condition_assessment"/>
          <div slot="clinical_manifestations" class="scoll-titles" ref="clinical_manifestations"/>
          <div slot="guides" class="scoll-titles" ref="guides" title="guides"/>
        </CommonCyclopedia>
      </ApiType5>

      <!-- 渗透液 -->
      <ApiType6
         :data="data"
        :list="common_list"
        :current_item="current_item"
        :report_id="report_id"
        :current_report_id="current_report_id"
        v-else-if="data.api_type === 6"
        :is_has_data="!hander_data()"
        :patient_id="patient_id"
        @is_edit="is_edit"
        @reflesh_page="reflesh_page"
        @select_common_report="select_common_report"
        @update_list="update_list"
        @update_menus="update_menus"
        @update_loading="update_loading"
        @update_data="update_data">
        <CommonCyclopedia :data="data" slot="CommonCyclopedia">
          <div slot="introduction" class="scoll-titles" ref="introduction"/>
          <div slot="recommends" class="scoll-titles" ref="recommends"/>
          <div slot="condition_assessment" class="scoll-titles" ref="condition_assessment"/>
          <div slot="clinical_manifestations" class="scoll-titles" ref="clinical_manifestations"/>
          <div slot="guides" class="scoll-titles" ref="guides" title="guides"/>
        </CommonCyclopedia>
      </ApiType6>

      <!-- 胃黏膜 -->
     <ApiType7
        v-else-if="data.api_type === 7"
        :data="data"
        :list="common_list"
        :patient_id="patient_id"
        @update_loading="update_loading"
        @update_menus="update_menus"
        @update_data="update_data"
        @update_list="update_list"
        @select_common_report="select_common_report"
        :is_has_data="!hander_data()"
        :current_report_id="current_report_id"
        ref="ApiType7"
        >
        <CommonCyclopedia :data="data" slot="CommonCyclopedia">
          <div slot="introduction" class="scoll-titles" ref="introduction"/>
          <div slot="recommends" class="scoll-titles" ref="recommends"/>
          <div slot="condition_assessment" class="scoll-titles" ref="condition_assessment"/>
          <div slot="clinical_manifestations" class="scoll-titles" ref="clinical_manifestations"/>
          <div slot="guides" class="scoll-titles" ref="guides" title="guides"/>
        </CommonCyclopedia>
      </ApiType7>

      <!-- 量表 -->
      <ApiType8
        :data="data"
        v-else-if="data.api_type === 8"
        @is_edit="is_edit"
        :list="common_list"
        :report_id="report_id"
        @update_loading="update_loading"
        @update_data="update_data"
        @update_list="update_list"
        @select_common_report="select_common_report"
        :patient_id="patient_id"
        :current_report_id="current_report_id">
      </ApiType8>
       <!-- 新冠-->
      <ApiType9
        :data="data"
        v-else-if="data.api_type === 9"
        @is_edit="is_edit"
        :list="common_list"
        :report_id="report_id"
        :is_has_data="!hander_data()"
        @update_loading="update_loading"
        @update_data="update_data"
        @update_list="update_list"
        @select_common_report="select_common_report"
        :patient_id="patient_id"
        :current_report_id="current_report_id">
      </ApiType9>

      <div class="no-data" v-if="hander_data()">
        <img src="../../../assets/img/detail/img_no_data.png" width="200" height="200"/>
        {{hander_no_text()}}
      </div>

      <template v-else>
        <!-- 公共百科 -->
        <CommonCyclopedia :data="data" v-if="!data.api_type || [1].includes(data.api_type)"  style="padding-left: 28px;">
          <div slot="introduction" class="scoll-titles" ref="introduction"/>
          <div slot="recommends" class="scoll-titles" ref="recommends"/>
          <div slot="condition_assessment" class="scoll-titles" ref="condition_assessment"/>
          <div slot="clinical_manifestations" class="scoll-titles" ref="clinical_manifestations"/>
          <div slot="guides" class="scoll-titles" ref="guides" title="guides"/>
        </CommonCyclopedia>

        <!--title - 公共配置部分 -->
        <!-- <template v-if="data.additional_infos&&data.additional_infos.length>0&&data.api_type === 2">
          <div v-for="(i,index) of data.additional_infos" :key="'additional_infos'+index">
            <h2 class="commom-text commom-text-top commom-text-bottom scoll-titles"  ref="additional_infos" v-if="i.title" :class="'additional_infos' + index">
                <div class="line"/>
                  {{i.title}}
              </h2>
              <Rich class="html"
                v-if="i.content"
                v-model="i.content"
                :style_type="2"
              />
          </div>
        </template> -->
      </template>
    </div>
  </el-drawer>
</template>
<script>
import { get_wikipedia} from './service'
import CommonCyclopedia from './CommonCyclopedia'
import {baseURL} from '../../../utils/api'
import { mapState,mapGetters, mapMutations } from 'vuex'
import ApiType1 from './ApiType1'
import ApiType2 from './ApiType2'
import ApiType3 from './ApiType3'
import ApiType4 from './ApiType4'
import ApiType5 from './ApiType5'
import ApiType6 from './ApiType6'
import ApiType7 from './ApiType7'
import ApiType8 from './ApiType8'
import ApiType9 from './ApiType9.vue'
import {form_obj,grace_config} from './config'
import {hander_commom_menus} from './config'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    bga_report_id:{
      default: '',
      type: String
    },
    name: {
      default: '',
      type: String
    },
    application_id: {
      default: null,
      type: Number
    },
    api_type: {
      default: null,
      type: [Number,String]
    },
    item: {
      default: null,
      type: Object
    }

  },
  data () {
    return {
      loading: false,
      data: {}, // 总数据
      list: [],
      select_key: 0,
      current_report_id: '',
      timeout: null,
      myScroll: null,
      current_key: null,
      links: [], // 关系
      nodes: [], // 总节点
      timeout2: null,
      item_key: 0,
      click_tab: false,
      timeout3: null,
      timeout4: null,
      ischange: false,
      current_item: null,
      t_edit: false,
      common_list: [],
    }
  },
  computed: {
    ...mapState('labDetails', {
      applications: state => state.applications,
    }),
    ...mapGetters("labDetails", [
      "patient_id",
      'report_id',
    ]),
    create_name() {
      let name = ''
      if(this.data.api_type === 5){
        name = this.current_report_id ? '' : 'Grace评估'
      } else if (this.data.api_type === 6) {
        name = this.current_report_id ? '' : 'Light胸腔积液鉴别'
      } else if (this.data.api_type === 8) {
        name = this.current_report_id ? '' : '甲状腺用药随访'
      }
      return name
    },
  },
  components: {
    CommonCyclopedia,
    ApiType1,
    ApiType2,
    ApiType3,
    ApiType4,
    ApiType5,
    ApiType6,
    ApiType7,
    ApiType8,
    ApiType9
  },
  watch: {
    applications() {
      const {api_type} = this.data
      if(!api_type) return
      let list =  this.applications.my_applications  || []
      for(let i of list) {
        if(this.current_item.application_id === i.application_id) {
          this.current_item = {...i}
          break
        }
      }
    }
  },
  created() {
    this.data.api_type = this.api_type
    const {api_type} = this.data
    if(this.item) {
      this.current_item = this.item
    } else if(api_type) {
      let list =  this.applications.my_applications  || []
      for(let i of list) {
        if(api_type === i.api_type) {
          this.current_item = {...i}
          break
        }
      }
    }
    this.current_report_id = this.bga_report_id
    if(api_type === 8) {
      this.data = {...form_obj,...this.data}
    } else if(api_type === 5) {
      this.data = {...grace_config,...this.data}
    } else if(!api_type || api_type === 1 || api_type === 2) {
      this.current_report_id = this.report_id
      this.search_wikipedia()
    }
  },
  mounted () {
    this.myScroll = new IScroll(`#wrapper_time`,{
      scrollX:true,
      scrollY:false,
    })
  },
  beforeDestroy () {
    if(this.myScroll)  this.myScroll.destroy()
    this.myScroll = null
  },
  methods: {
    ...mapMutations('commonData', [
      'update_other_app_url'
    ]),
    hander_no_text() {
      let text = ''
      switch (+this.data.api_type) {
      case 3:
        text = '此患者没有血气分析检验记录';
        break;
      case 7:
        text = '此患者没有胃黏膜血清学检验记录';
        break;
      case 11:
        text = '正在努力开发中';
        break;
      default:
        text = '暂无数据'
      }
      return text
    },
    // 当菜单数量太多出现滑动条
    resize () {
      if (this.timer) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let width_last = this.$refs['last'].offsetLeft
        this.$refs['wrapper_content'].style.width = width_last + 'px'
        setTimeout(() => {
          this.myScroll.refresh()
        }, 500)
      }, 300)
    },
    hander_data() {
      const {loading,list} = this
      let api_type = this.data.api_type
      return ![8,9].includes(api_type) && list.length === 0 && !loading
    },

    // 滚动
    tab_select(i,idx) {
      this.click_tab = true
      this.select_key = idx;
      let dom = i.key === 'additional_infos' ? this.$refs[i.key][i.index] : this.$refs[i.key]
      dom.scrollIntoView({
        behavior: "smooth",
      });
      if(this.timeout3) clearTimeout(this.timeout3)
      this.timeout3 = setTimeout(() => {
        this.click_tab = false
      },1000)
    },
    scroll_to() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        let recent_top = this.$refs.basical_main.scrollTop;
        let scrollItems = document.querySelectorAll(".scoll-titles");
        for (let i = scrollItems.length - 1; i >= 0; i--) {
          let judge =
            recent_top >= scrollItems[i].offsetTop - scrollItems[0].offsetTop - 20
          if (judge) {
            let dom = this.$refs['tab' + i]
            if(dom) {
              if(this.timeout2) clearTimeout(this.timeout2)
              this.timeout2 = setTimeout(() => {
                if(this.$refs['tab' + i][0]&&this.$refs['tab' + 0][0]) {
                  let left = this.$refs['tab' + i][0].offsetLeft - this.$refs['tab' + 0][0].offsetLeft - 100
                  this.$refs.wrapper.scrollLeft = left
                }
              }, 200);
            }
            if(!this.click_tab) {
              this.select_key = i
            }
            break;
          }
        }
      }, 20);
    },
    // 跳转应用
    applay(i) {
      if(this.data.api_type===9) return
      this.update_other_app_url(`/appstore-detail?id=${i.application_id}`)
    },
    update_menus(menus) {
      this.list = menus
    },
    //公共更新详情
    update_data(data) {
      this.data = data
      //滑到顶部
      let dom = this.$refs.laboratories
      if(this.timeout4)clearTimeout(this.timeout4)
      this.timeout4 = setTimeout(() => {
        dom.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        })
      }, 800)
    },
    //公共更新loading
    update_loading(val) {
      this.loading = val
    },
    //公共更新列表
    update_list(list) {
      this.common_list = list
    },
    //公共更新报告
    select_common_report(id) {
      this.current_report_id = id
    },
    //公共是否点击了编辑按钮
    is_edit(val) {
      this.t_edit = val
    },
    //公共是否刷新页面
    reflesh_page(val) {
      this.ischange = val
    },
    //其他诊断详情
    async search_wikipedia () {
      this.loading = true
      try {
        const {data} = await get_wikipedia({
          name: this.name,
          report_id: this.current_report_id,
          application_id: this.application_id
        })

        const {
          roc,shap,performances=[],//之前字段
          laboratories=[],api_type
        } = data
        let list = []
        if(laboratories&&laboratories.length>0) list.push({key: 'laboratories',name: '诊断依据'})
        list = hander_commom_menus(data,list)
        if(performances && performances.length>0 && api_type === 2)list.push({key: 'performances',name: '性能'})
        if(roc && api_type === 2)list.push({key: 'roc',name: 'ROC曲线'})
        if(shap && api_type === 2)list.push({key: 'shap',name: 'SHAP图'})
        // if(additional_infos && additional_infos.length>0 && api_type ===2) {
        //   let index = 0
        //   for(let item of additional_infos) {
        //     list.push({key:'additional_infos',name: item.title,index: index})
        //     index ++
        //   }
        // }

        if(shap) data.hander_shap = baseURL + `/api/v2/images/shap/${shap}`
        if(roc) data.hander_roc = baseURL + `/api/v2/images/roc/${roc}`
        this.list = list
        if(data.api_type === 1) {
          let diagnosis = [
            {
              name: data.diagnosis_display_name,
              key: data.id
            }
          ]
          let laboratories_1 = data.laboratories || []
          let links = []
          for(let i of laboratories_1) {
            i.key = i.laboratory_id
            links.push({
              source: data.id,
              target:i.laboratory_id,
              knowledges: [],
            })
          }
          diagnosis = diagnosis.map((item, index) => {
            item._type = 2
            item._name = item.name
            item._index = index
            return item
          })
          laboratories_1 = laboratories_1.map((item, index) => {
            item._type = 3
            item._name = item.original_laboratory_name + (item.original_specimen ? `（${item.original_specimen}）` : '')
            item._index = index
            return item
          })

          this.current_key = diagnosis[0].key
          this.nodes = JSON.parse(JSON.stringify([ ...diagnosis, ...laboratories_1]))
          this.links = JSON.parse(JSON.stringify(links))
        }
        this.data = {api_type: this.data.api_type,...data}
        this.$nextTick(()=>{
          this.resize()
        })
      } finally {
        this.loading = false
      }
    },
    close () {
      this.$emit('update:visible', false)
    },
    before_close () {
      if(this.ischange) {
        if(this.t_edit) {
          this.$confirm('关闭将丢失已编辑的内容，确认关闭？')
            .then(() => {
              setTimeout(()=>{
                location.reload()
              }, 10)
            })
            .catch(() => {});
        } else {
          setTimeout(()=>{
            location.reload()
          }, 10)
        }
      } else {
        if([5,8].includes(this.data.api_type)&&this.t_edit) {
          this.hander_close()
        } else if([6].includes(this.data.api_type)&&this.t_edit&&this.list.length>0){
          this.hander_close()
        } else {
          this.close()
        }
      }
    },
    hander_close() {
      this.$confirm('关闭将丢失已编辑的内容，确认关闭？')
        .then(() => {
          this.close()
        })
        .catch(() => {});
    }
  }
}
</script>
<style>
.jup{
  color: #6C757D;
  &:hover{
    color:var(--color-primary);
  }
}
.evidence .el-dialog__header {
  border-bottom: 1px solid #DEE2E6;
}
.decision_dislog {
  & .el-dialog__header {
    position: relative;
    z-index: 2;
  }
}
</style>
<style scoped>
 .wrapper {
   overflow-x:auto;
    position: relative;
    width: 100%;
    padding-top: 32px;
  }
  .wrapper_content {
    height: 100%;
    white-space: nowrap;
    position: relative;
  }
.wrappe::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.wrapper{
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.title {
  position: relative;
  border-bottom: 1px solid rgba(112, 112, 112, 0.22);
  & .text{
    position: relative;
    z-index: 1;
    font-size: 30px;
    font-weight: bold;
    color: #303133;
    padding:40px 28px 0px;
    & .applay {
      font-size: 16px;
      font-weight: 400;
      color: var(--color-primary);
      line-height: 28px;
      margin-left: 12px;
      cursor: pointer;
      position: relative;
      & .inner-test {
        background:#ffb400;
        border-radius: 2px;
        padding:4px;
        line-height: 14px;
        position: absolute;
        top: -15px;
        right: -38px;
        color: #fff;
        font-size: 14px;
      }
    }
  }
  & .img {
    position: absolute;
  }
}


.dialog_content {
  font-weight: 400;
  line-height: 24px;
  color: #303133;
  flex:1;
  overflow: auto;
  padding-right: 28px;
  &.dialog_content1{
    flex:1;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
}


.tab {
  display: flex;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-text-secondary);
  padding-left: 28px;
  & >li {
    padding-bottom: 12px;
    cursor: pointer;
    margin-right: 20px;
    position: relative;
    & .line {
      position: absolute;
      height: 2px;
      background: none;
      width: 60%;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &.select{
      color: var(--color-primary);
    & .line {
      background: var(--color-primary);
    }
   }
 }
}
.no-data {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  padding-top: 100px;
  color: #303133;
  & >img {
    margin-bottom: 29px;
  }
}
.process {
  display: flex;
  & .line {
    flex: 1;
    height: 2;
    border-top: 2px solid #E8E8E8;
    margin-top: 13px;
    margin-left:-10px;
    &.line1 {
      border-color: var(--color-primary);
      margin-left:-13px;
      margin-right:-10px;
    }

  }
   & .text {
      text-align: center;
      font-size: 14px;;
      line-height: 22px;
      color: #303133;
      &.text1 {
        color: rgba(0, 0, 0, 0.85);
      }&.text2 {
        color: #909399;
      }
    }
}
.lab {
  display: flex;
  flex-wrap: wrap;
  color: rgba(37, 128, 236, 1);
  padding-left: 30px;
}

.html {
  font-size: 14px;
  line-height: 24px;
  color: #303133;
}
.prompt{
  background: #f5e4af;
  padding: 10px 20px 5px;
  color: #303133;
  margin: 15px 28px 0px;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  & >li {
    padding-bottom: 5px;
  }
  & .icon-top{
    position: absolute;
    color: #f5e4af;
    font-size: 26px;
    top: -15px;

  }

}


>>>.el-drawer.rtl {
  overflow: auto;
}
>>>.el-drawer__body {
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
}

>>>.el-drawer__header {
  padding: 0px;
  margin-bottom: 0px;
  display: block;
}
>>>.el-drawer__close-btn {
  position: absolute;
  right: 20px;
  top:40px;
  z-index: 100;
  & .el-dialog__close {
    transform: scale(1.4);
    font-size: 22px;
    color: #707070;
    font-weight: bold;
  }
}
</style>

