export const short_name_config = {
  3: '血气分析',
  4: 'CKD分期',
  5: 'Grace评分',
  6: 'Light鉴别',
  7: '胃功能综合分析',
  8: '甲状腺用药随访',
  9: '新冠分型诊治辅助',
  11: '肾上腺皮质',
  12: '原发性醛固酮',
  14: '甲状腺功能辅助',
  15: '脑脊液辅助检测分析'
}
