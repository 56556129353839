<template>
  <el-drawer
    :append-to-body="true"
    :visible.sync="visible"
    class="p_drawer"
    size="50%"
    :destroy-on-close="true"
    :before-close="before_close"
    :title="`${dynamicValidateForm.plan_id ? '编辑' : '新建'}方案`">
    <div class="drawer_body">
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="drawer_content"
        v-loading="loading">
        <el-form-item
          style="padding:10px 24px 0;"
          label="方案名称"
          prop="plan_name"
          class="bot"
          :rules="{ required: true, message: '请输入方案名称', trigger: 'blur'}">
          <el-input class="input" :maxlength="20" clearable v-model="dynamicValidateForm.plan_name" placeholder="请输入方案名称，最大支持20个字。"></el-input>
        </el-form-item >
        <el-form-item
          style="padding:10px 24px 0;"
          label="检验项"
          class="bot"
          :rules="{
            required: true
          }">
          <el-button icon="el-icon-plus" @click="addDomain">添加</el-button>
        </el-form-item>
        <div class="content" ref="scroll">
          <div
            class="out-item"
            v-for="(domain, index) in dynamicValidateForm.laboratories"
            :key="domain.key">
            <div class="item">
              <el-form-item
              class="bot one"
              :prop="'laboratories.' + index + '.standard_laboratory_name'"
              :rules="{
                required: true, message: '请选择检验名称', trigger: 'change'
              }"
            >
              <Item1 :domain="domain" :or_name_list="or_name_list"/>
              </el-form-item>
              <el-form-item
                class="bot one"
                :prop="'laboratories.' + index + '.standard_specimen'"
                :rules="{
                  required: true, message: '请选择标本', trigger: 'change'
                }"
              >
                <Item2 :domain="domain"/>
              </el-form-item>
              <i class="el-icon-delete del" @click.prevent="removeDomain(domain)"/>
            </div>
           <div  style="margin-top: 5px;">
              查找到以下同类型的检验项，将与以下检验项合并展示：
              <el-form-item
               style="margin-top: 10px;"
                class="bot one"
                label="检验项目"
                :prop="'laboratories.' + index + '.children'"
              >
                <Item3 :domain="domain"/>
              </el-form-item>
           </div>
          </div>
        </div>
      </el-form>
      <div class="drawer_footer">
        <el-button size="medium" @click="close">取消</el-button>
        <el-button type="primary" size="medium" @click="submitForm">保 存</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import {edit_data,add_data,get_item_list} from './service'
import { user_action } from "../../../common-data/service";
import Item1 from './Item1'
import Item2 from './Item2'
import Item3 from './Item3'

export default {

  data() {
    return {
      loading: false,
      dynamicValidateForm: {
        laboratories: [{
          standard_laboratory_name: '',
          standard_specimen: '',
          children:[]
        }],
        plan_name: ''
      },
      or_name_list: [],
      timer: null
    }
  },
  props: {
    patient_id:{
      type: [String,Number],
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    item_obj:{
      type: Object,
      default: () => {}
    }
  },
  components: {
    Item1,
    Item2,
    Item3
  },
  created() {
    this.remote_name()
    this.dynamicValidateForm = {...this.dynamicValidateForm, ...this.item_obj}
    for(let i of this.dynamicValidateForm.laboratories) {
      if(i.children) {
        i.children = i.children.map(item=>{
          return {
            ...item,
            id: item.original_laboratory_name + item.original_specimen
          }
        })
      }
    }
  },
  methods: {
    close () {
      this.$emit('update:visible', false)
    },
    before_close () {
      this.$confirm('关闭将丢失已编辑的内容，确认关闭？')
        .then(() => {
          this.close()
        })
        .catch(() => {})
    },
    common_data(laboratories) {
      for(let i= 0; i<laboratories.length;i++) {
        for(let j= i+1; j<laboratories.length;j++) {
          if((laboratories[i].standard_laboratory_name === laboratories[j].standard_laboratory_name) && (laboratories[i].standard_specimen === laboratories[j].standard_specimen)) {
            return true
          }
        }
      }
      return false
    },
    async submit_data () {
      const {patient_id,dynamicValidateForm} = this
      let plan_id = dynamicValidateForm.plan_id
      let laboratories = dynamicValidateForm.laboratories
      if(this.common_data(laboratories)) {
        this.$notify({
          title: '',
          message: '已经出现重复的检验项请修改再提交',
          type: 'warning'
        })
        return
      }
      this.common_data(laboratories)
      try {
        let params = {
          plan_id,
          plan_name:dynamicValidateForm.plan_name,
          laboratories: dynamicValidateForm.laboratories,
          patient_id
        }
        this.loading = true
        if(plan_id) {
          await edit_data(params)
          // 埋点
          user_action({
            action_id: 51,
            extra_data: {
              plan_id,
              plan_name: dynamicValidateForm.plan_name,
              items: dynamicValidateForm.laboratories,
            }
          })
        } else {
          await add_data(params)
          // 埋点
          user_action({
            action_id: 10,
            extra_data: {
              plan_name: dynamicValidateForm.plan_name,
              items: dynamicValidateForm.laboratories,
            }
          })
        }
        this.$emit('refresh')
        this.close()
      } finally {
        this.loading = false
      }
    },
    submitForm() {
      this.$refs['dynamicValidateForm'].validate((valid) => {
        if (valid) {
          this.submit_data()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    removeDomain(item) {
      if(this.dynamicValidateForm.laboratories.length ===1) {
        this.$notify({
          title: '',
          message: '必须含有一条检验项',
          type: 'warning'
        })
        return
      }
      var index = this.dynamicValidateForm.laboratories.indexOf(item)
      if (index !== -1) {
        this.dynamicValidateForm.laboratories.splice(index, 1)
      }
    },
    addDomain() {
      if(this.common_data(this.dynamicValidateForm.laboratories)) {
        this.$notify({
          title: '',
          message: '已经出现重复的检验项请修改再提交',
          type: 'warning'
        })
        return
      }
      this.dynamicValidateForm.laboratories.push({
        standard_laboratory_name: '',
        standard_specimen: '',
        children: [],
        name_list: this.name_list,
        key: Date.now()
      });
      if(this.timer)clearTimeout(this.timer)
      this.timer = setTimeout(()=>{
        this.$refs.scroll.scrollTop = this.$refs.scroll.scrollHeight + 100
      },100)
    },
    async remote_name() {
      this.loading = true
      try {
        let params ={
          page: 0,
          page_size: 200,
          type:1
        }
        const {data} = await get_item_list(params)
        this.or_name_list = data.data || []
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
.drawer_body {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.drawer_content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  & .content{
    flex:1;
    overflow: auto;
    padding: 0 20px;
  }
  & .item {
    display: flex;
    align-items: center;
    & .one{
      flex:1;
      &:first-child {
        padding-right: 12px;
      }
    }
  }
  & .del {
    padding: 0 10px 10px;
    cursor: pointer;
    font-size: 16px;
    color: var(--color-danger);
  }
}
.out-item {
  padding: 12px;
  background: rgba(250, 250, 250, 1);
  margin-top: 16px;

}
>>>.drawer_content {
  & .el-form-item__label {
    font-weight: bold;
  }
  & .bot.el-form-item {
    margin-bottom: 12px;
  }
}
.drawer_footer {
  text-align: right;
  padding: 10px 24px;
  border-top: 1px solid rgba(0,0,0,0.06);
}
>>>.el-drawer__header {
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 500;
  color: var(--color-text-regular);
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
>>>.el-drawer__body {
  overflow: hidden;
}
</style>
